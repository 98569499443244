import { ActionType as Type } from "../../types";

const initialState = {
  isOpenLeftMenu: true,
};

export const handlerLeftMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.HANDLER_LEFT_MENU:
      return {
        ...state,
        isOpenLeftMenu: action?.payload?.isOpenLeftMenu,
      };
    default:
      return state;
  }
};
