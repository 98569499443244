import React, { FC, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { PageUrl } from "../../../types/enums";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import MenuItem from "@mui/material/MenuItem";
import { initialState, IProps } from "./SCUIProductCommentDetailForm.config";
import { Box, Card, CardContent, Divider, Grid } from "@mui/material";
import TimeHelper from "../../../../src/utils/time";
import { SCUIButton } from "../../atoms";
import Swal from "sweetalert2";
import Fade from "@mui/material/Fade";
import LinearProgress from "@mui/material/LinearProgress";
import MerchantQueryService from "../../../services/query/MerchantQueryService";
import { enumCommentFlowStatusTrName } from "../../../../src/types/enums";
import "./SCUIProductCommentDetailForm.scss";
import AuthService from "../../../services/auth/Auth.services";
import Helper from "../../../utils/helper";
import jwt_decode, { JwtPayload } from "jwt-decode";

import { enumCommentStatusFlowStatusTrName } from "../../../types/enums/status";
import MerchantCommandService from "../../../services/command/MerchantCommandService";


export const SCUIProductCommentDetailForm: FC<IProps> = ({
  filterInfo,
  id,
  history,
}) => {
  const [tagName, setTagName] = React.useState<any[]>([]);
  const [tagNames, setTagNames] = React.useState([]);
  const [statusName, setStatusName] = React.useState("");
  const [rejectInfo, setRejectInfo] = React.useState("");
  const [rejectInfos, setRejectInfos] = React.useState([]);
  const [disabled, setDisabled] = React.useState(true);
  const [commentUrl, setCommentUrl] = useState("");
  const [userName, setUserName] = useState(" ");

  const [atananKisi, setAtananKisi] = useState(" ");
  const [status, setStatus] = useState(" ");

  const [commentDetail, setCommentDetail] = useState<any>(
    initialState.CommentDetailType
  );
  const authService = new AuthService();
  interface JwtPayload {
    name: string;
  }
  const commentId = history?.location?.state?.yorumId
    ? history?.location?.state?.yorumId
    : id;
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    getCommentDetail(commentId);
    // GetCommentStatusList();
  }, [history?.location?.state?.yorumId, id]);
  useEffect(() => {
    GetCommentTicketList();
    getUserName();
    GetCommentRejectReasonList();
  }, []);
  const _handlChangeTag = (event: any) => {
    const {
      target: { value },
    } = event;
    setTagName(value);
  };
  const getUserName = () => {
    authService.getUserInfo().then((getUserInfo: any) => {
      if (!Helper.isNil(getUserInfo)) {
        const decodedToken = jwt_decode<JwtPayload>(getUserInfo.access_token);
        setUserName(decodedToken.name);
      }
    });
  };

  const _handleChangeStatus = (event: SelectChangeEvent) => {
    setStatusName(event.target.value as string);
    if (event.target.value == "3") {
      setDisabled(false);
    } else {
      setDisabled(true);
      setRejectInfo("");
    }
  };

  const getCommentDetail = async (id: any): Promise<any> => {
    try {
      let params = { id: id };
      let commentInfo: any = await MerchantQueryService.GetCommentDataById(
        params
      );
      commentInfo.rejectReasons
        ? setRejectInfo(commentInfo.rejectReasons[0]?.rejectId)
        : setRejectInfo("");
      setCommentDetail(commentInfo);
      setAtananKisi(commentInfo.userOperatorName);
      setStatus(enumCommentFlowStatusTrName[commentInfo?.status]);
      setStatusName(commentInfo.status);
      setCommentUrl(commentInfo.optionUrl);
      setTagName(
        commentInfo.tag
          ? commentInfo.tag
              .split("_")
              .filter((i: any) => i.length > 0)
              .map(Number)
          : []
      );

      if (commentInfo.status == "3") setDisabled(false);
      setLoading((prevLoading) => !prevLoading);
    } catch (error) {
      console.info(error);
    } finally {
    }
  };
  const postAssignToMe = async (): Promise<any> => {
    let params = { rowId: Number(commentId) };
    MerchantCommandService.PostAssigToMe(params)
      .then((res: any) => {
        Swal.fire({
          icon: "success",
          title: "Atandı",
          showConfirmButton: false,
          timer: 1500,
        });
        setAtananKisi(userName);
      })
      .catch((error: any) => {
        Swal.fire({
          icon: "error",
          title: "Atanamadı",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log("Hata :>> ", error);
      })
      .finally(() => {});
  };

  const GetCommentTicketList = async () => {
    try {
      let commentText: any = await MerchantQueryService.GetCommentTicketList();
      setTagNames(commentText.data);
    } catch (error) {
      console.log("Hata :>> ", error);
    }
  };

  const GetCommentRejectReasonList = async () => {
    try {
      let commentRejectReason: any =
        await MerchantQueryService.GetCommentRejectReasonList();
           setRejectInfos(commentRejectReason.data);
    } catch (error) {
      console.log("Hata :>> ", error);
    }
  };

  const getParams = () => {
    var arr = tagName.map(String);
    let params: any = {};
    params.commentId = Number(commentId);
    params.operation = statusName.toString();
    params.rejectStatus = rejectInfo.toString();
    params.tickets = arr;
    return params;
  };
  const tamamla = async () => {
    let params: any = getParams();
    if (params.operation === "0" || params.operation === "1")
      Swal.fire({
        icon: "error",
        title: "Lütfen işlem seçiniz",
        showConfirmButton: false,
        timer: 1500,
      });
    if (params.operation === "3" && Number(params.rejectStatus) === 0)
      Swal.fire({
        icon: "error",
        title: "Lütfen red bilgisini seçiniz",
        showConfirmButton: false,
        timer: 1500,
      });
    MerchantCommandService.PostCommentStatusUpdate(params)
      .then((res: any) => {
        if (res.assigntoMessage.visible)
          Swal.fire({
            icon: "error",
            title: res.assigntoMessage.text,
            showConfirmButton: false,
            timer: 1500,
          });

        if (res.operationMessage.visible) {
          Swal.fire({
            icon: "success",
            title: res.operationMessage.text,
            showConfirmButton: false,
            timer: 1500,
          });
          setStatus(enumCommentStatusFlowStatusTrName[params.operation]);
        }
        //setTagName([]);
      })
      .catch((error: any) => {
        console.log("Hata :>> ", error);
      })
      .finally(() => {});
  };

  return (
    <Grid container spacing={3} className="commentDetailGrid">
      <Box sx={{ width: "100%" }}>
        <Fade in={loading} unmountOnExit>
          <LinearProgress />
        </Fade>
      </Box>
      <Grid item lg={9} md={6} xs={8}>
        <h1 className="headtxt">Yorum Detay Düzenleme </h1>
      </Grid>
      <Grid item lg={3} md={6} xs={4}>
        <Link
          to={{
            pathname: PageUrl.commentList.url,
            state: { filterInfo: filterInfo },
          }}
          className="linktxt"
        >
          <ArrowCircleLeftIcon />
          {"Yorum Listesine Dön"}
        </Link>
      </Grid>

      <Box display="inline-block">
        <br />
        <Card>
          <CardContent>
            <Grid container>
              <Grid item md={4} xs={12}>
                <Box
                  component="img"
                  sx={{
                    padding: "2rem",
                    maxHeight: { xs: 512, lg: 512 },
                    maxWidth: { xs: 512, lg: 512 },
                  }}
                  src={commentDetail?.optionImgUrl?.replace("120", "512")}
                  loading="lazy"
                />
                <SCUIButton
                  text={"Ürüne git"}
                  type="ghost"
                  sizeType="default"
                  size="l"
                  onClick={() => window.open(commentUrl, "_blank")}
                />
              </Grid>

              <Grid item md={8} xs={12}>
                <Grid item md={12} xs={12}>
                  <div className="txt-detail">
                    Yorum Id: {commentDetail?.id}
                  </div>
                </Grid>
                <Grid item md={12} xs={12}>
                  <div className="txt-detail">
                    Müşteri Id: {commentDetail?.customerId}
                  </div>
                </Grid>
                <Grid item md={12} xs={12}>
                  <div className="txt-detail">
                    Puan: {commentDetail?.starScore}
                  </div>
                </Grid>
                <Grid item md={12} xs={12}>
                  <div className="txt-detail">Yorum Statü:{status}</div>
                </Grid>
                <Grid item md={12} xs={12}>
                  <div className="txt-detail">
                    E mail: {commentDetail?.userEmail}
                  </div>
                </Grid>
                <Grid item md={12} xs={12}>
                  <div className="txt-detail">
                    Yorum Tarihi:{" "}
                    {TimeHelper.formatDate(commentDetail.createOn)}
                  </div>
                </Grid>

                <Grid item md={12} xs={12}>
                  <div className="txt-detail">Atanan Kişi: {atananKisi}</div>
                </Grid>
                <Grid item md={12} xs={12}>
                  <div className="txt-detail">
                    Özel ve Renk Kodu:{" "}
                    {commentDetail.specialCode +
                      " - " +
                      commentDetail.colorcode}
                  </div>
                </Grid>
                <Grid item md={12} xs={12}>
                  <div className="yorumDiv">{commentDetail.commentText}</div>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <Card>
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id="tagMultipleSelectLabel">
                              Etiket
                            </InputLabel>

                            <Select
                              labelId="tagMultipleSelectLabel"
                              id="tagMultipleSelect"
                              multiple
                              value={tagName}
                              onChange={_handlChangeTag}
                            >
                              {tagNames.map((name: any) => (
                                <MenuItem key={name.id} value={name.id}>
                                  {name.keyWord}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id="statusSelectLabel">
                              İşlem
                            </InputLabel>
                            <Select
                              labelId="statusSelectLabel"
                              fullWidth
                              id="statusSelect"
                              value={statusName}
                              label="Durum"
                              onChange={_handleChangeStatus}
                              className="multiTagSelect"
                            >
                              {/*    {statusNames?.map((name: any) => (
                                  <MenuItem key={name.id} value={name.id}>
                                    {enumCommentStatusFlowStatusTrName[name.id]}
                                  </MenuItem>
                                ))}
 */}
                              <MenuItem value={2}>
                                {enumCommentStatusFlowStatusTrName[2]}
                              </MenuItem>
                              <MenuItem value={3}>
                                {enumCommentStatusFlowStatusTrName[3]}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id="rejectSelectLabel">
                              Red Nedeni
                            </InputLabel>
                            <Select
                              fullWidth
                              disabled={disabled}
                              labelId="rejectSelectLabel"
                              id="rejectSelect"
                              value={rejectInfo}
                              label="Durumum"
                              onChange={(e) =>
                                setRejectInfo(e.target.value as string)
                              }
                            >
                              {rejectInfos?.map((name: any) => (
                                <MenuItem key={name.id} value={name.id}>
                                  {name.keyWord}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid lg={6} item></Grid>

                        <Grid lg={3} item md={4} xs={5}>
                          <SCUIButton
                            text={"Bana Ata"}
                            type="primary"
                            sizeType="fixed"
                            size={"m"}
                            buttonType="button"
                            onClick={() => postAssignToMe()}
                          />
                        </Grid>
                        <Grid lg={3} item md={4} xs={5}>
                          <SCUIButton
                            text={"Tamamla"}
                            type="primary"
                            sizeType="fixed"
                            size={"m"}
                            buttonType="button"
                            onClick={tamamla}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card> 
      </Box>
    </Grid>
  );
};
