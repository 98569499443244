import React, { Component } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select } from "@mui/material";
import DataTable from "react-data-table-component";
import { SCUIButton, SCUILoading } from "../../../atomic/atoms";
import { SCUIContent } from "../../../atomic/organisms";
import MerchantQueryService from "../../../services/query/MerchantQueryService";
import TimeHelper from "../../../utils/time";
import customStylesTable from "../../Orders/Const";
import "../ProductList.scss";
import MerchantCommandService from "../../../services/command/MerchantCommandService";
import { SCUICollapsiblePaper, SCUISnackbar } from "../../../atomic/molecules";
import Helper from "../../../utils/helper";
import jwtDecode from "jwt-decode";
import AuthService from "../../../services/auth/Auth.services";
import { connect } from "react-redux";
import { handlerMpOriginCodeList } from "../../../stores/redux/actions/lookupAction/lookupAction";

var autService = new AuthService();

class ProductSentLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledButton: true,
      data: [],
      selectedMp: null,
      UserName: null,
      validationMessage: null,
      status: null,
      openMessage: false,
      totalCount: null,
      loading: true,
      currentPage: 1,
      showLeftButton1: false,
      filterDb: {
        page: 1,
        marketplace: null
      },
    };
  }

  componentDidMount() {
    this.getMpList();
    this.getProductList(this.state.filterDb);
    autService.getUserInfo().then((getUserInfo) => {
      if (!Helper.isNil(getUserInfo)) {
        const decodedToken = jwtDecode(getUserInfo.access_token);
        this.setState({ UserName: decodedToken.name });
      }
    });
  }

  getMpList() {
    this.props.handlerMpOriginCodeList()
  }

  onChange(e) {
    this.setState(
      {
        filterDb: {
          ...this.state.filterDb,
          page: e,
        },
      },
      () => {
        this.getProductList(this.state.filterDb);
      }
    );
  }

  getProductList(filterDb) {
    this.setState({ loading: true });
    MerchantQueryService.GetProductSentLogList(filterDb).then((res) => {
      if (res.data) {
        this.setState({
          data: res.data,
          loading: false,
          currentPage: filterDb.page,
          totalCount: res.totalCount,
        });
      }
    });
  }

  render() {
    const columns = [
      { omit: true, selector: (row) => row.id, name: "Id", grow: 0 },
      { selector: (row) => row.marketplace, name: "Marketplace" },
      { selector: (row) => row.id, name: "Dosya ID", cell: (row) => (<div>{row.id}</div>) },
      { selector: (row) => row.FileName, name: "Katalog Adı", cell: (row) => (<div>{row.FileName}</div>) },
      { selector: (row) => row.Status, name: "Gönderim Durumu" },
      { selector: (row) => row.Templatecode, name: "Template" },
      { selector: (row) => row.CreatedBy, name: "Gönderen Kullanıcı", cell: (row) => (<div>{row.CreatedBy}</div>) },
      {
        selector: (row) =>
          TimeHelper.momentDateAddThreeHours(row?.StartedOnUtc),
        name: "Gönderim Tarihi",
      },
      { selector: (row) => row.ResponseFileUrl, name: "Hatalı Kayıtlar" },
    ];

    return (
      <SCUIContent>
        <div style={{ height: "calc(100vh - 120px)", width: "100%" }}>
          <SCUICollapsiblePaper title="">
            <Grid container spacing={1}>
              <Grid item xs={2} mt={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select">Marketplace</InputLabel>
                  <Select
                    style={{ height: "55px", width: "200px" }}
                    labelId="value"
                    variant="outlined"
                    id="demo-simple-select"
                    value={this.state.selectedMp}
                    label="Marketplace"
                    onChange={(event) => {
                      this.setState({
                        selectedMp: event.target.value,
                        disabledButton: false,
                        filterDb: {
                          ...this.state.filterDb,
                          marketplace: event.target.value,
                        },
                      });
                    }}
                  >
                    {this.props.mpOrderOriginCodeList.map((e) => (
                      <MenuItem value={e.value}>{e.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} mt={3.3} mr={5}>
                <SCUIButton
                  text={"Ara"}
                  disabled={this.state.disabledButton}
                  type="primary"
                  sizeType="fixed"
                  size={"m"}
                  onClick={() => this.getProductList(this.state.filterDb)}
                />
              </Grid>
              <Grid item xs={1.5} mt={3.3}>
                <SCUIButton
                  text={"Temizle"}
                  type="secondary"
                  sizeType="fixed"
                  size={"m"}
                  onClick={() =>
                    this.setState(
                      {
                        disabledButton: true,
                        selectedMp: null,
                        filterDb: {
                          ...this.state.filterDb,
                          marketplace: null,
                        },
                      },
                      () => {
                        this.getProductList(this.state.filterDb);
                      }
                    )
                  }
                />
              </Grid>
            </Grid>
          </SCUICollapsiblePaper>
          <Grid container direction="column">
            {this.state.loading ? (
              <SCUILoading />
            ) : (
              <DataTable
                columns={columns}
                data={this.state.data}
                highlightOnHover
                customStyles={customStylesTable}
                paginationPerPage={50}
                pagination={true}
                paginationServer
                paginationTotalRows={this.state.totalCount}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                paginationDefaultPage={this.state.currentPage}
                onChangePage={(page) => this.onChange(page)}
              />
            )}
          </Grid>
        </div>
        <SCUISnackbar
          messageText={this.state.validationMessage}
          status={this.state.status}
          show={this.state.openMessage}
          onClose={() => this.setState({ openMessage: false })}
          autoHideDuration={3000}
        />
      </SCUIContent>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return ({
    handlerMpOriginCodeList:()=> handlerMpOriginCodeList(dispatch,false),
  });
};

const mapStateToProps = ({ lookups }) => ({
  mpOrderOriginCodeList: lookups.mpOrderOriginCodeList,
});
// withTranslation()(Orders);
export default connect(mapStateToProps, mapDispatchToProps)(ProductSentLogs);
