import React, { FC } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItem from "@mui/material/ListItem";
import Icon from '@mui/material/Icon';
import "./SCUIMainMenu.scss";

interface IProps {
   id: string;
   menuList:[];
   onClickNestedMenu:any;
   key?:any;
} 

export const SCUIMainMenu: FC<IProps> = ({ id = "1-1-1-2", menuList=null,onClickNestedMenu}) => {

  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const _handleClickDrawerListMenu = (index: number) => {
     if (selectedIndex === index) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
    }
  };
   return (
          


            <div id="mainMenuList">  
            {menuList ? menuList.map((blog: any, index: number) => (
               blog.navigateViewUrl===null ||  blog.navigateViewUrl==="null" ||blog.navigateViewUrl.trim().length===0 ?( 
                <List key={"mainmenumain"+index}  >

                <ListItemButton
                  onClick={() => {
                    _handleClickDrawerListMenu(index);
                  }}
                 >
                  <ListItemIcon sx={{paddingLeft:'2rem'}}> 
                  <Icon>{(blog.iconName)?blog.iconName: "star"}</Icon>

                  </ListItemIcon>
                  <ListItemText primary={blog.text} sx={{fontSize:"1.2rem"}} className="menuFont" />
                  {index === selectedIndex ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              <Collapse
                  in={index === selectedIndex}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding className="mainMenuList">
                    {blog.items?.menuItem && blog.items?.menuItem.length > 0
                      ? blog.items?.menuItem.map((data: any, i: number) => (
                          <ListItem className="nestedMenuList"
                          key={"k"+i}
                            component={Link}
                            to={data.navigateViewUrl}                            
                          >
                            <ListItemIcon>
                               <Icon>{(data.iconName)?data.iconName: "star"}</Icon>

                            </ListItemIcon>
                            <ListItemText primary={data.text} />
                          </ListItem>
                        ))
                      : null}
                  </List>
                </Collapse> 
              </List>
               ):(
                <List key={"mainmenumain"+index}   component={Link}    to={blog.navigateViewUrl}  >

                <ListItemButton
                  onClick={() => {
                    _handleClickDrawerListMenu(index);
                  }}
                 >
                  <ListItemIcon sx={{paddingLeft:'2rem'}}> 
                  <Icon>{(blog.iconName)?blog.iconName: "star"}</Icon>

                  </ListItemIcon>
                  <ListItemText primary={blog.text} sx={{fontSize:"1.2rem"}} className="menuFont" />
                 </ListItemButton>
              
              </List>
               )
                          
                 ))
              : null}
          </div> 
  );
};
