import React, { FC, ReactChildren, ReactChild,useState, useEffect } from "react";
import { SCUIContent } from "../../../atomic/organisms";
import "./EditRoles.scss";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import { SCUIButton } from "../../../atomic/atoms";
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';


import {
  Collapse,
  Box,
  Paper,
  FormControl,
  Grid,
  Card,
  TextField,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Button,

} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const privileges = [
  'Select',
  'Update',
  'Insert',
  'Delete',
  'Print',
  ];


export const EditRoles: FC<any> = (props: any) => {
  
  const [rules, setRulesName] = React.useState(['']);

  const handleChange = (event:any) => {
    const {
      target: { value },
    } = event;
    setRulesName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
   
  };
 console.info(rules)

return(
<SCUIContent  headerName={"Kural ve Yetkileri Düzenle "}>

<Grid container direction="row">




<Grid item lg={8} md={12} xs={12}>
<Paper sx={{ padding: "2rem", margin:"2rem", width:"100%" }} >
<Grid item md={12} xs={12}>


<FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Banner Görüntüleme</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={rules}
          onChange={handleChange}
          input={<OutlinedInput label="Banner Görüntüleme" />}

          renderValue={(selected) => selected.join(',')}
          MenuProps={MenuProps}
        >
          {privileges.map((privilege) => (   
            <MenuItem key={privilege} value={privilege}>
              <Checkbox checked={rules.indexOf(privilege) > -1} />

              <ListItemText primary={privilege} />
            </MenuItem>
          ))}        
             

        </Select>
      </FormControl>

             

   
</Grid>

</Paper>

<Grid container justifyContent="flex-end">
   <SCUIButton text={"Kaydet"} type="primary" sizeType="fixed" size={"m"} 
   />
<Paper>


</Paper>
</Grid>
</Grid>

</Grid>



  </SCUIContent>
  );
};




