import React, { FC, useState } from "react";
import { TextField, Checkbox, Button } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import "./SCUIMenuManagementDialogContent.scss";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
interface IProps {
  selectedRow?: any;
  onAddMenu?: any;
  onEditMenu?: any;
  onCloseMenu?: any;
  parent?: any;
  
}
export const SCUIMenuManagementDialogContent: FC<IProps> = ({
  selectedRow = null,
  onAddMenu,
  onEditMenu,
  onCloseMenu,
  parent,

}) => {
  let array_: any = [
    {
     
      menuDescription: "Ana Menü",
      menuId: 0,
      menuNameKey: "Ana Menü",
     
    },
  ]; 
  let arr: any = [...array_, ...parent];
  const [menuInput, setMenuInput] = useState({
    parentMenuId: selectedRow ? selectedRow?.parentMenuItemId : null,
    menuNameKey: selectedRow ? selectedRow?.text : 0,
    menuOrder: selectedRow ? selectedRow?.orderNo : 0,
    applicationId: 1,
    iconName: selectedRow ? selectedRow?.iconName : "",
    menuDescription:  selectedRow ? selectedRow?.menuDescription : "",
    isActive: selectedRow?.isActive ?selectedRow.isActive : false,
    menuNavigation: selectedRow ? selectedRow?.navigateViewUrl : null,
    menuId: selectedRow ? selectedRow?.menuItemId : "",
  });
  menuInput.parentMenuId =
  menuInput.parentMenuId === 0 ? null : menuInput.parentMenuId;

  const handleSelectParent = (e: any) => {
    setMenuInput({ ...menuInput, parentMenuId: e.target.value });
  };
  const submitHandler = (event: any) => {
    event.preventDefault();
    onAddMenu(menuInput);
  };
  const guncelle = (event: any) => {
    event.preventDefault();
    onEditMenu(menuInput);
  };
  return (
    <div>
      <form onSubmit={submitHandler}>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="parentMenuLabel">
              Bağlı olacağı menüyü seçin
            </InputLabel>
            <Select
              fullWidth
              labelId="parentMenuLabel"
              id="parentMenuLabel"
              value={menuInput.parentMenuId}
              label="Durumum"
              onChange={(e) => handleSelectParent(e)}
            >
              {arr &&
                arr.map((parent: any) => (
                  <MenuItem key={parent.menuId} value={parent.menuId}>
                    {parent.menuNameKey}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <TextField
            margin="dense"
            id="menuOrder"
            label="menuOrder"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={menuInput.menuOrder}
            onChange={(e) =>
              setMenuInput({ ...menuInput, menuOrder: Number(e.target.value) })
            }
          />
          <TextField
            margin="dense"
            id="applicationId"
            label="applicationId"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={menuInput.applicationId}
            onChange={(e) =>
              setMenuInput({
                ...menuInput,
                applicationId: Number(e.target.value),
              })
            }
          />

          <TextField
            margin="dense"
            id="iconName"
            label="iconName"
            type="text"
            placeholder="Örnek kullanım add_alarm şeklindedir"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Tüm iconlara burdan erişebilirsiniz">
                    <IconButton size="large"  onClick={() => { window.open('https://mui.com/material-ui/material-icons/', '_blank')}}>
                      <QuestionMarkIcon/>
                            </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            variant="standard"
            defaultValue={menuInput.iconName}
            onChange={(e) =>
              setMenuInput({ ...menuInput, iconName: e.target.value })
            }
          />

          <TextField
            margin="dense"
            id="menuDescription"
            label="menuDescription"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={menuInput.menuDescription}
            onChange={(e) =>
              setMenuInput({ ...menuInput, menuDescription: e.target.value })
            }
          />
          <TextField
            margin="dense"
            id="menuNameKey"
            label="menuNameKey"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={menuInput.menuNameKey}
            onChange={(e) =>
              setMenuInput({ ...menuInput, menuNameKey: e.target.value })
            }
          />
          <TextField
            margin="dense"
            id="menuNavigation"
            label="menuNavigation"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={selectedRow ? selectedRow?.navigateViewUrl : " "}
            onChange={(e) =>
              setMenuInput({ ...menuInput, menuNavigation: e.target.value })
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={menuInput.isActive}
                onClick={(e) =>
                  setMenuInput({ ...menuInput, isActive: !menuInput.isActive })
                }
                onChange={(e) => {
                  setMenuInput({ ...menuInput, isActive: e.target.checked });
                }}
              />
            }
            label="isActive"
          />
        </DialogContent>
        <DialogActions>
          {selectedRow.items ? (
            <Button onClick={guncelle}>guncelle</Button>
          ) : (
            <Button type="submit">kaydet</Button>
          )}
          <Button onClick={onCloseMenu}>iptal</Button>
        </DialogActions>
      </form>
    </div>
  );
};
