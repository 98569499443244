import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import MerchantQueryService from "../../../../services/query/MerchantQueryService";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function SaleAndReturnDashboard(props) {
  const [saleCount, setSaleCount] = useState(0);
  const [returnCount, setreturnCount] = useState(0);

  const data = {
    labels: ["Satış", "İade"],
    datasets: [
      {
        label: "# of Votes",
        data: [saleCount, returnCount],
        backgroundColor: ["rgba(0, 255, 164, 0.77)", "rgba(241, 30, 0, 0.71)"],
        borderColor: ["rgba(255, 99, 152, 0.2)"],
        borderWidth: 1,
      },
    ],
  };
  const optionsForecast = {
    // responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",

        labels: {
          fontColor: "white",
          boxWidth: 20,
          padding: 20,
        },
      },
    },
  };
  useEffect(() => {
    GetSaleAndReturnDashboard(props.filter);
    // const interval = setInterval(() => {
    //   GetSaleAndReturnDashboard();
    // }, 30000);
    // return () => clearInterval(interval);
  }, [props?.filter]);

  const GetSaleAndReturnDashboard = (filter) => {
    MerchantQueryService.GetSaleAndReturnDashboard(filter).then((res) => {
      if (res.data) {
        setSaleCount(res.data.saleCount);
        setreturnCount(res.data.returnCount);
      }
    });
  };

  return <Pie data={data}  options={optionsForecast}/>;
}
