
export const parseCommentListParams = (
  paginationInfo: any,
  optionId?: string,
  specialCode?: string,
  colorCode?: string,
  createDate?: string,
  endDate?: string,
  starScore?: any,
  commentStatus?: any,
  tagName?: string,
  operator?:string
): any => {
  let params = "";
  params += "pageSize=" + paginationInfo.rowsPerPage + "&";
  params += "pageNumber=" + paginationInfo.currentPage + "&";
  if (paginationInfo.currentPage)     params += "Page=" +  Number(Number(paginationInfo.currentPage)+1) + "&";  
  if (paginationInfo.rowsPerPage)     params += "size=" + paginationInfo.rowsPerPage + "&";
  if (optionId)                       params += "OptionCode=" + optionId + "&";
  if (specialCode)                    params += "SpecialCode=" + specialCode + "&";
  if (colorCode)                      params += "ColorCode=" + colorCode + "&";
  if (createDate)                     params += "StartDate=" + createDate + "&"; 
  if (endDate)                        params += "EndDate=" + endDate + "&";
  if (starScore) {
    if (starScore[0]?.id === -1) {
    } else {
      params += "Point=" + starScore[0]?.id + "&";
    }
  }
  if (commentStatus) {
    if (commentStatus[0]?.id === -1) {
    } else {
      params += "ApproveStatus=" + commentStatus[0]?.id + "&";
    }
  }
   if (tagName && tagName?.length>0) {
    for (let i = 0; i < tagName.length; i++) {
      params += "ticket=" + tagName[i] + "&";
    }
  }
  if(operator){
    params += "Operator=" + operator + "&"
    if(params[params.length - 3] === '-')
    {
      params = params.slice(0,-3);
    }
    
  }

  return params;
};
