import React, { Component } from "react";
//--
import AuthService from "../../../services/auth/Auth.services";
import LocalStorageWrapper from "../../../core/wrappers/browserStorage/localStorage-wrapper";
import Helper from "../../../utils/helper";
import { PageUrl } from "../../../types/enums";

interface IProps {
  history: any;
  authService: () => any;
}
export class Login extends Component<IProps, any> {
  authService;

  constructor(props: any) {
    super(props);
    this.authService = new AuthService();
    // this.languageService = new LanguageServices();
  }

  componentDidMount() {
    this.completeLogin();
  }

  completeLogin() {
    this.authService.completeLogin().then((result: any) => {
      // this.languageService.getLanguage();
      if (!Helper.isNil(result.access_token) && result.access_token !== "") {
        let returnPath = LocalStorageWrapper.getItemFromLocalStorage("returnPath");
        if (Helper.isNil(returnPath)) {
         // returnPath = PageUrl.currentRecordCreate.url;
          returnPath = PageUrl.home.url;

        } else {
          LocalStorageWrapper.removeItemFromLocalStorage("returnPath");
        }
        this.props.history.push(returnPath);
      }
    });
  }

  render() {
    return (
      <div>
        <p>Auth Works!</p>
      </div>
    );
  }
}
