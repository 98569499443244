const enumRoleCategories ={
   admin:0,
   user:1,
}
const enumRoleCategoryName ={
   [enumRoleCategories.admin]:'admin',
   [enumRoleCategories.user]:'user',
}

export {
   enumRoleCategories,
   enumRoleCategoryName
}