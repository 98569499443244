import { WebStorageStateStore } from "oidc-client";
//---
import { PageUrl } from "../enums/pageUrl-enums";

export const appUrl             =  process.env.REACT_APP_APPURL;
export const clientId           =  process.env.REACT_APP_CLIENTID;
export const stsUrl             =  process.env.REACT_APP_STSURL;
export const apiUrl             =  process.env.REACT_APP_APIURL;

export const StsAuthority       = stsUrl;
export const ClientRoot         = appUrl + '/';
export const RedirectUri        = appUrl+PageUrl.signIn.url;
export const LogoutRedirectUri  = appUrl+PageUrl.signOut.url;
export const ClientId           = clientId;
export const ClientSecret       = '';
export const Scope              = 'openid';
export const ResponseMode       = 'query';
export const ResponseType       = 'code';
export const ApiRoot            = '';
export const UserStore          = new WebStorageStateStore({ store: window.localStorage });
