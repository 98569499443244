import React, { useEffect, useRef, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,

} from "chart.js";
import { Chart } from "react-chartjs-2";
import MerchantQueryService from "../../../../services/query/MerchantQueryService";
import { SCUILoading } from "../../../../atomic/atoms";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const SaleAndReturnTotalDashboard = (props) => {
  const [labels, setLabels] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [saleTotal, setSaleTotal] = useState(0);
  const [returnTotal, setReturnTotal] = useState(0);
  const [ratio, setRatio] = useState(0);

  const data = {
    labels,
    datasets: [
      {
        type: "line",
        yAxisID: "y2",
        label: "İade Oranı",
        borderColor: "rgba(246, 194, 48, 0.88)",
        borderWidth: 3,
        fill: false,
        data: ratio,
        datalabels: {
          display: true,
          anchor: "end",
          offset: 0,
          align: "right",
          marginBottom: "100px",
          formatter: function (value) {
            return  Number.isFinite(value)? value.toFixed(2) +" % ":0 +" % ";
          },
          font: {
            weight: 'bold',
            size: 12,
            marginBottom: "100px",
            align:"right"
          },
        },
      },
      {
        type: "bar",
        label: "Satış",
        backgroundColor: "rgba(40, 87, 162, 0.88)",
        data: saleTotal,
        borderColor: "white",
        borderWidth: 2,
        datalabels: {
          display: true,
          anchor: "end",
          offset: -20,
          align: "start",
          marginBottom: "100px",
          formatter: function (value) {
            return `€ ${ parseInt(value*10, 10) % 2 == 0 ? Math.floor(value) : Math.ceil(value)}`;
          },
          font: {
            weight: 'bold',
            size: 12,
            marginBottom: "100px",
          },
        },
      },
      {
        type: "bar",
        label: "İade",
        backgroundColor: "rgba(255, 99, 9, 0.77)",
        data: returnTotal,

        datalabels: {
          display: true,
          anchor: "end",
          offset: -20,
          align: "start",
          marginBottom: "100px",
          formatter: function (value) {
           return `€ ${ parseInt(value*10, 10) % 2 == 0 ? Math.floor(value) : Math.ceil(value)}`;
          },
          font: {
            weight: 'bold',
            size: 12,
            marginBottom: "100px",
          },
        },
      },
    ],
  };

  const optionsForecast = {
    plugins: {
      legend: {
        position: "bottom",

        labels: {
          fontColor: "white",
          boxWidth: 20,
          padding: 20,
        },
      },
    },
    scales: {
      y: {
        id: "y1",
        ticks: {
          callback: function (value, index, ticks) {
            return "€ " + value;
          },
        },
      },
      y2: {
        id: "y2",
        type: "linear",
        position: "right",
      },
    },
  };

  useEffect(() => {
    GetSaleAndReturnTotalDashboard(props.filter);
  }, [props?.filter]);

  const GetSaleAndReturnTotalDashboard = (filter) => {
    setIsLoading(true);
    MerchantQueryService.GetSaleAndReturnTotalDashboard(filter).then((res) => {
      if (res.data) {
        setLabels(res.data.labels);
        setSaleTotal(res.data.saleTotal);
        setReturnTotal(res.data.returnTotal);
        setRatio(res.data.ratio);
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <SCUILoading />
      ) : (
        <Chart
          type="bar"
          data={data}
          plugins={[ChartDataLabels]}
          options={optionsForecast}
          style={{ height: "375px", width: "500px", marginLeft: "70px",position:"relative" }}
        />
      )}
    </>
  );
};
export default SaleAndReturnTotalDashboard;
