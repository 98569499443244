import React, { FC, useEffect } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Swal from "sweetalert2";
import {   Paper, Grid, TextField, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { SCUIButton } from "../../../atomic/atoms"; 
import "./CacheManagement.scss";
import { SCUIContent } from "../../../atomic/organisms";
import { SCUICollapsiblePaper } from "../../../atomic/molecules";


export const CacheManagement: FC<any> = (props: any) => {
  const [cacheValue, setCacheValue] = React.useState("");

  const columns: GridColDef[] = [
    { field: "id", headerName: "Grup", flex: 1 },
    { field: "lastName", headerName: "Sorumlu Grup", flex: 1 },
    { field: "age", headerName: "Komut", flex: 1 },
    { field: "parametre", headerName: "Parametre", flex: 1 },
    { field: "redisdb", headerName: "Redis DB", flex: 1 },

    {
      field: "sil",
      headerName: " ",
      sortable: false,
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        const onClick = (e: any) => {
          e.stopPropagation();
          Swal.fire({
            text:
              params.id +
              " nolu " +
              params.row.keyWord +
              " etiketini silmek istediğinizden emin misiniz?",
            cancelButtonText: "İptal",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sil",
          }).then((result: any) => {
            if (result.isConfirmed) 
              Swal.fire("Silindi!", " ", "success");
 
          });
        };

        return (
          <Button onClick={onClick}>
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];
   const Swal = require("sweetalert2");

 
  const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  ];
  

  

  return (
    <SCUIContent>
      <Grid container direction="row">
      <SCUICollapsiblePaper title="Cache Yönetimi">
          <Grid container spacing={2}>
         <Grid item xs={6} sm={6} lg={8} xl={8}>
              <TextField
                label="Cache Değeri"
                value={cacheValue}
                onChange={(event) => {
                   setCacheValue(event.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3} sm={3} lg={2} xl={2}>
              <SCUIButton
                text={"Ara"}
                type="primary"
                sizeType="fixed"
                size={"m"}
               
              />
            </Grid> 
          </Grid>
        </SCUICollapsiblePaper>

        <Paper sx={{ width: "100%", margin: "2rem", padding: "2rem" }}>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[5]}
            />
          </div>
        </Paper>
      </Grid>
    </SCUIContent>
  );
};
