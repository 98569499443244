import React, { FC, useState, useEffect } from "react";
import "./CommentList.scss";
import { initialState, tableHeadList } from "./CommentList.config";
import { SCUIContent } from "../../../atomic/organisms";
import {
  SCUICommentListForm,
  SCUIGrid,
  SCUICollapsiblePaper,
} from "../../../atomic/molecules";
import { Grid, Paper } from "@mui/material";
import MerchantQueryService from "../../../services/query/MerchantQueryService";
import TimeHelper from "../../../utils/time";
import { parseCommentListParams } from "./CommentList.helper";
import {
  enumCommentStatusFlowStatusName,
  enumCommentStatusFlowStatusTrName,
} from "../../../types/enums/status";
import { PageUrl } from "../../../types/enums";
import MerchantCommandService from "../../../services/command/MerchantCommandService";
import Swal from "sweetalert2"; 

export const CommentList: FC<any> = (props: any) => {
  const [tagNames, setTagNames] = React.useState([]);
  const [commentList, setCommentList] = useState(initialState.commentList);
  const [pageCount, setPageCount] = useState();
  const [param, setParam] = useState('');
  const [urls, setUrls] = useState('');
  const [loading, setLoading] = useState(initialState.loading);
  const [paginationInfo, setPaginationInfo] = useState(
    initialState.paginationInfo
  );
  const [operators, setOperators] = React.useState([]);
  const [filterInfo, setFilterInfo] = useState(props.history.location.state?.filterInfo?props.history.location.state?.filterInfo:initialState.filterInfo);
  let array_ : any =[{

    applicationUserId: 1,
    combinedId: "",
    createDate: "",
    domainName: "",
    email: "",
    hrref: "?",
    isActive: true,
    updateDate: "",
    userRef: -1,
    username: "[Kullanıcı Seçiniz]"

  }  ]


  const _onChangePage = (key: string, value: number) => {
    setPaginationInfo((prevState: any) => ({ ...prevState, [key]: value }));
  };
  const _onClickRowField = (
    rowId: string | number,
    cellInfo: any,
    rowInfo: any
    
  ) => {
      if (cellInfo === "Detay")
      props.history.push({
        pathname: PageUrl.commentDetail.url + "/?CommentId=" + rowInfo.id, 
        state: {
          yorumId: rowInfo.id,
          filterInfo:filterInfo
        },
      });

      if (cellInfo === "Bana Ata")
   { postAssignToMe(rowInfo.id)}
  };
   const postAssignToMe = async (id:any): Promise<any> => {
    let params = { rowId: Number(id) }; 
     MerchantCommandService.PostAssigToMe(params)
      .then((res: any) => {
       res?
        Swal.fire({
          icon: "success",
          title: "Atandı",
          showConfirmButton: false,
          timer: 1500,
        }):
        Swal.fire({
          icon: "error",
          title: "Atanamadı",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error: any) => {
        Swal.fire({
          icon: "error",
          title: "Atanamadı",
          showConfirmButton: false,
          timer: 1500,
        });
        console.log("Hata :>> ", error);
      })
      .finally(() => {});
  };  
  const GetCommentTicketList = async () => {
    try {
      let commentText: any = await MerchantQueryService.GetCommentTicketList();
      setTagNames(commentText.data);
    } catch (error) {
      console.log("Hata :>> ", error);
    }
  };
  const GetOperatorList = async () => {
    try {
      let operator: any = await MerchantQueryService.GetOperatorService();
      let arr  :any = [...array_,...operator];
      setOperators(arr)

    } catch (error) {
      console.log("Hata :>> ", error);
    }
  };
  useEffect(() => {
    GetCommentTicketList();
    getCommentList();
    GetOperatorList();
  }, []);

  useEffect(() => {
    getCommentList();
  }, [filterInfo, paginationInfo.rowsPerPage, paginationInfo.currentPage]);
  const filterData = (filteredInfo: any) => {
    paginationInfo.currentPage = 0;
    setFilterInfo(filteredInfo);
  };
 
  const getCommentList = () => {
    if (!loading) {
      setLoading(true);
      let param: any = parseCommentListParams(
        paginationInfo,
        filterInfo.optionId,
        filterInfo.specialCode,
        filterInfo.colorCode,
        filterInfo.createDate,
        filterInfo.endDate,
        filterInfo.starScore,
        filterInfo.commentStatus,
        filterInfo.tagName,
        filterInfo.operator
      );
       MerchantQueryService.GetCommentData(param)
        .then((res: any) => {
          if (res) {
            let parseData = res.data.map((item: any) => {
              return {
                id: item.id || "-",
                mail: item.userEmail || "-",
                special: item.specialCode || "-",
                colorCode: item.colorcode || "-",
                createDate: TimeHelper.formatDate(item.commentDate) || "-",
                status: item.starScore || "-",
                commentStatus: {
                  text: enumCommentStatusFlowStatusTrName[item.status],
                  className:
                    enumCommentStatusFlowStatusName[item?.status]?.statusType,
                  id: item?.status,
                },
                tag: item.tagName || "-",
                operator:item?.userOperatorName || "-",
                detay: "Detay",
                banaAta: "Bana Ata",

              };
            });
            setCommentList(parseData); 
            let totalCounts = res.count;
            let pageSize = res.take;
            setPageCount(res.count);
            setPaginationInfo({
              rowsPerPage: pageSize,
              pageCount: res.totalPages,
              totalCount: totalCounts,
              currentPage: paginationInfo.currentPage,
            });
            
            setParam(param);
            setUrls(""+param);
          }
        })
        .catch((error: any) => {
          console.log("Hata :>> ", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  

  return (
    <SCUIContent>
      <Grid container direction="row">
        <SCUICollapsiblePaper title="Yorum Listesi">
          <SCUICommentListForm
            formFilterInfo={filterData}
            tagNames={tagNames}
            operators={operators}
            commentList = {commentList}
            pageCount = {pageCount}
            param = {param}
            urls = {urls}
            myFilterInfo={filterInfo}
          />
          
          
        </SCUICollapsiblePaper>
      
        <Paper sx={{ padding: "2rem", margin: "2rem", width: "100%" }}>
          <SCUIGrid
            history={props.history}
            rows={commentList}
            headCells={tableHeadList}
            onChangePage={_onChangePage}
            paginationInfo={paginationInfo}
            //  onClickRow={_onClickSelectedRow}
            onClickRowField={_onClickRowField}
            loading={loading}
          />
        </Paper>
      </Grid>
    </SCUIContent>
  );
};
