import React, {
  useEffect,
  useState,
  ReactChildren,
  ReactChild,
  FC,
} from "react";
import Grid from "@mui/material/Grid";
//--
import "./SCUIPage.scss";
import { SCUIHeader, SCUIFooter } from "../../organisms";
interface ISCUIPage {
  children?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  history?: any;
  location?: any;
  match?: any;
  staticContext?: any;
  showFooter?: boolean;
}

export const SCUIPage: FC<ISCUIPage> = ({
  children,
  history,
  location,
  match,
  staticContext,
  showFooter = false,
}) => {
  return <Grid container className="sc-content-area">
    <Grid item xs={12}>
      <SCUIHeader history={history} />
    </Grid>
    <Grid item xs={12}>
      <section className="content-wrapper">{children}</section>
    </Grid>
    {showFooter && (
      <Grid item xs={12}>
        {/* <SCUIFooter history={history} /> */}
      </Grid>
    )}
  </Grid>

};
/*
    <section className="content-area">
      <SCUIHeader
        scrollIsTop={scrollTopPosition === 0}
        history={history}
      />
      <section className="content-wrapper">{children}</section>
      { showFooter && ( <SCUIFooter history={history} /> ) }
    </section>


*/
