import { Grid, Paper } from "@mui/material";
import { Component } from "react";
import { SCUIButton, SCUIButtonFileUpload, SCUILoading } from "../../../atomic/atoms";
import { SCUIContent } from "../../../atomic/organisms";
import MerchantCommandService from "../../../services/command/MerchantCommandService";
import { SCUISnackbar, SCUIAlertDialog } from "../../../atomic/molecules";
import AuthService from "../../../services/auth/Auth.services";
import Helper from "../../../utils/helper";
import Select from "react-select";
import jwtDecode from "jwt-decode";
import DataTable from "react-data-table-component";
import customStylesTable from "../../Orders/Const";
import { PageUrl } from "../../../types/enums";
import { connect } from "react-redux";
import { handlerMpList } from "../../../stores/redux/actions/lookupAction/lookupAction";
import { handlerMpOriginCodeList } from "./../../../stores/redux/actions/lookupAction/lookupAction";

var autService = new AuthService();

class ProductImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: "",
      openMessage: false,
      openDialog: false,
      validationMessage: null,
      selectedFiles: [],
      selectedMpOrigin: {},
      status: "",
      showErrorTable: false,
      errorData: [],
      disabledButton:false,
      loading:false,
    };
  }
  componentDidMount() {
    this.getMpList();
    this.getMpOriginCodeList();
    autService.getUserInfo().then((getUserInfo) => {
      if (!Helper.isNil(getUserInfo)) {
        const decodedToken = jwtDecode(getUserInfo.access_token);
        this.setState({ UserName: decodedToken?.preferred_username });
      }
    });
  }

  onChange(e) {
    if (e.length > 1) {
      this.setState({
        selectedFiles: [],
        openMessage: true,
        validationMessage: "Yalnız 1 adet seçebilirsiniz !",
        status: "warning",
      });
    } else {
      if (!Helper.objectIsEmpty(this.state?.selectedMpOrigin)) {
        this.setState({
          selectedFiles: e,
          showErrorTable: false,
          errorData: [],
        });
      } else {
        this.setState({
          // selectedFiles: [],
          openMessage: true,
          validationMessage:
            "Ürün Yüklemesi Yapmak İçin Önce MarketPlace Seçilmelidir!",
          status: "warning",
        });
      }
    }
  }

  getMpList() {
    this.props.handlerMpList();
  }

  getMpOriginCodeList() {
    this.props.handlerMpOriginCodeList();
  }

  pushData() {
    if (this.state.selectedFiles.length > 0) {
      this.setState({disabledButton:true,loading:true,showErrorTable:false})
      var excelImportModel = {
        OriginCode: this.state?.selectedMpOrigin?.value, // ?????????????????
        FileType: "Product",
      };

      const data = Helper.jsonToFormData(excelImportModel);
      for (let index = 0; index < this.state.selectedFiles.length; index++) {
        data.append("File", this.state.selectedFiles[index]);
      }

      MerchantCommandService.ImportExcel(data)
        .then((res) => {
          this.setState({
            selectedFiles: [],
            openDialog: true,
            validationMessage: "Excel veri yükleme başarıyla tamamlandı",
            status: "success",
            disabledButton:false,
            loading:false
          });
        })
        .catch((res) => {
          this.setState({
            showErrorTable: true,
            errorData: res?.data?.data,
            openMessage: true,
            validationMessage: "İçerik hatası !",
            status: "danger",
            disabledButton:false,
            loading:false
          });
        });
    } else {
      this.setState({
        openMessage: true,
        validationMessage: "Herhangi bir dosya yüklenmedi!",
        status: "warning",
      });
    }
  }

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL.split(",")[1]);
      };
    });
  };

  onClickRedirect = () => {
    this.props.history.push(PageUrl.ProductDataList.url);
  };

  render() {
    const columns = [
      { omit: true, selector: (row) => row.id, name: "Id", grow: 0 },
      {
        selector: (row) => row.page,
        name: "Sayfa",
        maxWidth: "20%",
        cell: (row) => <div>{row.page}</div>,
      },
      { selector: (row) => row.row, name: "Satır", maxWidth: "10%" },
      { selector: (row) => row.column, name: "Sütün", maxWidth: "10%" },
      {
        selector: (row) => row.message,
        name: "Hata",
        cell: (row) => <div>{row.message}</div>,
      },
    ];

    const customStyles = {
      zIndex: "inherit",
      control: (base) => ({
        ...base,
        height: 55,
        minHeight: 35,
        zIndex: 9999,
        flex: 10,
      }),
      menuPortal: (base) => ({
        ...base,

        zIndex: 9999,
      }),
      container: (provided) => ({
        ...provided,
        width: "190px",
        height: "50px", // Ana container'ın genişliğini belirleyebilirsiniz
      }),
    };

    return (
      <SCUIContent>
        <Grid container direction="column">
          <Paper sx={{ padding: "2rem", margin: "2rem", width: "100%" }}>
            <Grid container spacing={1}>
              <Grid item xs={10} mt={3}>
                <Select
                  styles={customStyles}
                  placeholder={"Origin Code"}
                  value={this.state.selectedMpOrigin}
                  onChange={(e) =>
                    this.setState(
                      {
                        disabledButton: false,
                        selectedMpOrigin: e,
                        filterDb: {
                          ...this.state.filterDb,
                          OriginCode: e?.value,
                          page: 1,
                        },
                      },
                      () => {}
                    )
                  }
                  options={this.props?.mpOrderOriginCodeList}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={10} mt={3}>
                <SCUIButtonFileUpload
                  onChange={(e) => this.onChange(e)}
                  selectedFiles={this.state.selectedFiles}
                />
              </Grid>
              <Grid item xs={2} mt={3}>
                <SCUIButton
                  text={"Ürün Dosyası Yükle"}
                  // disabled={this.state.disabledButton}
                  type="primary"
                  disabled={
                    !Helper.objectIsEmpty(this.state?.selectedMpOrigin)&&!this.state.disabledButton
                      ? false
                      : true
                  }
                  sizeType="fixed"
                  size={"m"}
                  onClick={() => this.pushData()}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper sx={{ width: "100%", margin: "1rem", padding: "1rem" }}>
            <div style={{ height: 2550, width: "100%" }}>
              {this.state.showErrorTable ? (
                <DataTable
                  columns={columns}
                  data={this.state.errorData}
                  highlightOnHover
                  // expandableRows
                  customStyles={customStylesTable}
                  paginationPerPage={50}
                  pagination={true}
                  paginationServer
                  // paginationTotalRows={this.state.totalCount}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  // paginationDefaultPage={this.state.currentPage}
                  // onChangePage={(page) => this.onChange(page)}
                />
              ) : this.state.loading ? <SCUILoading />:null}
            </div>
          </Paper>
        </Grid>

        <SCUISnackbar
          messageText={this.state.validationMessage}
          status={this.state.status}
          show={this.state.openMessage}
          onClose={() => this.setState({ openMessage: false })}
          autoHideDuration={2000}
        />
        <SCUIAlertDialog
          open={this.state.openDialog}
          status="success"
          iconShow={true}
          showBtnCancel={false}
          showBtnConfirm={true}
          btnConfirmText={"Tamam"}
          onClose={() => this.setState({ openDialog: false })}
          onClickAgreed={this.onClickRedirect}
          content={
            "Ürün datası backoffice e yüklenmiştir. Marketplace e göndermek için lütfen Ürün Veri Listesi sayfasından ➤ mp gönder işlemi yapınız"
          }
        />
      </SCUIContent>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    handlerMpList: handlerMpList(dispatch),
    handlerMpOriginCodeList:()=> handlerMpOriginCodeList(dispatch,false),
  };
};

const mapStateToProps = ({ lookups }) => ({
  mpList: lookups.mpList,
  mpOrderOriginCodeList: lookups.mpOrderOriginCodeList,
});

// withTranslation()(Orders);
export default connect(mapStateToProps, mapDispatchToProps)(ProductImport);
