import React, { FC } from "react";
import Radio from "@mui/material/Radio";

//--
import "./SCUIRadioButton.scss";

enum toastType {
  primary = "primary",
  secondary = "secondary",
  default = "default",
}

interface IProps {
  color?: toastType.default | toastType.secondary | toastType.primary;
  checked?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  indeterminate?: boolean;
  size?: "small" | "medium";
  value?: string;
  name?: string;
  onChange?: () => void;
  className?: string;
}

export const SCUIRadioButton: FC<IProps> = ({
  color = toastType.default,
  checked,
  disabled = false,
  defaultChecked = false,
  size = "small",
  value,
  name,
  onChange,
  className = "",
}) => {
  return (
    <Radio
      className={`sc-radio ${className}`}
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={onChange}
      value={value}
      name={name}
      inputProps={{ "aria-label": value }}
      color={color}
      disabled={disabled}
    />
  );
};
