import React, { useEffect } from "react";
import { Provider } from "react-redux"; //-
import rootReducer from "./stores/redux/reducers";
//-
import MainRouter from "./routers/mainRouter/main-router";
import configureStore, { history } from "./stores/redux/configureStore";

export const App = () => {
  useEffect(() => {
    //indexedDB create
    //language select
    return () => {};
  }, []);

  const store: any = configureStore({
    reducer: rootReducer,
  });

  return (
    <Provider store={store}>
      <section className="container">
        <MainRouter />
      </section>
    </Provider>
  );
};
