import React, { FC } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import "./SCUIOperatorMainRow.scss";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";

import MerchantCommandService from "../../../services/command/MerchantCommandService";

interface IProps {
  row: any;
  refreshOperatorList?: any;
  roleId?: any;
}
export const SCUIOperatorMainRow: FC<IProps> = ({
  row,
  refreshOperatorList,
  roleId,
}) => {
  function deleteOperator(row: any) {
    Swal.fire({
      text:
        row.username +
        " kişisini bu rolden kaldırmak istediğinize emin misiniz?",
      cancelButtonText: "İptal",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Kaldır",
    }).then((result: any) => {
      if (result.isConfirmed) {
        let params: any = {};
        params.applicationUserId = Number(row.applicationUserId);
        params.roleId = Number(roleId);

        MerchantCommandService.DeleteOperatorFromRole(params)
          .then((res: any) => {
            Swal.fire(res.message, "Operatör silindi ", "success");
            refreshOperatorList();
          })
          .catch((error: any) => {
            Swal.fire("", "Kullanıcı silinemedi. ", "error");
            console.log("Hata :>> ", error);
          })
          .finally(() => {});
      }
    });
  }

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        key={row.operatorItemId}
      >
        <TableCell align="center">{row.username}</TableCell>
        <TableCell align="center">{row.domainName}</TableCell>
        <TableCell align="center">
          <Button onClick={() => deleteOperator(row)}>
            <DeleteIcon />
          </Button>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
