import React, { FC, useState, useEffect } from "react";
import { Checkbox, Button } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import "./SCUIMenuRoleDialogContent.scss";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import CancelIcon from "@material-ui/icons/Cancel";
import _without from "lodash/without";
import MerchantCommandService from "../../../services/command/MerchantCommandService";
import MerchantQueryService from "../../../services/query/MerchantQueryService";
import Swal from "sweetalert2";
import ListItemText from "@mui/material/ListItemText";

interface IProps {
  selectedRow?: any;   
  onCloseDialog?: any;
  roles?: any;
}
export const SCUIMenuRoleDialogContent: FC<IProps> = ({
  selectedRow = null,  
  onCloseDialog,
  roles,
}) => {
  const [roleName, setRoleName] = React.useState<any[]>([]);
  useEffect(() => {
    GetRolesList();
  }, []);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const GetRolesList = () => {
    MerchantQueryService.GetMenuRoleList(selectedRow.menuItemId)
      .then((res: any) => {
        if (res) {
          let arr: number[] = [];
          res.model.data.map((item: any) => arr.push(item.roleId));
          setRoleName(arr); 
        }
      })
      .catch((error: any) => {
        console.log("Hata :>> ", error);
      });
  };
  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    setRoleName((current) => _without(current, value));
  };

  const _handlChangeMenu = (event: any) => {
    const {
      target: { value },
    } = event;
    setRoleName(typeof value === "string" ? value.split(",") : value);
  };

  const submitHandler = (event: any) => {
    event.preventDefault();
    console.info(roleName);
    let params: any = {};
    params.menuId = selectedRow.menuItemId;
    params.roleID = roleName;
    MerchantCommandService.PostMenuItemRoleAdd(params)
      .then((res: any) => {
        Swal.fire("Güncellendi!", " ", "success");
        onCloseDialog();
      })
      .catch((error: any) => {
        console.log("Hata :>> ", error);
      })
      .finally(() => {});
  };
  return (
    <div>
      <form onSubmit={submitHandler}>
        <DialogContent>
          <FormControl sx={{ m: 1, width: 400 }}>
            <InputLabel id="parentMenuLabel">Rol Listesi</InputLabel>
            <Select
              fullWidth
              multiple
              labelId="parentMenuLabel"
              id="parentMenuLabel"
              value={roleName}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((roleId) => (
                    <Chip
                      key={roleId}
                      label={
                        roles?.find((e: any) => e.roleId === roleId).roleNameKey
                      }
                      clickable
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event: any) => event.stopPropagation()}
                        />
                      }
                      onDelete={(e) => handleDelete(e, roleId)}
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
              label="Durumum"
              onChange={(e) => _handlChangeMenu(e)}
            >
              {roles &&
                roles.map((role: any) => (
                  <MenuItem key={role.roleId} value={role.roleId}>
                    <Checkbox checked={roleName.indexOf(role.roleId) > -1} />
                    <ListItemText primary={role.roleNameKey} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button type="submit">güncelle</Button>
          <Button onClick={onCloseDialog}>iptal</Button>
        </DialogActions>
      </form>
    </div>
  );
};
