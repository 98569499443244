import { enumRoleCategories } from "./roles";
import { enumSellerRegisterTabs } from "../enums/tabs-enums";

enum enumActionStatus {
  create = 0,
  update = 1,
  delete = 2,
  read = 3,
}

enum enumSellerCurrentSaveFlowStatus {
  preNewCreate = -1, //null
  newCreate = 1, //start yeni oluşturulmuş .
  draft = 2, //Taslak
  submit = 3, //Submit --> Onay bekliyor
  revision = 4, //Revizyon için gönderilmiş
  revisionDraft = 5, //RevisionDraft -->  Revizyondan gelen başvurunun güncellenmes
  reject = 6, //reject --> Reddedilmiş
  approved = 7, //Onaylanmış
}

enum enumBrandFlowStatus {
  waitingForApproval = 1,
  approved = 2,
  passive = 3,
  active = 4,
  denied = 5,
}
enum enumCommentFlowStatus {
  approved = 0,
  noComment = 1,
  pending = 2,
  rejected = 3
} 

enum enumStarScoreFlowStatus {
  minusOne = -1,
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
}

enum enumCommentStatusFlowStatus {
  minusOne = -1,
  one = 1,
  two = 2,
  three = 3,
}

enum enumAddressDiff {
  different = 0,
  same = 1,
}

const enumRolesFlowAction: any = {
  [enumRoleCategories.admin]: {
    [enumSellerCurrentSaveFlowStatus.preNewCreate]: enumActionStatus.read,
    [enumSellerCurrentSaveFlowStatus.newCreate]: enumActionStatus.read,
    [enumSellerCurrentSaveFlowStatus.draft]: enumActionStatus.read,
    [enumSellerCurrentSaveFlowStatus.submit]: enumActionStatus.create,
    [enumSellerCurrentSaveFlowStatus.revision]: enumActionStatus.read,
    [enumSellerCurrentSaveFlowStatus.revisionDraft]: enumActionStatus.read,
    [enumSellerCurrentSaveFlowStatus.reject]: enumActionStatus.read,
    [enumSellerCurrentSaveFlowStatus.approved]: enumActionStatus.read,
  },
  [enumRoleCategories.user]: {
    [enumSellerCurrentSaveFlowStatus.preNewCreate]: enumActionStatus.create,
    [enumSellerCurrentSaveFlowStatus.newCreate]: enumActionStatus.create,
    [enumSellerCurrentSaveFlowStatus.draft]: enumActionStatus.create,
    [enumSellerCurrentSaveFlowStatus.submit]: enumActionStatus.read,
    [enumSellerCurrentSaveFlowStatus.revision]: enumActionStatus.create,
    [enumSellerCurrentSaveFlowStatus.revisionDraft]: enumActionStatus.create,
    [enumSellerCurrentSaveFlowStatus.reject]: enumActionStatus.read,
    [enumSellerCurrentSaveFlowStatus.approved]: enumActionStatus.read,
  },
};

const enumSellerCurrentSaveFlowStatusTypeName = {
  [enumSellerCurrentSaveFlowStatus.preNewCreate]: "preNewCreate",
  [enumSellerCurrentSaveFlowStatus.newCreate]: "Start",
  [enumSellerCurrentSaveFlowStatus.draft]: "Draft",
  [enumSellerCurrentSaveFlowStatus.submit]: "Submit",
  [enumSellerCurrentSaveFlowStatus.revision]: "Revision",
  [enumSellerCurrentSaveFlowStatus.revisionDraft]: "RevisionDraft", //user
  [enumSellerCurrentSaveFlowStatus.reject]: "Reject",
  [enumSellerCurrentSaveFlowStatus.approved]: "Approved",
};

const enumSellerFlowInfo: any = {
  [enumSellerCurrentSaveFlowStatus.preNewCreate]: {
    action: {
      user: enumActionStatus.create,
      admin: enumActionStatus.read,
    },
  },
  [enumSellerCurrentSaveFlowStatus.newCreate]: {
    action: {
      user: enumActionStatus.create,
      admin: enumActionStatus.read,
    },
  },
  [enumSellerCurrentSaveFlowStatus.draft]: {
    action: {
      user: enumActionStatus.create,
      admin: enumActionStatus.read,
    },
  },
  [enumSellerCurrentSaveFlowStatus.submit]: {
    action: {
      user: enumActionStatus.read,
      admin: enumActionStatus.create,
    },
  },
  [enumSellerCurrentSaveFlowStatus.revision]: {
    action: {
      user: enumActionStatus.create,
      admin: enumActionStatus.read,
    },
  },
  [enumSellerCurrentSaveFlowStatus.revisionDraft]: {
    action: {
      user: enumActionStatus.create,
      admin: enumActionStatus.read,
    },
  },
  [enumSellerCurrentSaveFlowStatus.reject]: {
    action: {
      user: enumActionStatus.read,
      admin: enumActionStatus.read,
    },
  },
  [enumSellerCurrentSaveFlowStatus.approved]: {
    action: {
      user: enumActionStatus.read,
      admin: enumActionStatus.read,
    },
  },
};

const enumSellerCurrentSaveFlowStatusName: any = {
  [enumSellerCurrentSaveFlowStatus.preNewCreate]: {
    name: enumSellerCurrentSaveFlowStatusTypeName[enumSellerCurrentSaveFlowStatus.preNewCreate],
    statusType: "light",
  },
  [enumSellerCurrentSaveFlowStatus.newCreate]: {
    name: enumSellerCurrentSaveFlowStatusTypeName[enumSellerCurrentSaveFlowStatus.newCreate],
    statusType: "dark",
  },
  [enumSellerCurrentSaveFlowStatus.draft]: {
    name: enumSellerCurrentSaveFlowStatusTypeName[enumSellerCurrentSaveFlowStatus.draft],
    statusType: "secondary",
  },
  [enumSellerCurrentSaveFlowStatus.submit]: {
    name: enumSellerCurrentSaveFlowStatusTypeName[enumSellerCurrentSaveFlowStatus.submit],
    statusType: "warning",
  },
  [enumSellerCurrentSaveFlowStatus.revision]: {
    name: enumSellerCurrentSaveFlowStatusTypeName[enumSellerCurrentSaveFlowStatus.revision],
    statusType: "info",
  },
  [enumSellerCurrentSaveFlowStatus.revisionDraft]: {
    name: enumSellerCurrentSaveFlowStatusTypeName[enumSellerCurrentSaveFlowStatus.revisionDraft],
    statusType: "primary",
  }, //user
  [enumSellerCurrentSaveFlowStatus.reject]: {
    name: enumSellerCurrentSaveFlowStatusTypeName[enumSellerCurrentSaveFlowStatus.reject],
    statusType: "danger",
  },
  [enumSellerCurrentSaveFlowStatus.approved]: {
    name: enumSellerCurrentSaveFlowStatusTypeName[enumSellerCurrentSaveFlowStatus.approved],
    statusType: "success",
  },
};

const enumSellerFlowStatusTrName: any = {
  [enumSellerCurrentSaveFlowStatus.preNewCreate]: {
    name: "Form Oluşturma öncesi",
    message: "Form oluşturuldu.",
  },
  [enumSellerCurrentSaveFlowStatus.newCreate]: {
    name: "Yeni Form",
    message: "Yeni form eklendi.",
  },
  [enumSellerCurrentSaveFlowStatus.draft]: {
    name: "Taslak.",
    message: "Taslak kaydedildi.",
  },
  [enumSellerCurrentSaveFlowStatus.submit]: {
    name: "Onay.",
    message: "Onay bekleniyor.",
  },
  [enumSellerCurrentSaveFlowStatus.revision]: {
    name: "Revizyon",
    message: "Revize bekleniyor.",
  },
  [enumSellerCurrentSaveFlowStatus.revisionDraft]: {
    name: "Revize",
    message: "Revize onayı bekleniyor.",
  }, //user
  [enumSellerCurrentSaveFlowStatus.reject]: {
    name: "Red",
    message: "Reddedildi.",
  },
  [enumSellerCurrentSaveFlowStatus.approved]: {
    name: "Onaylandı",
    message: "Onaylandı.",
  },
};

const enumBrandFlowStatusName: any = {
  [enumBrandFlowStatus.waitingForApproval]: {
    name: "waitingForApproval",
    statusType: "warning",
  },
  [enumBrandFlowStatus.approved]: { name: "approved", statusType: "success" },
  [enumBrandFlowStatus.passive]: { name: "passive", statusType: "secondary" },
  [enumBrandFlowStatus.active]: { name: "active", statusType: "info" },
  [enumBrandFlowStatus.denied]: { name: "denied", statusType: "danger" },
};

const enumStarScoreFlowStatusName: any = {
  [enumStarScoreFlowStatus.one]: {
    name: "one",
    statusType: "warning",
  },
  [enumStarScoreFlowStatus.two]: { name: "two", statusType: "success" },
  [enumStarScoreFlowStatus.three]: { name: "three", statusType: "secondary" },
  [enumStarScoreFlowStatus.four]: { name: "four", statusType: "info" },
  [enumStarScoreFlowStatus.five]: { name: "five", statusType: "danger" },
};
const enumCommentStatusFlowStatusName: any = {
  
  [enumCommentStatusFlowStatus.one]: { name: "one", statusType: "warning" },
  [enumCommentStatusFlowStatus.two]: { name: "two", statusType: "approved" },
  [enumCommentStatusFlowStatus.three]: { name: "three", statusType: "danger" },
};

const enumBrandNameFlowStatusId: any = {
  waitingForApproval: enumBrandFlowStatus.waitingForApproval,
  approved: enumBrandFlowStatus.approved,
  passive: enumBrandFlowStatus.passive,
  active: enumBrandFlowStatus.active,
  denied: enumBrandFlowStatus.denied,
};

const enumBrandFlowStatusTrName: any = {
  [enumBrandFlowStatus.waitingForApproval]: "Onay Bekleniyor.",
  [enumBrandFlowStatus.approved]: "Onaylandı.",
  [enumBrandFlowStatus.passive]: "Pasife alındı.",
  [enumBrandFlowStatus.active]: "Aktife alındı.",
  [enumBrandFlowStatus.denied]: "Reddedildi.",
};
const enumStarScoreFlowStatusTrName: any = {
  [enumStarScoreFlowStatus.minusOne]: "Tümü",
  [enumStarScoreFlowStatus.one]: "1 Yıldız",
  [enumStarScoreFlowStatus.two]: "2 Yıldız",
  [enumStarScoreFlowStatus.three]: "3 Yıldız",
  [enumStarScoreFlowStatus.four]: "4 Yıldız",
  [enumStarScoreFlowStatus.five]: "5 Yıldız",
};
const enumCommentStatusFlowStatusTrName: any = {
  [ enumCommentStatusFlowStatus.minusOne]: "Tümü",
  [ enumCommentStatusFlowStatus.one]: "Onay Bekliyor",
  [ enumCommentStatusFlowStatus.two]: "Onaylandı",
  [ enumCommentStatusFlowStatus.three]: "Reddedildi",
  
};
const enumCommentRejectReasonName: any = {
   1:"Fiyat",
2:"Yabancı Dil Kullanımı",
3:"Fiyat Kaynaklı",
4:"Uygunsuz içerik/Ahlak Kuralları",
5:"Reklam/Firma Markası",
6:"Toplumsal Ayrıştırma/Siyasi"
};

const enumCommentFlowStatusName: any = {
  [enumCommentFlowStatus.approved]: {name: "approved", statusType: "success",},
  [enumCommentFlowStatus.noComment]: { name: "noComment", statusType: "secondary" },
  [enumCommentFlowStatus.pending]: { name: "pending", statusType: "info" },
  [enumCommentFlowStatus.rejected]: { name: "rejected", statusType: "danger" }
  
};

const enumCommentNameFlowStatusId: any = {
  approved: enumCommentFlowStatus.approved,
  noComment: enumCommentFlowStatus.noComment,
  pending: enumCommentFlowStatus.pending,
  rejected: enumCommentFlowStatus.rejected, 
};

const enumCommentFlowStatusTrName: any = {
  [enumCommentFlowStatus.approved]: "Yorum Yok.",
  [enumCommentFlowStatus.noComment]: "Onay Bekliyor.",
  [enumCommentFlowStatus.pending]: "Onaylandı.",
  [enumCommentFlowStatus.rejected]: "Reddedildi.", 
};

export {
  enumBrandFlowStatusName,
  enumSellerCurrentSaveFlowStatus,
  enumSellerCurrentSaveFlowStatusTypeName,
  enumSellerCurrentSaveFlowStatusName,
  enumBrandFlowStatusTrName,
  enumSellerFlowStatusTrName,
  enumBrandNameFlowStatusId,
  enumActionStatus,
  enumAddressDiff,
  enumBrandFlowStatus,
  enumSellerFlowInfo,
  enumRolesFlowAction,
  enumStarScoreFlowStatus,
  enumStarScoreFlowStatusTrName,
  enumStarScoreFlowStatusName,
  enumCommentStatusFlowStatusTrName,
  enumCommentStatusFlowStatus,
  enumCommentStatusFlowStatusName,
  enumCommentFlowStatus,
  enumCommentFlowStatusName,
  enumCommentRejectReasonName,
  enumCommentNameFlowStatusId,enumCommentFlowStatusTrName
};
