import React, { FC } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
//--
import { SCUIToast } from "../../atoms";
import {enumSnackbarStatusType} from '../../../types/enums';
//-
import "./SCUISnackbar.scss";

interface IProps {
  show?: boolean;
  onClose?: () => void;
  onShow?: () => void;
  messageText?: string;
  key?: string;
  status:enumSnackbarStatusType.success | enumSnackbarStatusType.warning |enumSnackbarStatusType.danger | enumSnackbarStatusType.info
   | enumSnackbarStatusType.danger;
  slideDirection?: string;
  autoHideDuration?: number;
}

export const SCUISnackbar: FC<IProps> = ({
  show,
  messageText = "",
  status = enumSnackbarStatusType.success,
  slideDirection = "center",
  onShow,
  onClose,
  autoHideDuration = 1250,
}) => {
  const _onClose = (
    event: any,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    if (onClose) {
      onClose();
    }
  };

  const _onExited = () => {
    //setMessageInfo(undefined);
  };

  const handleTransitionClick = () => () => {
    if (onShow) {
      onShow();
    }
  };

  const SnackbarWrapper = React.forwardRef(function _renderSnackbarWrapper(
    props: any,
    ref: any,
  ) {
    return (
      <div ref={ref}>
        <SCUIToast type={status} text={messageText} />
      </div>
    );
  });
  return (
   
    <Snackbar
      key={messageText}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      className={`sc-snackbar ${status}`}
      open={show}
      autoHideDuration={autoHideDuration}
      onClose={_onClose}
      message={messageText}
      action={
        <React.Fragment>
          <IconButton
            aria-label="close"
            color="inherit"
            className={""}
            onClick={_onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </React.Fragment>
      }
      TransitionProps={{
        onExited: _onExited,
      }}
    >
      <SnackbarWrapper />
    </Snackbar>
  );
};
