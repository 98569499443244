import { Component } from "react";
import { Grid, Paper, TextField } from "@mui/material";
import { SCUIContent } from "../../../atomic/organisms";
import {
  SCUIAlertDialog,
  SCUICollapsiblePaper,
  SCUISnackbar,
} from "../../../atomic/molecules";
import MerchantQueryService from "../../../services/query/MerchantQueryService";
import TimeHelper from "../../../utils/time";
import { withTranslation } from "react-i18next";
import { SCUIButton, SCUILoading } from "../../../atomic/atoms";
import DataTable from "react-data-table-component";
import Select from "react-select";
import customStylesTable from "../Const";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { tr } from "date-fns/locale";
import SaleDetail from "./SaleDetail/SaleDetail";
import ReturnDetail from "./ReturnDetail/ReturnDetail";
import { handlerSalesMpOriginCodeList } from "../../../stores/redux/actions/lookupAction/lookupAction";
import { connect } from "react-redux";
import { TransactionType } from "../../../types/enums/transactionType";
import { OperationType } from "../../../types/enums/operationType";
import CustomizedMenus from "./menuComponent";
import WholeSaleDetail from "./WholeSale/WholeSaleDetail";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledButton: true,
      disabledCsvButton: false,
      disabledExcelButton: false,
      openDialog: false,
      alertMessageText: "",
      messageText: "",
      data: [],
      totalCount: null,
      loading: true,
      currentPage: 1,
      selectedType: {
        value: [TransactionType.ADVANCE, TransactionType.WHOLESALE],
        label: "Satış",
      },
      selectedMpOrigin: [],
      filterDb: {
        transactionType: [TransactionType.ADVANCE, TransactionType.WHOLESALE],
        documentSequenceNumber: null,
        originDocumentNumber: null,
        documentNumber: null,
        OriginCodes: [],
        startDate: null,
        endDate: null,
        fiscalStartDate:null,
        fiscalEndDate:null,
        page: 1,
        operationTypes : [OperationType.IsConsignee,OperationType.MpxOperated,OperationType.Franchise]
      },
    };
  }

  componentDidMount() {
    this.props.handlerSalesMpOriginCodeList();
    this.getTransactionList(this.state.filterDb);
  }

  onChange(e) {
    this.setState(
      {
        filterDb: {
          ...this.state.filterDb,
          page: e,
        },
      },
      () => {
        this.getTransactionList(this.state.filterDb);
      }
    );
  }

  downloadExcel() {
    this.setState({
      messageText: "Dosya indirme işleminiz devam ediyor...",
      disabledCsvButton: true,
      disabledExcelButton: true,
    });
    MerchantQueryService.DownloadExcel(this.state.filterDb)
      .then((response) => {
        this.setState({
          messageText: "",
          disabledCsvButton: false,
          disabledExcelButton: false,
        });
      })
      .catch((res) => {
        this.setState({
          messageText: "",
          disabledCsvButton: false,
          disabledExcelButton: false,
        });
      });
  }

  downloadCsv() {
    this.setState({
      messageText: "Dosya indirme işleminiz devam ediyor...",
      disabledExcelButton: true,
      disabledCsvButton: true,
    });
    MerchantQueryService.DownloadCsv(this.state.filterDb)
      .then((response) => {
        this.setState({
          messageText: "",
          disabledExcelButton: false,
          disabledCsvButton: false,
        });
      })
      .catch((res) => {
        this.setState({
          messageText: "",
          disabledExcelButton: false,
          disabledCsvButton: false,
        });
      });
  }

  getTransactionList(filterDb) {
    this.setState({ loading: true });
    MerchantQueryService.GetTransactions(filterDb).then((res) => {
      if (res.data) {
        res.data.forEach((x) => {
          x.beginDateTimeStamp = TimeHelper.momentDate(x.beginDateTimeStamp);
          x.orderDate = TimeHelper.momentDate(x.orderDate);
          x.transactionType =
            x.transactionType === TransactionType.ADVANCE ||
              x.transactionType === TransactionType.WHOLESALE
              ? "Satış"
              : "İade";
        });
        this.setState({
          data: res.data,
          loading: false,
          currentPage: filterDb.page,
          totalCount: res.totalCount,
        });
      }
    });
  }

  downloadDespatchFile(row) {
    this.setState({ loading: true });
    MerchantQueryService.GetDocumentByOrderNumber({
      fileName: row.originDocumentNumber,
      OrderNumber: row.originDocumentNumber,
      DocumentType: 1,
    })
      .then((res) => {
        res.blob().then((res) => {
          this.setState({ loading: false });
        });
      })
      .catch((res) => {
        this.setState({
          openDialog: true,
          alertMessageText: "Sorguladığınız e-fatura için gib'den cevap bekleniyor!"
        });
      })
      .finally(this.setState({ loading: false }));
  }

  downloadInvoiceSalesFile(row) {
    this.setState({ loading: true });
    MerchantQueryService.GetDocumentByOrderNumber({
      fileName: row.originDocumentNumber,
      OrderNumber: row.originDocumentNumber,
      DocumentType: 2,
    })
      .then((res) => {
        res.blob().then((res) => {
          this.setState({ loading: false });
        });
      })
      .catch((res) => {
        this.setState({
          openDialog: true,
          alertMessageText: "Sorguladığınız e-fatura için gib'den cevap bekleniyor!"
        });
      })
      .finally(this.setState({ loading: false }));
  }
  showFiscal(row) {
    if (!row.fiscalQRCode) {
      this.setState({
        openDialog: true,
        alertMessageText: "Bu sipariş için Fiscal Bulunmamaktadır!"
      });
    }
    else {
      const decodedUrl = atob(row.fiscalQRCode);

      if (decodedUrl) {
        window.open(decodedUrl, '_blank');
      } else {
        this.setState({
          openDialog: true,
          alertMessageText: "Bu sipariş için Fiscal Bulunmamaktadır!"
        });
      }
    }
  }

  render() {
    const customStyles = {
      zIndex: "inherit",
      control: (base) => ({
        ...base,
        height: 55,
        minHeight: 35,
        zIndex: 9999,
        flex: 10,
      }),
      menuPortal: (base) => ({
        ...base,

        zIndex: 9999,
      }),
      // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //   return {
      //     ...styles,
      //     // color: '#FFF',
      //     // cursor: isDisabled ? 'not-allowed' : 'default',
      //   };
      // },
    };

    const messageCss = {
      color: "black",
      backgroundColor: "transparent",
      padding: "0px",
      fontFamily: "Arial",
      border: "0px",
      overflow: "hidden",
      width: "400px",
      height: "50px",
    };

    const { t } = this.props;

    const columns = [
      { omit: true, selector: (row) => row.id, name: "Id", grow: 0 },
      { selector: (row) => row.transactionType, name: "Tip" },
      {
        selector: (row) => row.marketPlace,
        name: "Market Place",
        width: "12rem",
      },
      { selector: (row) => row.mpOriginCountry, name: t("MP Ülke") },
      // {
      //   selector: (row) => row.shippingCountry,
      //   name: "Sipariş Verilen Ülke",
      //   width: "14rem",
      // },
      {
        selector: (row) => row.orderDate,
        name: "Sipariş Tarihi",
        sortable: true,
        id: "date",
        width: "18rem",
      },
      {
        selector: (row) => row.beginDateTimeStamp,
        name: [TransactionType.ADVANCE, TransactionType.WHOLESALE].some((x) =>
          this.state.filterDb.transactionType.includes(x)
        )
          ? "Satış Tarihi"
          : "İade Tarihi",
        sortable: true,
        id: "date2",
        width: "18rem",
      },
      {
        selector: (row) => row.originDocumentNumber,
        name: "Sipariş No",
        width: "15rem",
      },
      {
        selector: (row) =>
          [TransactionType.ADVANCE, TransactionType.WHOLESALE].some((x) =>
            this.state.filterDb.transactionType.includes(x)
          )
            ? row.saleItemsTotalQuantity
            : row.returnItemsTotalQuantity,
        name: [TransactionType.ADVANCE, TransactionType.WHOLESALE].some((x) =>
          this.state.filterDb.transactionType.includes(x)
        )
          ? "Sipariş Ürün Adedi"
          : "İade Ürün Adedi",
        width: "12rem",
      },
      {
        selector: (row) => row.destTaxableAmount,
        name: "Toplam Tutar (Euro)",
        width: "15rem",
        cell: (row) =>
          row.destTaxableAmount == "0" ? "-" : row.destTaxableAmount.toFixed(2),
      },
      {
        selector: (row) => row.destISOCurrencyCode,
        name: "Para Birimi (Euro)",
        width: "15rem",
        cell: (row) =>
          row.destISOCurrencyCode == "UNDEFINED"
            ? "-"
            : row.destISOCurrencyCode,
      },
      {
        selector: (row) => row.documentSequenceNumber,
        name: [TransactionType.ADVANCE, TransactionType.WHOLESALE].some((x) =>
          this.state.filterDb.transactionType.includes(x)
        )
          ? "Fatura No"
          : "İade Fatura No",
        width: "18rem",
      },
      {
        selector: (row) => "",
        name: "İrsaliye No",
        width: "18rem",
        cell: (row) =>
          row.wholeSale
            ? row.wholeSale.despatchSerialCode +
            row.wholeSale.despatchSequenceNumber
            : "-",
      },
      {
        selector: (row) => row.totalAmountIncludingTAX,
        name: "Toplam Tutar (Lokal)",
        width: "15rem",
        cell: (row) => row.totalAmountIncludingTAX.toFixed(2),
      },
      {
        selector: (row) => row.isoCurrencyCode,
        name: "Para Birimi (Lokal)",
        width: "15rem",
      },
      {
        selector: (row) => row.documentNumber,
        name: "Belge No",
        width: "15rem",
      },
      { selector: (row) => row.businessUnitCode, name: "Depo" },
      {
        selector: (row) => row.status,
        name: "Aksiyon",
        width: "15rem",
        cell: (row) => {
          return (
            <CustomizedMenus
              downloadDespatch={(e) => this.downloadDespatchFile(row)}
              downloadInvoiceSales={(e) => {
                this.downloadInvoiceSalesFile(row);
              }}
              showFiscal={(e) => this.showFiscal(row)}
              disableFiscal={row.fiscalQRCode != null ? false : true}
            />
          );
        },
      },
    ];

    return (
      <SCUIContent>
        <Grid container direction="row">
          <SCUICollapsiblePaper title="Satış ve İade">
            <Grid container spacing={1}>
              <Grid item xs={2.4} mt={3} className="box">
                <Select
                  styles={customStyles}
                  placeholder={"Marketplace"}
                  isMulti
                  value={this.state.selectedMpOrigin}
                  onChange={(e) => {
                    var temp = [];
                    e.forEach((x) => {
                      temp.push(x.value);
                    });
                    this.setState(
                      {
                        disabledButton: false,
                        selectedMpOrigin: e,
                        filterDb: {
                          ...this.state.filterDb,
                          OriginCodes: temp,
                          page: 1,
                        },
                      },
                      () => { }
                    );
                  }}
                  options={this.props.mpOrderOriginCodeList}
                />
              </Grid>

              <Grid item xs={2} mt={3}>
                <Select
                  styles={customStyles}
                  placeholder={"Belge Tipi.."}
                  value={this.state.selectedType}
                  onChange={(e) => {
                    this.setState({
                      disabledButton: false,
                      selectedType: e,
                      filterDb: {
                        ...this.state.filterDb,
                        transactionType: e.value,
                        page: 1,
                      },
                    });
                  }}
                  options={[
                    {
                      value: [
                        TransactionType.ADVANCE,
                        TransactionType.WHOLESALE,
                      ],
                      label: "Satış",
                    },
                    { value: [
                      TransactionType.RETURN,
                      TransactionType.WHOLESALE_RETURN
                    ], label: "İade" },
                  ]}
                />
              </Grid>

              <Grid item xs={1.5} mt={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label={"Satış Başlangıç"}
                    inputFormat="dd/MM/yyyy"
                    value={this.state.filterDb.startDate}
                    onChange={(event) => {
                      event.setHours(0, 0, 0, 0);
                      this.setState({
                        disabledButton: false,
                        filterDb: {
                          ...this.state.filterDb,
                          startDate: event.toLocaleDateString("en-US"),
                          page: 1,
                        },
                      });
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={1.5} mt={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label={"Satış Bitiş"}
                    inputFormat="dd/MM/yyyy"
                    value={this.state.filterDb.endDate}
                    onChange={(event) => {
                      event.setHours(0, 0, 0, 0);
                      this.setState({
                        disabledButton: false,
                        filterDb: {
                          ...this.state.filterDb,
                          endDate: event.toLocaleDateString("en-US"),
                          page: 1,
                        },
                      });
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={1.5} mt={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label={"Fiscal Başlangıç"}
                    inputFormat="dd/MM/yyyy"
                    value={this.state.filterDb.fiscalStartDate}
                    onChange={(event) => {
                      event.setHours(0, 0, 0, 0);
                      this.setState({
                        disabledButton: false,
                        filterDb: {
                          ...this.state.filterDb,
                          fiscalStartDate: event.toLocaleDateString("en-US"),
                          page: 1,
                        },
                      });
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={1.5} mt={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label={"Fiscal Bitiş"}
                    inputFormat="dd/MM/yyyy"
                    value={this.state.filterDb.fiscalEndDate}
                    onChange={(event) => {
                      event.setHours(0, 0, 0, 0);
                      this.setState({
                        disabledButton: false,
                        filterDb: {
                          ...this.state.filterDb,
                          fiscalEndDate: event.toLocaleDateString("en-US"),
                          page: 1,
                        },
                      });
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={1.5} mt={3}>
                <TextField
                  label={"Fatura No"}
                  type="text"
                  value={this.state.filterDb.documentSequenceNumber}
                  onChange={(event) => {
                    this.setState({
                      disabledButton: false,
                      filterDb: {
                        ...this.state.filterDb,
                        documentSequenceNumber: event.target.value,
                        page: 1,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={1.5} mt={3}>
                <TextField
                  label={"Sipariş No"}
                  type="text"
                  value={this.state.filterDb.originDocumentNumber}
                  onChange={(event) => {
                    this.setState({
                      disabledButton: false,
                      filterDb: {
                        ...this.state.filterDb,
                        originDocumentNumber: event.target.value,
                        page: 1,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={1.5} mt={3}>
                <TextField
                  label={"Belge No"}
                  type="text"
                  value={this.state.filterDb.documentNumber}
                  onChange={(event) => {
                    this.setState({
                      disabledButton: false,
                      filterDb: {
                        ...this.state.filterDb,
                        documentNumber: event.target.value,
                        page: 1,
                      },
                    });
                  }}
                  style={{ height: "10px" }}
                />
              </Grid>

              <Grid item xs={1} mt={3.3} mr={5}>
                <SCUIButton
                  text={"Ara"}
                  disabled={this.state.disabledButton}
                  type="primary"
                  sizeType="fixed"
                  size={"m"}
                  onClick={() => {
                    this.state.filterDb.operationTypes = [];
                    this.getTransactionList(this.state.filterDb);
                  }}
                />
              </Grid>

              <Grid item mt={3.3}>
                <SCUIButton
                  type="secondary"
                  text={"Temizle"}
                  sizeType="fixed"
                  size={"m"}
                  onClick={() =>
                    this.setState(
                      {
                        disabledButton: true,
                        messageText: "",
                        disabledCsvButton: false,
                        disabledExcelButton: false,
                        selectedValue: null,
                        selectedMpOrigin: [],
                        selectedType: {
                          value: [
                            TransactionType.ADVANCE,
                            TransactionType.WHOLESALE,
                          ],
                          label: "Satış",
                        },
                        filterDb: {
                          ...this.state.filterDb,
                          documentSequenceNumber: "",
                          originDocumentNumber: "",
                          documentNumber: "",
                          OriginCodes: [],
                          startDate: null,
                          endDate: null,
                          fiscalStartDate:null,
                          fiscalEndDate:null,
                          transactionType: [
                            TransactionType.ADVANCE,
                            TransactionType.WHOLESALE,
                          ],
                          operationTypes : [OperationType.IsConsignee,OperationType.MpxOperated,OperationType.Franchise]
                        },
                      },
                      () => {
                        this.getTransactionList(this.state.filterDb);
                      }
                    )
                  }
                />
              </Grid>
              <Grid item xs={1} mt={3.3} mr={3}>
                <SCUIButton
                  text={"CSV"}
                  disabled={this.state.disabledCsvButton}
                  type="secondary"
                  sizeType="fixed"
                  size={"m"}
                  onClick={() => this.downloadCsv()}
                />
              </Grid>

              <Grid item xs={1} mt={3.3}>
                <SCUIButton
                  text={"Excel"}
                  disabled={this.state.disabledExcelButton}
                  type="secondary"
                  sizeType="fixed"
                  size={"m"}
                  onClick={() => this.downloadExcel()}
                //  style={{height:"2rem"}}
                />
              </Grid>

              <Grid item xs={1} mt={3.3} ml={5}>
                <button style={messageCss}>{this.state.messageText}</button>
              </Grid>
            </Grid>
          </SCUICollapsiblePaper>

          <Paper sx={{ width: "100%", margin: "1rem", padding: "1rem" }}>
            <div style={{ height: 2550, width: "100%" }}>
              {this.state.loading ? (
                <SCUILoading />
              ) : (
                <DataTable
                  columns={columns}
                  data={this.state.data}
                  highlightOnHover
                  expandableRows
                  customStyles={customStylesTable}
                  paginationPerPage={20}
                  pagination={true}
                  paginationServer
                  paginationTotalRows={this.state.totalCount}
                  expandableRowsComponent={(row) => {
                    if (row.data.wholeSale != null)
                      return <WholeSaleDetail data={row.data} />;

                    switch (row.data.transactionType) {
                      case "Satış":
                        return <SaleDetail data={row.data} />;
                      case "İade":
                        return <ReturnDetail data={row.data} />;
                      default:
                        return null;
                    }
                  }}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  paginationDefaultPage={this.state.currentPage}
                  onChangePage={(page) => this.onChange(page)}
                />
              )}
            </div>
          </Paper>
        </Grid>
        <SCUISnackbar
          messageText={this.state.alertMessageText}
          status="warning"
          show={this.state.openDialog}
          onClose={() => this.setState({ openDialog: false })}
          autoHideDuration={2000}
        />
        {/* <SCUISnackbar
          messageText={"En az bir filtre seçiniz !"}
          status="warning"
          show={this.state.openMessage}
          onClose={() => this.setState({ openMessage: false })}
          autoHideDuration={2000}
        /> */}
      </SCUIContent>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    // handlerMpCountriesList: handlerMpCountriesList(dispatch),
    // handlerMpList: handlerMpList(dispatch),
    handlerSalesMpOriginCodeList:() => handlerSalesMpOriginCodeList(dispatch,""),
  };
};

const mapStateToProps = ({ lookups }) => ({
  mpOrderOriginCodeList: lookups.mpOrderOriginCodeList,
  // mpCountriesList: lookups.mpCountries,
  // mpList: lookups.mpList,
});
// withTranslation()(Orders);
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Orders)
);
