import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DownloadIcon from "@mui/icons-material/Download";
import QrCodeIcon from '@mui/icons-material/QrCode';
import SettingsApplications from "@mui/icons-material/SettingsApplications";

import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 3,
    maxWidth: "200px",
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 10,
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
        >
          <SettingsApplications />
        </IconButton>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          style={{ fontSize: 12 }}
          onClick={props.downloadDespatch}
          disableRipple
        >
          <DownloadIcon style={{ marginRight: 5 }} />
          E-İrsaliye Belgesi İndir
        </MenuItem>
        <MenuItem
          style={{ fontSize: 12 }}
          onClick={props.downloadInvoiceSales}
          disableRipple
        >
          <DownloadIcon style={{ marginRight: 5 }} />
          E-Fatura Belgesi İndir
        </MenuItem>

        {
          props.disableFiscal ? null
            :
            <>
              <MenuItem
                style={{ fontSize: 12 }}
                onClick={props.showFiscal}
                disableRipple={true}
                disabled={props.disableFiscal}
              >
                <QrCodeIcon style={{ marginRight: 5 }} />
                Fiscal Görüntüle
              </MenuItem></>

        }
        
      </StyledMenu>
    </div>
  );
}
