
declare global {
  interface Window { localStorage: any; }
}


const getItemFromLocalStorage = (itemName: string): string | null => {
  
  if(
    window.localStorage.getItem(itemName) !== undefined &&
    window.localStorage.getItem(itemName) !== null) {
    return  JSON.parse(window.localStorage.getItem(itemName));
  }
  return null;
};

const setItemToLocalStorage = (itemName: string, itemValue: any): void => {
  window.localStorage.setItem(itemName, JSON.stringify(itemValue));
};

const removeItemFromLocalStorage = (itemName: string): void => {
  window.localStorage.removeItem(itemName);
};

const clearLocalStorage = (): void => {
  window.localStorage.clear();
};

const LocalStorageWrapper = {
  getItemFromLocalStorage,
  setItemToLocalStorage,
  removeItemFromLocalStorage,
  clearLocalStorage,
};

export default LocalStorageWrapper;
