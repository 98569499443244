import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import CountryTransferDataDashboard from "././CountryTransferData";
import { tr } from "date-fns/locale";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { SCUICollapsiblePaper } from "../../../../atomic/molecules";
import { handlerMpCountriesList, handlerMpList } from "../../../../stores/redux/actions/lookupAction/lookupAction";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SCUIButton } from "../../../../atomic/atoms";
import MerchantQueryService from "../../../../services/query/MerchantQueryService";
import { SCUIContent } from "../../../../atomic/organisms";
import ActivityStatistics from "./ActivityStatistics";
import CountryDataDashboard from "./CountryDataDashboard";
const today = new Date();
class ActivityDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityData: [],
      MpCountryList: [],
      selectedMp: null,
      selectedMpCountry: null,
      filter: {
        marketplace: null,
        country: null,
        startDate: null,
        endDate: null,
        transactionType: null,
      },
    };
  }
  componentDidMount() {
    this.props.handlerMpCountriesList()
    this.props.handlerMpList()
  }



  addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  render() {
    console.log(this.state)
    return (
      <SCUIContent>
        <Grid container direction="row">
          <SCUICollapsiblePaper title="Filtre">
            <Grid container spacing={1}>
              <Grid item xs={1.5} mt={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label={"Başlangıç Tarihi"}
                    inputFormat="dd/MM/yyyy"
                    value={this.state.filter.startDate}
                    onChange={(event) => {
                      event.setHours(0, 0, 0, 0);
                      this.setState({
                        filter: {
                          ...this.state.filter,
                          startDate: event.toLocaleDateString("en-US"),
                          endDate: this.addDays(event, 6).toLocaleDateString(
                            "en-US"
                          ),
                        },
                      });
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={1.5} mt={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label={"Bitiş Tarihi"}
                    minDate={this.state.filter.startDate}
                    maxDate={new Date(this.state.filter.startDate).setDate(
                      new Date(this.state.filter.startDate).getDate() + 6
                    )}
                    // maxDate={this.addDays(this.state.filter.startDate,6)}
                    inputFormat="dd/MM/yyyy"
                    value={this.state.filter.endDate}
                    onChange={(event) => {
                      event.setHours(0, 0, 0, 0);
                      this.setState({
                        filter: {
                          ...this.state.filter,
                          endDate: event.toLocaleDateString("en-US"),
                        },
                      });
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2} mt={3}>
                <FormControl fullWidth>
                  <InputLabel id="filter-type">Tip</InputLabel>
                  <Select
                    labelId="filter-type-select-label"
                    id="filter-type-select"
                    value={this.state.filter?.transactionType}
                    label="Type"
                    onChange={(event) => {
                      this.setState({
                        filter: {
                          ...this.state.filter,
                          transactionType: event.target.value,
                        },
                      });
                    }}
                  >
                    <MenuItem value={143}>Satış</MenuItem>
                    <MenuItem value={40}>İade</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={1.5} mt={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select">marketplace</InputLabel>
                  <Select
                    style={{ height: "55px", width: "200px" }}
                    labelId="value"
                    variant="outlined"
                    id="demo-simple-select"
                    value={this.state.selectedMp}
                    label="Marketplace"
                    onChange={(event) => {
                      this.setState({
                        selectedMp: event.target.value,
                        filter: {
                          ...this.state.filter,
                          marketplace: event.target.value,
                        },
                      });
                    }}
                  >
                    {
                      this.props.mpList.map((e) => (
                        <MenuItem value={e.value}>{e.label}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={1.5} mt={3} ml={2}>
                <FormControl fullWidth>
                  <InputLabel id="MpCountry">mp ülke</InputLabel>
                  <Select
                    style={{ height: "55px", width: "200px" }}
                    labelId="value2"
                    variant="outlined"
                    id="MpCountry"
                    value={this.state.selectedMpCountry}
                    label="mp ülke"
                    onChange={(event) => {
                      this.setState({
                        selectedMpCountry: event.target.value,
                        filter: {
                          ...this.state.filter,
                          country: event.target.value,
                        },
                      });
                    }}
                  >
                    {this.props.mpCountriesList.map((e) => (
                      <MenuItem value={e.value}>{e.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={1.2} ml={3} mt={4}>
                <SCUIButton
                  text={"Temizle"}
                  type="secondary"
                  sizeType="fixed"
                  size={"m"}
                  onClick={() =>
                    this.setState({
                      selectedMp: null,
                      selectedMpCountry: null,
                      filter: {
                        ...this.state.filter,
                        marketplace: null,
                        selectedMpCountry: null,
                        startDate: null,
                        endDate: null,
                        country: null,
                        transactionType: null,
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
          </SCUICollapsiblePaper>

          <ActivityStatistics filter={this.state.filter} />

          <CountryDataDashboard
            filter={this.state.filter}
            style={{ height: "800px", width: "900px" }}
          />

          {/* <Grid item>
            <Box>
              <Card variant="outlined">
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  ></Typography>
                  <CountryTransferDataDashboard filter={this.state.filter} />
                </CardContent>
              </Card>
            </Box>
          </Grid> */}
        </Grid>
      </SCUIContent>
    );
  }
}



const mapDispatchToProps = (dispatch) => {
  return ({
    handlerMpCountriesList: handlerMpCountriesList(dispatch),
    handlerMpList: handlerMpList(dispatch),
  });
};

const mapStateToProps = ({ lookups }) => ({
  mpCountriesList: lookups.mpCountries,
  mpList: lookups.mpList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDashboard);


