import React, { FC, useEffect, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
//--
import { enumSnackbarStatusType } from '../../../types/enums';
//--
import "./SCUIButtonFileUpload.scss";
import { SCUIButton } from "../";
import { SCUISnackbar } from "../../molecules";

interface IProps {
  stateName?: string;
  onChange?: (fileInfo: any, stateName: string) => void;
  onError?: (errorInfo: any) => void;
  selectedFiles?: any[] | null;
  error?: any;
}

export const SCUIButtonFileUpload: FC<IProps> = ({
  onChange,
  onError,
  stateName = "fileUpload",
  selectedFiles = null,
  error = false,
}) => {
  const [fileNames, setFileNames] = useState<any>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    if (selectedFiles !== null && fileNames !== selectedFiles) {
      let fileInfo =
        typeof selectedFiles !== "string"
          ? JSON.parse(JSON.stringify(selectedFiles))
          : ["İlgili dosya eklidir"];
      let result = [...fileInfo];
      setFileNames([...result]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  useEffect(() => {
    if (error && onError) {
      onError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onDrop = (acceptedFiles: any) => {
    let fileInfo = acceptedFiles.map((file: any) => file.name);
    setFileNames(fileInfo);
    sendPropsChange(acceptedFiles);
  };

  const onClickDeleteSelectedFile = (fileName: string) => {
    let resultList: any = fileNames.filter((item: string) => item !== fileName);
    setFileNames(resultList);
    sendPropsChange(resultList);
  };

  const sendPropsChange = (value: any) => {
    if (onChange) {
      onChange(value, stateName);
    }
  };

  const onCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const getMessageAndClass = (isAccept: boolean, isReject: boolean): string => {
    if (isReject && !showSnackbar) {
      setShowSnackbar(true);
    }
    return isAccept ? "accept" : isReject ? "reject" : "";
  };

  const getFile = (file: any): string => {
    if (typeof file === "string") {
      return file;
    }
    return file?.path;
  };

  // accept={"image/*, *.pdf, *.doc,*.xlsx"}
  return (
    <div className="sc-btn-file-upload dropzone">
      {fileNames.length === 0 ? (
        <>
          <Dropzone onDrop={onDrop} minSize={1024} maxSize={50000000} accept={".xlsx"}>
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragAccept,
              isDragReject,
            }) => {
              const additionalClass = getMessageAndClass(
                isDragAccept,
                isDragReject,
              );
              return (
                <div
                  {...getRootProps({
                    className: `sc-btn-file-upload__area dropzone ${additionalClass}`,
                  })}
                >
                  <p className="txt-message">Dosyaları bu alana sürükleyiniz</p>
                  {/* <p className="txt-message">veya</p> */}
                  <input {...getInputProps()} />
                  <SCUIButton
                    text="Dosya seçiniz"
                    type="ghost"
                    sizeType="fixed"
                    size="m"
                    onClick={() => console.log("")}
                  />
                </div>
              );
            }}
          </Dropzone>
          {Boolean(error) && (
            <p className="txt-error Mui-error">{error?.message}</p>
          )}
        </>
      ) : (
        <div className="selected-file">
          {fileNames.map((file: any) => (
            <div className="selected-file__info">
              <p className="txt-selected-file">{getFile(file)}</p>
              <IconButton
                aria-label="delete"
                onClick={() => onClickDeleteSelectedFile(file)}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </div>
          ))}
        </div>
      )}

      <SCUISnackbar
        messageText={"Lütfen resim formatında dosya seçiniz.."}
        status={enumSnackbarStatusType.danger}
        show={showSnackbar}
        onClose={onCloseSnackbar}
        autoHideDuration={2000}
      />
    </div>
  );
};
/**
 * 
 *   {({ getRootProps, getInputProps }) => (
          <div className="sp-btn-file-upload__area" {...getRootProps()}>
            <p className="txt-message">Dosyaları bu alana sürükleyiniz</p>
            <p className="txt-message">veya</p>
            <input {...getInputProps()} />
            <SCUIButton
              text="Dosya seçiniz"
              type="secondary"
              sizeType="fixed"
              size="m"
              onClick={() => console.log("a")}
            />
          </div>
        )}
 */
