import React, { FC } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
//
import "./SCUIMenus.scss";

/**
 * const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

 */
interface IProps {
  anchorEl: any;
  id: string;
  onClose?: () => void;
  onClickMenu: (url: string) => void;
  list: any[];
}

export const SCUIMenus: FC<IProps> = ({
  anchorEl,
  onClose,
  onClickMenu,
  id = "1-1-1",
  list,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      className="sc-menus"
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      {list.map((item: any, index: number) => (
        <MenuItem onClick={() => onClickMenu(item.url)} key={index.toString()}>
          {item.icon !== null && (
            <ListItemIcon className="sc-menus__icon">
              <item.icon fontSize="small" className="icon" />
            </ListItemIcon>
          )}
          <ListItemText primary={item.text} className="txt-list-item" />
        </MenuItem>
      ))}
    </Menu>
  );
};
/**
 * 

    <StyledMenu
      id={id}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      className="sc-menus"
    >
      {list.map((item: any) => (
        <StyledMenuItem
          onClick={() => {
             onClickMenu(item.name);
          }}
        >
          {item.icon !== null && (
            <ListItemIcon className="sc-menus__icon">
              <item.icon fontSize="small" className="icon" />
            </ListItemIcon>
          )}
          <ListItemText primary={item.text} className="txt-list-item" />
        </StyledMenuItem>
      ))}
    </StyledMenu>
  
 * 
 */
