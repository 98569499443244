import React, { FC } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
//--
import { SCUIButton } from "../../atoms";
import {
  HuedIconSuccessMd,
  HuedIconInfoMd,
} from "../../../assets/icons/svg/huedIcons/index";
//--
import "./SCUIAlertDialog.scss";
interface IProps {
  open: boolean;
  status?: string;
  iconShow?: boolean;
  title: string;
  content: any;
  onClose: () => void;
  onClickAgreed: () => void;
  onClickNotAgreed: () => void;
  showBtnCancel?: boolean;
  showBtnConfirm?: boolean;
  btnConfirmText?: string;
  btnCancelText?: string;
}

export const SCUIAlertDialog: FC<IProps> = ({
  open = false,
  iconShow = false,
  status = "none",
  title = "",
  content,
  onClickAgreed,
  onClickNotAgreed,
  onClose,
  showBtnCancel = true,
  showBtnConfirm = true,
  btnConfirmText = "Evet",
  btnCancelText = "Hayır",
}) => {
  const onClickApprove = () => {
    onClose();
    onClickAgreed();
  };

  const onClickReject = () => {
    onClose();
    onClickNotAgreed();
  };

  const getIcon = () => {
    switch (status) {
      case "success":
        return HuedIconSuccessMd;
      case "info":
        return HuedIconInfoMd;
      default:
        break;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="sc-alert-dialog"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {iconShow && (
          <div className="sc-alert-dialog__icon-area">
            <img
              src={getIcon()}
              alt="success"
              className="sc-alert-dialog__icon-area__icon"
            />
          </div>
        )}

        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        className={!showBtnConfirm || !showBtnCancel ? "one-btn" : "two-btn"}
      >
        {showBtnCancel && (
          <SCUIButton
            text={btnCancelText}
            type="secondary"
            sizeType="fixed"
            size="m"
            onClick={onClickReject}
          />
        )}

        {showBtnConfirm && (
          <SCUIButton
            text={btnConfirmText}
            type="primary"
            sizeType="fixed"
            size="m"
            onClick={onClickApprove}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};
