import React, { FC, useState, useEffect } from "react";
import { SCUIContent } from "../../../atomic/organisms";
import "./EditUser.scss";
import { SCUIButton } from "../../../atomic/atoms";
import { Link } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { PageUrl } from "../../../types/enums";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  SCUIOperatorDialog,
  SCUICollapsiblePaper,
} from "../../../atomic/molecules";
import { SCUIOperatorMainRow } from "../../../atomic/molecules";
import { Paper, Grid, TextField } from "@mui/material";
import MerchantQueryService from "../../../services/query/MerchantQueryService";
import { useLocation } from "react-router-dom";

export const EditUser: FC<any> = (props: any) => {
  const [display, setDisplay] = useState(false);
  const [refreshOperatorListById, setRefreshOperatorListById] = useState(false);
  const [searchedOperatorName, setSearchedOperatorName] = React.useState("");
  const [openEditModalOpr, setOpenEditModalOpr] = React.useState(false);
  const onModalEditCloseOpr = () => setOpenEditModalOpr(false);
  const onModalEditOpenOpr = () => {
    setOpenEditModalOpr(true);
  };
  const [operatorList, setOperatorList] = useState<any>();
  const [searchedOperatorList, setSearchedOperatorList] = useState<any>();
  const query = new URLSearchParams(useLocation().search);
  const idfromUrl = query.get("RoleId");
  const id = props.location.state?.roleId
    ? props.location.state?.roleId
    : idfromUrl;
  const roleName = props.location.state?.roleName;
  const refreshOperatorList = () => {
    setRefreshOperatorListById(true);
  };
  const [operators, setOperators] = React.useState([]);
  const getOperatorListByRoleId = (id: any) => {
    MerchantQueryService.GetOperatorListByRoleId(id)
      .then((res: any) => {
        if (res) {
          var parsedData = res.model.data;
          setOperatorList(parsedData);
          setSearchedOperatorList(parsedData);
         parsedData.length>0 ? setDisplay(true):setDisplay(false);
        }
        setRefreshOperatorListById(false);
      })
      .catch((error: any) => {
        console.log("Hata :>> ", error);
      });
  };
  const GetOperatorList = async () => {
    try {
      let operator: any = await MerchantQueryService.GetOperatorService();      
      setOperators(operator);
    } catch (error) {
      console.log("Hata :>> ", error);
    }
  };
  useEffect(() => {
    GetOperatorList()},[]);
  useEffect(() => {
    getOperatorListByRoleId(id);
  }, [props.history?.location?.state?.roleId, id, refreshOperatorListById]);

  const requestSearchOpr = (searchedVal: any) => {
    setSearchedOperatorName(searchedVal.target.value);
    let filteredOperator = searchedOperatorList.filter(
      (o: any) =>
        o.username
          .toLowerCase()
          .indexOf(searchedVal.target.value.toLowerCase()) !== -1
    );
    setOperatorList(filteredOperator);
  };


 
  return (
    <SCUIContent>
      <SCUIOperatorDialog
        roleId={id}
        open={openEditModalOpr}
        onClose={onModalEditCloseOpr}
        refreshOperatorList={refreshOperatorList}
        operators={operators}
      />

      <Grid container justifyContent="flex-end">
        <Grid item lg={12} md={12} xs={12}>
          <h1 className="headtxt"> {roleName} Operatör Düzenleme </h1>
        </Grid>
        <Grid item lg={2} md={6} xs={6} >
        <Link to={PageUrl.authorizationRuleManagement.url} className="linktxt">
          <ArrowCircleLeftIcon />
          {"Rol Listesine Dön"}
        </Link>
      </Grid>
        <Grid item lg={2} md={6} xs={6}>
          <SCUIButton
            text={"Kullanıcı Ekle"}
            type="primary"
            sizeType="fixed"
            size={"m"}
            onClick={() => onModalEditOpenOpr()}
          />
        </Grid>
        <SCUICollapsiblePaper>
          <TextField
            value={searchedOperatorName}
            onChange={(searchVal) => requestSearchOpr(searchVal)}
            fullWidth
            placeholder="Kullanıcı adı"
          />
        </SCUICollapsiblePaper>{" "}
        {display ?
        <Paper sx={{ padding: "2rem", margin: "2rem", width: "100%" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow key={"id"}>
                  <TableCell align="center">Kullanıcı Adı Soyadı</TableCell>
                  <TableCell align="center">Domain Bilgisi</TableCell>
                  <TableCell align="center">Operatörü Kaldır</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
            {operatorList && operatorList?.map((row: any) => (
                  <SCUIOperatorMainRow  row={row} roleId={id} refreshOperatorList={refreshOperatorList}/>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper> :
          <Paper sx={{ padding: "2rem", margin: "2rem", width: "100%" }}>
           <div>Role ait operatör mevcut değil</div>
        </Paper>}
      </Grid>
    </SCUIContent>
  );
};
