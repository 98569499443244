import React, { FC, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Avatar, Box, Card, CardContent } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from "react-router-dom";

import Divider from "@mui/material/Divider";
import "./SCUIHomeDashboardCard.scss";

interface IProps {
  headText: string;
  context: string;
  componentName?: string;
  pastReviewList?: Array<{ date: string; desc: string }>;
}

export const SCUIHomeDashboardCard: FC<IProps> = ({
  headText = "",
  context = "",
  componentName = " ",
}) => {
  return (
    <Card  >
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
        <Grid item>
            <Avatar>
               {headText.charAt(0)}
          
            </Avatar>
          </Grid>
          <Grid item>
            <Typography color="textSecondary"   variant="h6">
              {headText}
            </Typography>
            <Typography color="textPrimary" variant="h4">
              {context}
            </Typography>
          </Grid>
         
        </Grid>
        <Divider />
        <Box
          sx={{
            pt: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Link to={componentName} className="sign-in-link">
            {headText}
         <ArrowForwardIcon/>
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
};
