import Dexie from "dexie";

const db:any = new Dexie('queryResults');

const createDb = () => {
    db.version(1).stores({ requests: "++id, url, response, insert_date", images: "++id, url, base64_url, insert_date" });
    db.open().catch(function (err:any) {
        console.error(err.stack || err);
    });
}

const getItemFromDb = async (url:string) => {
    const items = await db.requests
        .where('url').equals(url)
        .toArray();
    return items;
}

const getImageFromDb = async (url:string) => {
    const items = await db.images
        .where('url').equals(url)
        .toArray();
    return items;
}

const addItemToDb = async (url:string, response:any) => {
    await db.requests.add({
        url,
        response,
        insert_date: Date.now()
    }).catch( (e:any) => {
        if ((e.name === 'QuotaExceededError') ||
            (e.inner && e.inner.name === 'QuotaExceededError'))
        {
          // QuotaExceededError may occur as the inner error of an AbortError
          console.error ("QuotaExceeded error!");
        } else {
          // Any other error
          console.error (e);
        }
    });
}

const addImageToDb = async (url:string, base64_url:string) => {
    await db.images.add({
        url,
        base64_url,
        insert_date: Date.now()
    }).catch((e:any) => {
        if ((e.name === 'QuotaExceededError') ||
            (e.inner && e.inner.name === 'QuotaExceededError'))
        {
          // QuotaExceededError may occur as the inner error of an AbortError
          console.error ("QuotaExceeded error!");
        } else {
          // Any other error
          console.error (e);
        }
    });
}

const deleteItemFromDb = async (id:number) => {
    await db.requests.where("id").equals(id).delete();
}

const deleteDb = () => {
    db.delete();
}

const IndexedDbWrapper = {
    createDb,
    getItemFromDb,
    getImageFromDb,
    addItemToDb,
    addImageToDb,
    deleteItemFromDb,
    deleteDb
}

export default IndexedDbWrapper;