import React from "react";
import {Paper,Collapse,Grid} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
 import "./SCUICollapsiblePaper.scss";

 
export const SCUICollapsiblePaper= (props:any)=> {
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
   return (
    <Grid container direction="row">
    <Grid item lg={12} md={12} xs={12}>
    <h1 className="headtxt">{props.title} </h1>
  </Grid>

  <Paper  sx={{display:"inline-block", padding: "2rem", margin: "2rem", width: "100%" }}>
    <div onClick={handleClick}>
       Filtrele
      {open ? (
        <ExpandLess className="svg_icons" sx={{ float: "right" }} />
      ) : (
        <ExpandMore className="svg_icons" sx={{ float: "right" }} />
      )}
    </div>
    <br />
    <Collapse in={open} timeout="auto" unmountOnExit>
{props.children}
    </Collapse>
        </Paper>
  </Grid>
  );
};
