import React from "react";
import ReactDOM from "react-dom";
import { createTheme, ThemeProvider, Theme, StyledEngineProvider, createMuiTheme } from "@mui/material/styles";
import { trTR } from "@mui/material/locale";
import './i18n';
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/styles/main.scss";



declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}



declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme =createTheme({
  palette: {
    primary: { main: "#1976d2" },
  },
},trTR); 
    
ReactDOM.render(
  <>
{/*     <React.StrictMode>
 */}
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
