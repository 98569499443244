import React, { FC, useState } from "react";
import { SCUIContent } from "../../atomic/organisms";
import Swal from "sweetalert2";
import { SCUICollapsiblePaper } from "../../atomic/molecules";
import { Paper, Grid, TextField, Typography } from "@mui/material";
import { SCUIButton } from "../../atomic/atoms";
import { DataGrid } from "@mui/x-data-grid";
import MerchantQueryService from "../../services/query/MerchantQueryService";
import TimeHelper from "../../utils/time";
import { Box,LinearProgress,Fade} from "@mui/material";

import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
export const StoreLcwaikiki: FC<any> = (props: any) => {
  const Swal = require("sweetalert2");
  const [siparisNo, setSiparisNo] = React.useState("");
  const [storeData, setStoreData] = React.useState<any>();
  const [billData, setBillData] = React.useState<any>();
  const [orderNumber, setOrderNumber] = React.useState("");
  const [orderDate, setOrderDate] = useState("");
  const [display, setDisplay] = useState(false);
  const [barDisplay, setBarDisplay] = useState(false);

  const columns = [
    {
      field: "image",
      headerName: "image",
      width:140,
      renderCell: (params: any) => (
        <img
          src={params.value}
          alt={params.urunBilgi}
          className="storeLcwaikikiImage"
        />
      ),
    },
    {
      field: "urunBilgi",
      minWidth: 400,
      flex: 1,
      headerName: "Ürün Bilgileri",
      renderCell: (params: any) => (
        <div>
          <Typography>{params.value.productDescription}</Typography>
          <Typography color="textSecondary">
            {params.value.optionName} - {params.value.brandName}
          </Typography>
          <Typography color="textSecondary">
            Ürün Kodu: {params.value.productCode} - {params.value.colorCode}
          </Typography>
          <Typography color="textSecondary">
            Renk: {params.value.color} , Beden: {params.value.size}
          </Typography>
        </div>
      ),
    },
    {
      field: "adet",
      flex: 1,
      headerName: "Quantity",
    },
    {
      field: "fiyat",
      flex: 1,
      headerName: "Fiyat",
    },
    {
      field: "tutar",
      flex: 1,
      headerName: "Tutar",
    },
  ];

  const fatura = (orderId: string, shippingListId: string) => {
    let params = { orderId: orderId, shippingListId: shippingListId };
    MerchantQueryService.GetStoreInvoiceFile(params);
  };

  const siparisAra = async (): Promise<any> => {
    try {
      if (!/^\d+$/.test(siparisNo)) {
        Swal.fire("geçerli sipariş no giriniz", " ", "error");
        return;
      }
      setBarDisplay(true);


      MerchantQueryService.GetStoreSaleOrderByOrderNo(siparisNo).then(
        (res: any) => {
          if (res.data) {
            setOrderNumber(res.data.orderNumber);
            setOrderDate(TimeHelper.formatDate(res.data.date));
            let parseData = res.data.items.map((item: any) => {
              return {
                id: item.productId || "-",
                image: item.productImageUrl || "-",
                urunBilgi: {
                  productDescription: item.productDescription || "-",
                  optionName: item.optionName || "-",
                  brandName: item.brandName || "-",
                  productCode: item.productCode || "-",
                  colorCode: item.colorCode || "-",
                  color: item.color || "-",
                  size: item.size || "-",
                },
                adet: item.quantity || "-",
                fiyat: Number(item.unitPrice).toFixed(2) || "-",
                tutar: Number(item.netItemDiscountedPriceTotal).toFixed(2) || "-",
              };
            });
            let bills = res.data.shippings.map((bill: any) => {
              return {
                orderId: bill.orderId || "-",
                shippingListNo: bill.shippingListNo || "-",
                shippingListId: bill.shippingListId || "-",
              };
            });
            setBillData(bills);
            setStoreData(parseData);
            setDisplay(true);
            setBarDisplay(false);
          } else
          { Swal.fire("bulunamadı", " ", "error");
          setBarDisplay(false);
        }
        }
      );
    } catch (error) {
      setDisplay(false);
      setBarDisplay(false);
      console.log("Hata :>> ", error);
    }
  };

  return (
    <SCUIContent>
      <Grid container direction="row">      
        <SCUICollapsiblePaper title="Store Lcwaikiki">
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} lg={8} xl={8}>
              <TextField
                label="Sipariş No"
                value={siparisNo}
                onChange={(event) => {
                  setDisplay(false);
                  setSiparisNo(event.target.value);
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3} sm={3} lg={2} xl={2}>
              <SCUIButton
                text={"Ara"}
                type="primary"
                sizeType="fixed"
                size={"m"}
                onClick={siparisAra}
              />
            </Grid>
          </Grid>
        </SCUICollapsiblePaper>
        <Box sx={{ width:"100%" }}>
         <Fade in={barDisplay} unmountOnExit>
          <LinearProgress/>
        </Fade>     
    </Box>
        {display && (
          <Paper sx={{ padding: "2rem", margin: "2rem", width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={4} sm={4} lg={4} xl={4}>
                Sipariş No: {orderNumber}
              </Grid>

              <Grid item xs={4} sm={4} lg={4} xl={4}>
                Sipariş Tarihi: {orderDate}
              </Grid>
              <Grid item xs={4} sm={4} lg={4} xl={4}>
                {billData
                  ? billData.map((bill: any, index: number) => (
                      <SCUIButton
                      key={bill.orderId}
                        text={bill.shippingListNo + " nolu fatura"}
                        type="ghost"
                        sizeType="default"
                        size="s"
                        showStartIcon
                        startIcon={<AssignmentReturnedIcon />}
                        onClick={() =>
                          fatura(bill.orderId, bill.shippingListId)
                        }
                      ></SCUIButton>
                    ))
                  : null}
              </Grid>
            </Grid>

            <DataGrid
              rowHeight={180}
              autoHeight={true}
              columns={columns}
              rows={storeData}
              hideFooter={true}
            />
            <br />
          </Paper>
        )}
      </Grid>
    </SCUIContent>
  );
};
