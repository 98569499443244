import React, { useEffect, FC } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import { SCUIHomeDashboardCard } from "../../../atomic/molecules";
import { SCUIContent } from "../../../atomic/organisms";
import { PageUrl } from "../../../types/enums";
import StyleVariables from "../../../assets/styles/variables/_export.module.scss";
import "./Dashboard.scss";
 
export const Dashboard: FC<any> = (props: any) => {
  
  const isTablet = useMediaQuery(`(min-width:  ${StyleVariables.breakpointSm});
  max-width:  ${StyleVariables.breakpointMd})
  `);
  const isDesktop = useMediaQuery(
    `(min-width:  ${StyleVariables.breakpointMd})`
  );
  const [commentCount, setCommentCount] = React.useState("");

  useEffect(() => {
    const hash = props?.history?.location?.hash;
    const el = hash && document.getElementById(hash.substr(1));
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.history?.location?.hash]);


  return (
    <div className="home">     
        <SCUIContent headerName={" "}>
          <Grid container spacing={3}>

            <Grid item xl={3} lg={3} sm={6} xs={12} className="testYayin">
              <SCUIHomeDashboardCard headText={"Menu Yönetimi"} context={"- "} componentName={PageUrl.menuManagement.url} />        
            </Grid>

             <Grid item xl={3} lg={3} sm={6} xs={12} className="testYayin">
              <SCUIHomeDashboardCard headText={"Yetki Yönetimi"} context={"- "} componentName={PageUrl.authorizationRuleManagement.url} />        
            </Grid>

          </Grid>
        </SCUIContent>
       
    </div>
  );
};
