import React, { FC } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import PersonIcon from "@mui/icons-material/Person";
import { useHistory } from "react-router-dom";
import { PageUrl } from "../../../types/enums";

import "./SCUIRolesMainRow.scss";

interface IProps {
  row: any;
  open?: boolean;
  onClose?: any;
  onEditOperator?: any;
}
export const SCUIRolesMainRow: FC<IProps> = ({
  row,
  open = false,
  onClose,
  onEditOperator,
}) => {
  let history = useHistory();
  const onClick = (e: any) => {
    const roleID = e.currentTarget.getAttribute("data-roleid");
    const roleName = e.currentTarget.getAttribute("data-rolename");
    history.push(PageUrl.editUser.url + "/?RoleId=" + roleID, {
      roleId: roleID,
      roleName: roleName,
    });
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        key={row.rolesItemId}
      >
        <TableCell align="center">{row.roleNameKey}</TableCell>
        <TableCell align="center">
          <Button
            onClick={onClick}
            data-roleid={row.roleId}
            data-rolename={row.roleNameKey}
          >
            <PersonIcon />
          </Button>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
