import React, { FC, ReactNode, useRef, HTMLAttributes } from "react";
import { Button } from "@mui/material";
//--
import "./SCUIButton.scss";
interface IProps {
  onClick?: () => void;
  type: "primary" | "secondary" | "ghost";
  buttonType?: string;
  sizeType: "icon" | "default" | "fixed";
  size: "s" | "m" | "l";
  children?: ReactNode;
  className?: string;
  text: string;
  disabled?: boolean;
  textStatusIsLeft?: boolean;
  showStartIcon?: boolean;
  showEndIcon?: boolean;
  startIcon?: any;
  endIcon?: any;
}

export const SCUIButton: FC<IProps> = ({
  onClick,
  type = "primary",
  buttonType = "submit",
  sizeType = "default",
  size = "m",
  children,
  className = "",
  text,
  disabled,
  textStatusIsLeft = false,
  showStartIcon = false,
  showEndIcon = false,
  startIcon,
  endIcon,
}) => {
  const refInputFile = useRef<any>();

  const isChildNull = (children: ReactNode) => {
    return Boolean(children === null);
  };

  const onClickBtn = (e: any) => {
    const inputElement = refInputFile.current;
    if (onClick) {
      inputElement.click();
      onClick();
    }
  };

  const _renderFileButton = () => {
    return (
      <div className="sc-file-btn">
        <input
          accept="image/*"
          className={"hidden"}
          id="contained-button-file"
          multiple
          type="file"
          ref={refInputFile}
        />
        <label htmlFor="contained-button-file">
          <Button
            className={`btn sc-btn  sc-btn-${type} sc-btn-${sizeType}  sc-btn-${sizeType}--${size} ${className}`}
            onClick={onClickBtn}
            disabled={disabled}
          >
            {textStatusIsLeft && <p className="sc-btn__text">{text}</p>}
            {!isChildNull(children) && (
              <div className="sc-btn__content">{children}</div>
            )}
            {!textStatusIsLeft && <p className="sc-btn__text">{text}</p>}
          </Button>
        </label>
      </div>
    );
  };

  const _renderDefaultButton = () => {
    return (
      <Button
        className={`btn sc-btn  sc-btn-${type} sc-btn-${sizeType}  sc-btn-${sizeType}--${size} ${className}`}
        onClick={onClick}
        disabled={disabled}
        type="submit"
        startIcon={showStartIcon ? startIcon : null}
        endIcon={showEndIcon ? endIcon : null}
        variant={
          type === "primary"
            ? "contained"
            : type === "secondary"
            ? "outlined"
            : "text"
        }
      >
        {textStatusIsLeft && <p className="sc-btn__text">{text}</p>}
        {!isChildNull(children) && (
          <div className="sc-btn__content">{children}</div>
        )}
        {!textStatusIsLeft && <p className="sc-btn__text">{text}</p>}
      </Button>
    );
  };

  return buttonType === "file" ? _renderFileButton() : _renderDefaultButton();
};
