import React, { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ErrorIcon from "@mui/icons-material/Error";
//--
import "./SCUIToast.scss";

enum enumToastType {
  success = "success",
  warning = "warning",
  danger = "danger",
  info = "info",
}
//#endregion
interface IProps {
  type:   "success" |"warning" |"danger" |"info" | "error" | any;
  text: string;
  showCloseButton?: boolean;
  className?: string;
}

export const SCUIToast: FC<IProps> = ({
  type =enumToastType.success,
  text,
  showCloseButton = false,
  className = "",
}) => {
  const getIcon = () => {
    switch (type) {
      case enumToastType.success:
        return <CheckCircleIcon className="icon" />;
      case enumToastType.info:
        return <InfoIcon className="icon" />;
      case enumToastType.danger:
        return <ErrorIcon className="icon" />;
      case enumToastType.warning:
        return <InfoIcon className="icon" />;

      default:
        return <CheckCircleIcon className="icon" />;
    }
  };

  return (
    <div className={`sc-toast ${type} ${className}`}>
      <div className="sc-toast__left">
        <div className="icon-area">{getIcon()}</div>
      </div>
      <div className="sc-toast__content">
        <p className="txt-message">{text}</p>
      </div>
      <div className="sc-toast__right">
        {showCloseButton && (
          <IconButton className="sc-toast__btn-close" size="large">
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};
