import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { SCUIButton, SCUILoading } from "../../atomic/atoms";
import { SCUIContent } from "../../atomic/organisms";
import MerchantQueryService from "../../services/query/MerchantQueryService";
import TimeHelper from "../../utils/time";
import customStylesTable from "../Orders/Const";
import "./ProductList.scss";
import CustomizedMenus from "./menuComponent";
import MerchantCommandService from "../../services/command/MerchantCommandService";
import {
  SCUICollapsiblePaper,
  SCUISnackbar,
  SCUIAlertDialog,
} from "../../atomic/molecules";
import Helper from "../../utils/helper";
import jwtDecode from "jwt-decode";
import AuthService from "../../services/auth/Auth.services";
import { connect } from "react-redux";
import { handlerMpOriginCodeList } from "../../stores/redux/actions/lookupAction/lookupAction";
var autService = new AuthService();

class ProductDataList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledButton: true,
      data: [],
      selectedOriginCode: null,
      UserName: null,
      validationMessage: null,
      status: null,
      openMessage: false,
      totalCount: null,
      loading: true,
      currentPage: 1,
      showLeftButton1: false,
      filterDb: {
        page: 1,
        originCode: null,
      },
    };
  }

  componentDidMount() {
    this.getProductList(this.state.filterDb);

    autService.getUserInfo().then((getUserInfo) => {
      if (!Helper.isNil(getUserInfo)) {
        const decodedToken = jwtDecode(getUserInfo.access_token);
        this.setState({ UserName: decodedToken.preferred_username });
      }
    });
    this.props.handlerMpOriginCodeList();
  }

  onChange(e) {
    this.setState(
      {
        filterDb: {
          ...this.state.filterDb,
          page: e,
        },
      },
      () => {
        this.getProductList(this.state.filterDb);
      }
    );
  }

  sendMarketPlace(e) {
    this.setState({ loading: true });
    MerchantCommandService.SendFileToMarketPlace({
      fileId: e.fileId,
      CreatedBy: this.state.UserName,
      OriginCode: e.originCode,
    })
      .then((res) => {
        this.setState(
          {
            openMessage: true,
            status: "success",
            validationMessage: `${e.fileName} işlem başlatıldı`,
          },
          () => {
            this.getProductList(this.state.filterDb);
          }
        );
      })
      .catch((res) => {
        this.setState(
          {
            openMessage: true,
            status: "danger",
          },
          () => {
            this.getProductList(this.state.filterDb);
          }
        );
      });
  }

  productStatusFileDownload(row) {
    console.log("rowww", row);
    this.setState({
      openDialog: true,
      showErrorTable: true,
    });

    MerchantQueryService.DownloadProductFileExport({
      FileId: row.fileId,
    })
      .then((res) => {
        res.blob().then((res) => {
          this.setState({ loading: false });
        });
      })
      .catch((res) => {
        this.setState({
          openDialog: true,
        });
      })
      .finally(this.setState({ loading: false }));
  }

  downloadProductExcel(e) {
    this.setState({ loading: true });
    MerchantQueryService.DownloadProductExcel({
      fileId: e.fileId,
      fileName: e.fileName,
    })
      .then((res) => {
        res.blob().then((res) => {
          this.setState({ loading: false });
        });
      })
      .finally(this.setState({ loading: false }));
  }

  getProductList(filterDb) {
    this.setState({ loading: true });
    MerchantQueryService.GetProductList(filterDb).then((res) => {
      if (res?.files) {
        this.setState({
          data: res.files,
          loading: false,
          currentPage: filterDb.page,
          totalCount: res.totalCount,
        });
      } else if (res.statusCode != 200 || res == undefined) {
        this.setState({
          loading: false,
          openMessage: true,
          status: "danger",
          validationMessage: `İşlem sırasında hata oluştu. Hata Detayları : ${res.data.Message}`,
        });
      }
    });
  }

  validationErrorFileDownload(row) {
    console.log("validationErrorFileDownload started!", row);
    this.setState({
      openDialog: true,
      showErrorTable: true,
    });

    MerchantQueryService.DownloadValidationErrorExcel({
      FileId: row.fileId,
      FileName : row.fileName
    })
      .then((res) => {
        res.blob().then((res) => {
          this.setState({ loading: false });
        });
      })
      .catch((res) => {
        this.setState({
          openDialog: true,
        });
      })
      .finally(this.setState({ loading: false }));
  }

  render() {
    const columns = [
      { omit: true, selector: (row) => row.id, name: "Id", grow: 0 },
      { selector: (row) => row.originCode, name: "Origin Code", grow: 0 },
      { selector: (row) => row.fileId, name: "Dosya ID", grow: 2 },
      { selector: (row) => row.fileName, name: "Dosya Adı", grow: 2 },
      { selector: (row) => row.userName.import, name: "Yükleyen Kullanıcı" },
      { selector: (row) => row.userName.sent, name: "Gönderen Kullanıcı" },
      { selector: (row) => row.status, name: "Durum" },
      {
        selector: (row) => TimeHelper.momentDate(row.importDate),
        name: "Yüklenme Tarihi",
      },
      {
        selector: (row) =>
          row.sentDate != null ? TimeHelper.momentDate(row.sentDate) : "-",
        name: "Gönderme Tarihi",
      },
      {
        selector: (row) => row.status,
        name: "Aksiyon",
        cell: (row) => {
          return (
            <CustomizedMenus
              rowData={row}
              onClick={(e) => this.sendMarketPlace(row)}
              productStatusFileDownload={(e) =>
                this.productStatusFileDownload(row)
              }
              onClickDownload={(e) => {
                this.downloadProductExcel(row);
              }}
              validationErrorFileDownload={(e) =>
                this.validationErrorFileDownload(row)
              }
            />
          );
        },
      },
    ];

    return (
      <SCUIContent>
        <div style={{ height: "calc(100vh - 120px)", width: "100%" }}>
          <SCUICollapsiblePaper title="">
            <Grid container spacing={1}>
              <Grid item xs={2} mt={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select">Marketplace</InputLabel>
                  <Select
                    style={{ height: "55px", width: "200px" }}
                    labelId="value"
                    variant="outlined"
                    id="demo-simple-select"
                    value={this.state.selectedOriginCode}
                    label="OriginCode"
                    onChange={(event) => {
                      this.setState({
                        selectedOriginCode: event.target.value,
                        disabledButton: false,
                        filterDb: {
                          ...this.state.filterDb,
                          originCode: event.target.value,
                        },
                      });
                    }}
                  >
                    {this.props.mpOrderOriginCodeList.map((e) => (
                      <MenuItem value={e.value}>{e.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} mt={3.3} mr={5}>
                <SCUIButton
                  text={"Ara"}
                  disabled={this.state.disabledButton}
                  type="primary"
                  sizeType="fixed"
                  size={"m"}
                  onClick={() => this.getProductList(this.state.filterDb)}
                />
              </Grid>
              <Grid item xs={1.5} mt={3.3}>
                <SCUIButton
                  text={"Temizle"}
                  type="secondary"
                  sizeType="fixed"
                  size={"m"}
                  onClick={() =>
                    this.setState(
                      {
                        disabledButton: true,
                        selectedOriginCode: null,
                        filterDb: {
                          ...this.state.filterDb,
                          originCode: null,
                        },
                      },
                      () => {
                        this.getProductList(this.state.filterDb);
                      }
                    )
                  }
                />
              </Grid>
            </Grid>
          </SCUICollapsiblePaper>
          <Grid container direction="column">
            {this.state.loading ? (
              <SCUILoading />
            ) : (
              <DataTable
                columns={columns}
                data={this.state.data}
                highlightOnHover
                customStyles={customStylesTable}
                paginationPerPage={50}
                pagination={true}
                paginationServer
                paginationTotalRows={this.state.totalCount}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                paginationDefaultPage={this.state.currentPage}
                onChangePage={(page) => this.onChange(page)}
              />
            )}
          </Grid>
        </div>
        <SCUISnackbar
          messageText={this.state.validationMessage}
          status={this.state.status}
          show={this.state.openMessage}
          onClose={() => this.setState({ openMessage: false })}
          autoHideDuration={3000}
        />

        <SCUIAlertDialog
          open={this.state.openDialog}
          status="success"
          iconShow={true}
          showBtnCancel={false}
          showBtnConfirm={true}
          btnConfirmText={"Tamam"}
          onClose={() => this.setState({ openDialog: false })}
          onClickAgreed={this.onClickRedirect}
          content={"Indiriliyor"}
        />
      </SCUIContent>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    handlerMpOriginCodeList:()=> handlerMpOriginCodeList(dispatch,false),
  };
};

const mapStateToProps = ({ lookups }) => ({
  mpOrderOriginCodeList: lookups.mpOrderOriginCodeList,
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductDataList);
