import {enumCellType} from '../../../types/enums';
import { enumCommentStatusFlowStatus, enumStarScoreFlowStatus } from '../../../types/enums/status';
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import StarIcon from '@mui/icons-material/Star';

export const initialState = {
  commentList: [],
  commentListEnd: [],
  selectedRow: {},   
  loading: false,
  paginationInfo: {
    rowsPerPage: 5,
    pageCount: 0,
    totalCount: 0,
    currentPage: 0,
  },filterInfo: {
    optionId: "",
    createDate: "",
    endDate: "",
    specialCode: "",
    colorCode: "",
    starScore:null,
    commentStatus:[{id: 1}], 
    tagName:[],
    operator:"",
  },

};
export const starScoreLookupList = [
  {
    id: enumStarScoreFlowStatus.minusOne,
    value: "Tümü",
  },
  {
    id: enumStarScoreFlowStatus.one,
    value: "1 Yıldız",
  },
  {
    id: enumStarScoreFlowStatus.two,
    value: "2 Yıldız",
  },
  {
    id: enumStarScoreFlowStatus.three,
    value: "3 Yıldız",
  },
  {
    id: enumStarScoreFlowStatus.four,
    value: "4 Yıldız",
  },
  {
    id: enumStarScoreFlowStatus.five,
    value: "5 Yıldız",
  },
];
export const commentStatusLookupList = [
  {
    id: enumCommentStatusFlowStatus.minusOne,
    value: "Tümü",
  },
  {
    id: enumCommentStatusFlowStatus.one,
    value: "Onay Bekliyor",
  },
  {
    id: enumCommentStatusFlowStatus.two,
    value: "Onaylandı",
  },
  {
    id: enumCommentStatusFlowStatus.three,
    value: "Reddedildi",
  },
];
export const paramsName = { 
  id:"id",
 // optionId: "optionId", 
  mail: "mail",
  special: "special",  
  colorCode: "colorCode",
  commentTitle: "commentTitle",
  commentStatus:"commentStatus",
  status: "status",
  name: "name",
  createDate: "createDate", 
  tag: "tag",
  banaAta: "banaAta",
  detay:"detay",
  operator:"operator",

};

export const tableHeadList = [
  {
    id: paramsName.id,
    numeric: false,
    disablePadding: true,
    label: "Yorum Id",
    cellType: enumCellType.string,
  },
  /* {
    id: paramsName.optionId,
    numeric: false,
    disablePadding: true,
    label: "optionId",
    cellType: enumCellType.string,
  }, */
  {
    id: paramsName.mail,
    numeric: false,
    disablePadding: true,
    label: "E-mail",
    cellType: enumCellType.string,
  },
  {
    id: paramsName.special,
    numeric: false,
    disablePadding: true,
    label: "Özel Kod",
    cellType: enumCellType.string,

  },
  {
    id: paramsName.colorCode,
    numeric: false,
    disablePadding: true,
    label: "Renk Kodu",     
    cellType: enumCellType.string,
  },
 /*  {
    id: paramsName.commentTitle,
    numeric: false,
    disablePadding: true,
    label: "Şikayet Konusu",
     
    cellType: enumCellType.string,
  }, */
  {
    id: paramsName.commentStatus,
    numeric: false,
    disablePadding: true,
    label: "Yorum Statü",
    cellType: enumCellType.status,
  },
   {
    id: paramsName.status,
    numeric: false,
   disablePadding: true,
   label: "Puan",
   icon: StarIcon,
   cellType: enumCellType.icons,

 },
  {
    id: paramsName.createDate,
    numeric: false,
    disablePadding: true,
    label: "Yorum Tarihi",  
    cellType: enumCellType.string,
  },
  {
    id: paramsName.operator,
    numeric: false,
    disablePadding: true,
    label: "Atanan Kişi",  
    cellType: enumCellType.string,
  },
  {
    id: paramsName.tag,
    numeric: false,
    disablePadding: true,
    label: "Etiket",
    cellType: enumCellType.string,
  },
  
 {
   id: paramsName.detay,
   numeric: false,
  disablePadding: true,
  label: "Detay",
  icon: AssignmentIcon,
  cellType: enumCellType.button,
  className: "detailButton",
},
{
  id: paramsName.banaAta,
  numeric: false,
 disablePadding: true,
 label: "Bana Ata",
 icon: AssignmentIndIcon,
 cellType: enumCellType.button,
 className: "detailButton",
},
  ];
  export type IRow = {
    
    name: string;
    createDate: string;
    status: IRowCellStatus;
    description: string;
  };
  
  export type IRowCellUrl = {
    url: string | null;
    text: string;
  };
  
  export type IRowCellStatus = {
    className: string;
    id: number;
    text: string;
  };
  
