import React, { FC } from "react";
import { SCUIContent } from "../../../atomic/organisms";
import "./NotFound.scss";
export const NotFound: FC<any> = (props: any) => {
  return (
    <SCUIContent>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          <h2>Sayfa bulunamadı</h2>
          <p>
            Aradığınız sayfa kaldırılmış, adı değiştirilmiş ya da geçici olarak
            kullanım dışı olabilir
          </p>
        </div>
      </div>
    </SCUIContent>
  );
};
