import React, { FC } from "react";
import LinearProgress from "@mui/material/LinearProgress";

//--
import "./SCUIProgressbar.scss";

interface IProps {
  text: string;
  percentage: number;
}
export const SCUIProgressbar: FC<IProps> = ({ text = "", percentage = 0 }) => {
  /*
              <progress  className="sp-progress-bar__graphic" value={percentage} max="100"/>
  */

  return (
    <div className="sc-progress-bar">
      <p className="sc-progress-bar__txt-info">{text}</p>
      <div className="sc-progress-bar__linear-graphic">
        <p className="txt-percentage">%{percentage}</p>
        <div className="line-progress">
          <LinearProgress variant="determinate" value={percentage} />
        </div>
      </div>
    </div>
  );
};
