//#region packages
import axios from "axios";
import qs from "qs";
//#endregion

//#region local imports
import { ApiBaseURL } from "../../types/constants/api-urls";
import IndexedDbWrapper from "./browserStorage/indexedDb-wrapper";
//#endregion

const getToken = function () {
  return localStorage.getItem("access_token");
};

const fullUrl = (options: any) => {
  let paramString = qs.stringify(options.params, {
    arrayFormat: "repeat",
    skipNulls: true,
  });
  if (paramString !== "") {
    paramString = "?" + paramString;
  }

  let url = ApiBaseURL + options.url + paramString;
  // console.info(url);
  return url;
};

const client = axios.create({
  baseURL: ApiBaseURL,
  paramsSerializer: (params) =>
    qs.stringify(params, { arrayFormat: "repeat", skipNulls: true }),
});

// NOTE cors hatası alınıyor bazen. önerilenlerde bu vardı. api tarafında da sorun olabilir. bi araştırılması gerekiliyor...
// client.defaults.withCredentials = true;
// client.defaults.crossDomain = true;

client.interceptors.request.use(
  (config) => {
    // Do something before request is sent

    config.headers["Authorization"] = "Bearer " + getToken();
    config.headers["Content-Type"] = "application/json";
    config.headers["x-api-context"] =
      '{regionid:1,applicationid:1,culture:"tr-TR",countryid:1}';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const request = function (options: any) {
  // console.info(options)
  const onSuccess = function (response: any) {
    // console.debug("Request Successful!", response);
    if (options.responseType === "blob") {
      // window.open(URL.createObjectURL(response.data));
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers["content-disposition"];
      const fileNameMatch = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
        contentDisposition
      );
      const fileName = fileNameMatch && fileNameMatch[1];
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
      link.removeChild(link);
    }
    if (options.useCache) {
      IndexedDbWrapper.addItemToDb(fullUrl(options), response.data);
    }
    return response.data;
  };

  const onCacheSuccess = function (res: any) {
    if (res.length > 0) {
      //cache time tanımlanmadı ise varsayılan 2 saat
      if (
        res[0].insert_date +
          (options.cacheTime ? options.cacheTime : 2) * 60 * 60 * 1000 <
        Date.now()
      ) {
        IndexedDbWrapper.deleteItemFromDb(res[0].id);
      } else {
        return res[0].response;
      }
    }

    return client(options).then(onSuccess).catch(onError);
  };

  const onError = function (error: any) {
    console.error("Request Failed:", error.config);

    if (error.response) {
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
    } else {
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  if (options.useCache) {
    return IndexedDbWrapper.getItemFromDb(fullUrl(options)).then(
      onCacheSuccess
    );
  } else {
    return client(options).then(onSuccess).catch(onError);
  }
};

const requestCsv = function (options: any) {
  // console.info(options)
  const onSuccess = function (response: any) {
    // console.debug("Request Successful!", response);
    if (options.responseType === "blob") {
      // window.open(URL.createObjectURL(response.data));
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const contentDisposition = response.headers["content-disposition"];
      const fileNameMatch = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
        contentDisposition
      );
      const fileName = fileNameMatch && fileNameMatch[1];
      link.setAttribute("download", `${fileName}`);
      document.body.appendChild(link);
      link.click();
      link.removeChild(link);
    }
    if (options.useCache) {
      IndexedDbWrapper.addItemToDb(fullUrl(options), response.data);
    }
    return response.data;
  };

  const onCacheSuccess = function (res: any) {
    if (res.length > 0) {
      //cache time tanımlanmadı ise varsayılan 2 saat
      if (
        res[0].insert_date +
          (options.cacheTime ? options.cacheTime : 2) * 60 * 60 * 1000 <
        Date.now()
      ) {
        IndexedDbWrapper.deleteItemFromDb(res[0].id);
      } else {
        return res[0].response;
      }
    }

    return client(options).then(onSuccess).catch(onError);
  };

  const onError = function (error: any) {
    console.error("Request Failed:", error.config);

    if (error.response) {
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
    } else {
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  if (options.useCache) {
    return IndexedDbWrapper.getItemFromDb(fullUrl(options)).then(
      onCacheSuccess
    );
  } else {
    return client(options).then(onSuccess).catch(onError);
  }
};

export { request, requestCsv };

export default request;
