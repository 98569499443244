import MerchantQueryService from "../../../../services/query/MerchantQueryService";
import { ActionType as Type } from "../../types";

export const handlerMpCountriesList = (dispatch) => () => {
    return new Promise((resolve, reject) => {
        MerchantQueryService.GetMpCountryList({}).then((res) => {
            var mpTemp = [];
            if (res) {
                res.country.forEach((x) => {
                    var obj = { value: x.value, label: x.key };
                    mpTemp.push(obj);
                })
                dispatch({
                    type: Type.HANDLER_MP_COUNTRIES,
                    mpCountriesList: mpTemp,
                });
                resolve();
            }
        });


    });
};

export const handlerMpList = (dispatch) => () => {
    return new Promise((resolve, reject) => {
        MerchantQueryService.GetMpList({}).then((res) => {
            var mpTemp = [];
            if (res) {
                res.marketplace.forEach((x) => {
                    var obj = { value: x.value, label: x.key };
                    mpTemp.push(obj);
                })
                dispatch({
                    type: Type.HANDLER_MP_LIST,
                    mpList: mpTemp,
                });
                resolve();
            }
        });
    });
};

export const handlerMpIntegratorList = (dispatch) => () => {
    return new Promise((resolve, reject) => {
        MerchantQueryService.GetMpIntegratorList({}).then((res) => {
            var mpTemp = [];
            if (res) {
                res.integrator.forEach((x) => {
                    var obj = { value: x.value, label: x.key };
                    mpTemp.push(obj);
                })
                dispatch({
                    type: Type.HANDLER_MP_INTEGRATORS,
                    mpIntegratorList: mpTemp,
                });
                resolve();
            }
        });
    });
};

export const handlerMpOrderStatusList = (dispatch) => () => {
    return new Promise((resolve, reject) => {
        MerchantQueryService.GetMpOrderStatusList({}).then((res) => {
            var statusTemp = [];
            if (res) {
                res.orderStatus.forEach((x) => {
                    var obj = { value: x.value, label: x.key };
                    statusTemp.push(obj);
                })
                dispatch({
                    type: Type.HANDLER_MP_ORDERSTATUS,
                    mpOrderStatusList: statusTemp,
                });
                resolve();
            }
        });
    });
};
export const handlerMpOriginCodeList = (dispatch, isConsignee) => {
    return MerchantQueryService.GetMpOriginsFromIntegration({ IsConsignee: isConsignee })
      .then((res) => {
        var orgTemp = [];
        if (res) {
          res.forEach((x) => {
            var obj = { value: x.originCode, label: x.name };
            orgTemp.push(obj);
          });
          console.log(orgTemp)
          dispatch({
            type: Type.HANDLER_MP_ORIGINCODE,
            mpOrderOriginCodeList: orgTemp,
          });
        }
      });
  };

  export const handlerSalesMpOriginCodeList = (dispatch, operationType) => {
    return MerchantQueryService.GetMpOriginsFromSales({ OperationType: operationType })
      .then((res) => {
        var orgTemp = [];
        if (res) {
          res.forEach((x) => {
            var obj = { value: x.originCode, label: x.name };
            orgTemp.push(obj);
          });
          console.log(orgTemp)
          dispatch({
            type: Type.HANDLER_MP_ORIGINCODE,
            mpOrderOriginCodeList: orgTemp,
          });
        }
      });
  };