import React, { FC} from "react";
 import {SCUIContent } from "../../../atomic/organisms";
 import {SCUIProductCommentDetailForm } from "../../../atomic/molecules";
 import {useLocation} from "react-router-dom";

   export const CommentDetail: FC<any> = (props: any) => {
    const query = new URLSearchParams(useLocation().search);
    const idfromUrl = query.get("CommentId");
    const id=(props.history.location.state?.id)?props.history.location.state?.id:idfromUrl;
    const filterInfo=props.history.location.state?.filterInfo;
    
    
   return (
     <SCUIContent>
      <SCUIProductCommentDetailForm   
       filterInfo={filterInfo}
          id={id}
          history={props.history} />  
      </SCUIContent>
 
  );
};



