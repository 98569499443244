import AddIcon from "@mui/icons-material/Add";
import ListIcon from "@mui/icons-material/List";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
//--
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { IconLightBulb, IconStore, IconHelp, IconPhoneSM } from "../../../assets/icons";
import { PageUrl } from "../../../types/enums";

//#region İnterface
export interface ISCUIHeader {
  history: any;
}
//#endregionp
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
export interface JwtPayload {
  name: string
}

//#region Url List
export const myMenuList = [
/*   { url: PageUrl.myInformation.url, text: "Bilgilerim", icon: null },
  { url: PageUrl.myDemands.url, text: "Taleplerim", icon: null }, */
  /* { name: PageUrl.users.name, text: "Kullanıcılar", icon: null }, */
  {
    url: PageUrl.signOut.url,
    text: "Çıkış yap",
    icon: ExitToAppIcon,
  },
];
 
//#endregion


export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
