enum enumRevisedEvaluationSelect {
  needRevision = "0",
  proper = "1",
}
const evaluationRadioGroupList = [
  { id: enumRevisedEvaluationSelect.needRevision, name: "Revize Gerekiyor" },
  { id: enumRevisedEvaluationSelect.proper, name: "Uygun" },
];


export {
  enumRevisedEvaluationSelect,
  evaluationRadioGroupList
}