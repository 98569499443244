import { Grid, Paper, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { SCUIButton, SCUICheckbox, SCUILoading } from "../../../atomic/atoms";
import { SCUICollapsiblePaper } from "../../../atomic/molecules";
import { SCUIContent } from "../../../atomic/organisms";
import MerchantQueryService from "../../../services/query/MerchantQueryService";
import TimeHelper from "../../../utils/time";
import customStylesTable from "../Const";
import { tr } from "date-fns/locale";
import OrdersAndCancelledDetail from "./OrdersAndCancelledDetail";
import { connect } from "react-redux";
import {
  handlerMpOrderStatusList,
  handlerMpOriginCodeList,
} from "../../../stores/redux/actions/lookupAction/lookupAction";
import "../Orders/OrderAndCancelled.scss";

class OrdersAndCancelled extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledButton: true,
      disabledCsvButton: false,
      disabledExcelButton: false,
      messageText: "",
      data: [],
      totalAmountFilteredOrders: "",
      totalOrderItemFilteredOrders: "",
      totalCount: null,
      loading: true,
      currentPage: 1,
      selectedType: [],
      selectedMpOrigin: [],
      showDeepTotal: false,
      filterDb: {
        isConsignee: false,
        // OrderId: null,
        marketplaceOrderId: null,
        OrderNumber: null,
        OrderStatus: [],
        OriginCode: [],
        startDate: null,
        endDate: null,
        page: 1,
        SourceId: null,
        GroupId: null,
      },
    };
  }

  componentDidMount() {
    this.getOrderList(this.state.filterDb);
    this.props.handlerMpOrderStatusList();
    this.props.handlerMpOriginCodeList(this.state.filterDb.isConsignee);
  }

  onChange(e) {
    this.setState(
      {
        filterDb: {
          ...this.state.filterDb,
          page: e,
        },
      },
      () => {
        this.getOrderList(this.state.filterDb);
      }
    );
  }

  isNullOrEmpty(value) {
    if (value == null || value == "") return false;
    return true;
  }

  IshowDeepTotal(filter) {
    if (
      this.isNullOrEmpty(filter.OrderNumber) ||
      this.isNullOrEmpty(filter.OriginCode) ||
      this.isNullOrEmpty(filter.SourceId) ||
      this.isNullOrEmpty(filter.GroupId)
    )
      return true;
    else return false;
  }
  downloadOrderExcel() {
    this.setState({
      messageText: "Dosya indirme işleminiz devam ediyor...",
      disabledExcelButton: true,
      disabledCsvButton: true,
    });
    MerchantQueryService.DownloadOrderExcel(this.state.filterDb)
      .then((response) => {
        this.setState({
          messageText: "",
          disabledExcelButton: false,
          disabledCsvButton: false,
        });
      })
      .catch((res) => {
        this.setState({
          messageText: "",
          disabledExcelButton: false,
          disabledCsvButton: false,
        });
      });
  }
  downloadOrderCsv() {
    this.setState({
      messageText: "Dosya indirme işleminiz devam ediyor...",
      disabledExcelButton: true,
      disabledCsvButton: true,
    });

    MerchantQueryService.DownloadOrderCsv(this.state.filterDb)
      .then((response) => {
        this.setState({
          messageText: "",
          disabledExcelButton: false,
          disabledCsvButton: false,
        });
      })
      .catch((res) => {
        this.setState({
          messageText: "",
          disabledExcelButton: false,
          disabledCsvButton: false,
        });
      });
  }

  getOrderList(filterDb) {
    this.setState({ loading: true });
    MerchantQueryService.GetOrderList(filterDb).then((res) => {
      if (res.data) {
        res.data.forEach((x) => {
          x.orderDate = TimeHelper.momentDate(x.orderDate);
        });
      }
      this.setState({
        data: res.data ?? [],
        showDeepTotal: this.IshowDeepTotal(filterDb),
        loading: false,
        currentPage: filterDb.page,
        totalCount: res.totalCount,
        totalAmountFilteredOrders: res.totalAmountFilteredOrders,
        totalOrderItemFilteredOrders: res.totalOrderItemFilteredOrders,
      });
    });
  }

  render() {
    const customStyles = {
      zIndex: "inherit",
      control: (base) => ({
        ...base,
        height: 55,
        minHeight: 35,
        zIndex: 9999,
        flex: 10,
      }),
      menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
      }),
      container: (provided) => ({
        ...provided,
        width: "190px",
        height: "50px", // Ana container'ın genişliğini belirleyebilirsiniz
      }),
    };

    const messageCss = {
      color: "black",
      backgroundColor: "transparent",
      padding: "0px",
      fontFamily: "Arial",
      border: "0px",
      overflow: "hidden",
      width: "400px",
      height: "50px",
    };

    const columns = [
      { omit: true, selector: (row) => row.id, name: "Id", grow: 0 },
      {
        selector: (row) =>
          row.marketplace.charAt(0).toUpperCase() +
          row.marketplace.slice(1).toLowerCase(),
        name: "MP",
        grow: 0,
      },
      // {
      //   selector: (row) => row.mpOrigin?.country,
      //   name: "MP Ülke",
      //   width: "12rem",
      // },
      {
        selector: (row) => row.shippingInfo.country,
        name: "Sipariş Ülkesi",
        grow: 1,
      },
      { selector: (row) => row.orderDate, name: "Sipariş Tarihi", grow: 3 },
      { selector: (row) => row.orderStatus, name: "Durum", grow: 1 },
      { selector: (row) => row.orderNumber, name: "Sipariş No", grow: 3 },
      {
        selector: (row) => row.fulfillments[0]?.shippings[0]?.shippingListNo,
        name: "Sevkiyat No",
        grow: 3,
      },
      {
        selector: (row) => row.marketplaceOrderId,
        name: "MP Sipariş No",
        grow: 3,
      },
      { selector: (row) => row.sourceId, name: "Butik ID", grow: 2 },
      {
        selector: (row) =>
          row.fulfillments[0]?.shippings[0]?.shippingTrackingId ?? "",
        name: "Group ID",
      },
      {
        selector: (row) => row.orderItemsQuantity,
        name: "Ürün Adet",
        cell: (row) =>
          row.orderItems.reduce(
            (accumulator, item) => accumulator + item.quantity,
            0
          ),
      },
      { selector: (row) => row.totalPrice, name: "Toplam Tutar" },
      { selector: (row) => row.currency, name: "Para Birimi", grow: 2 },
      {
        selector: (row) =>
          row.shippingInfo.firstName
            ? row.shippingInfo.firstName + " " + row.shippingInfo.lastName
            : "-",
        name: "Müşteri Ad Soyad",
        grow: 2,
      },
    ];

    return (
      <SCUIContent>
        <Grid container direction="row">
          <SCUICollapsiblePaper>
            <Grid container spacing={1}>
              <Grid item xs={3} mt={1} className="box">
                <div className="smallText">Consignee Satışlar</div>
                <SCUICheckbox
                  checked={this.state.filterDb.isConsignee}
                  onChange={(e) => {
                    this.setState(
                      {
                        filterDb: {
                          ...this.state.filterDb,
                          isConsignee: e.target.checked,
                          page: 1,
                        },

                      },
                      () => {
                        this.getOrderList(this.state.filterDb)
                        this.props.handlerMpOriginCodeList(this.state.filterDb.isConsignee);
                      }
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={2.4} mt={1} className="box">
                <Select
                  styles={customStyles}
                  placeholder={"Marketplace"}
                  isMulti
                  value={this.state.selectedMpOrigin}
                  menuPortalTarget={document.body}
                  onChange={(e) => {
                    var temp = [];
                    e.forEach((x) => {
                      temp.push(x.value);
                    });
                    this.setState(
                      {
                        disabledButton: false,
                        selectedMpOrigin: e,
                        filterDb: {
                          ...this.state.filterDb,
                          OriginCode: temp,
                          page: 1,
                        },
                      },
                      () => { }
                    );
                  }}
                  options={this.props.mpOrderOriginCodeList}
                />
              </Grid>

              <Grid item xs={2.4} mt={1}>
                <Select
                  styles={customStyles}
                  placeholder={"Sipariş Durumu"}
                  menuPortalTarget={document.body}
                  isMulti
                  value={this.state.selectedType}
                  onChange={(e) => {
                    var temp = [];
                    e.forEach((x) => {
                      temp.push(x.value);
                    });
                    this.setState({
                      disabledButton: false,
                      selectedType: e,
                      filterDb: {
                        ...this.state.filterDb,
                        OrderStatus: temp,
                        page: 1,
                      },
                    });
                  }}
                  options={this.props.mpOrderStatusList}
                />
              </Grid>

              <Grid item xs={2.4} mt={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: "190px" }} />
                    )}
                    label={"Sipariş Başlangıç"}
                    inputFormat="dd/MM/yyyy"
                    value={this.state.filterDb.startDate}
                    onChange={(event) => {
                      event.setHours(0, 0, 0, 0);
                      this.setState({
                        disabledButton: false,
                        filterDb: {
                          ...this.state.filterDb,
                          startDate: event.toLocaleDateString("en-US"),
                          page: 1,
                        },
                      });
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2.4} mt={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                  <DatePicker
                    renderInput={(props) => (
                      <TextField {...props} style={{ width: "190px" }} />
                    )}
                    label={"Sipariş Bitiş"}
                    inputFormat="dd/MM/yyyy"
                    value={this.state.filterDb.endDate}
                    onChange={(event) => {
                      event.setHours(0, 0, 0, 0);
                      this.setState({
                        disabledButton: false,
                        filterDb: {
                          ...this.state.filterDb,
                          endDate: event.toLocaleDateString("en-US"),
                          page: 1,
                        },
                      });
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              {/* <Grid item xs={2.4} mt={1}>
                <TextField
                  label={"Sipariş ID"}
                  type="text"
                  style={{ width: "190px" }}
                  value={this.state.filterDb.OrderId}
                  onChange={(event) => {
                    this.setState({
                      disabledButton: false,
                      filterDb: {
                        ...this.state.filterDb,
                        OrderId: event.target.value,
                        page: 1,
                      },
                    });
                  }}
                />
              </Grid> */}

              <Grid item xs={2.4} mt={1}>
                <TextField
                  label={"Sipariş No"}
                  type="text"
                  style={{ width: "190px" }}
                  value={this.state.filterDb.OrderNumber}
                  onChange={(event) => {
                    this.setState({
                      disabledButton: false,
                      filterDb: {
                        ...this.state.filterDb,
                        OrderNumber: event.target.value,
                        page: 1,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={2.4} mt={1}>
                <TextField
                  label={"Marketplace Sipariş No"}
                  type="text"
                  style={{ width: "190px" }}
                  value={this.state.filterDb.marketplaceOrderId}
                  onChange={(event) => {
                    this.setState({
                      disabledButton: false,
                      filterDb: {
                        ...this.state.filterDb,
                        marketplaceOrderId: event.target.value,
                        page: 1,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={2.4} mt={1}>
                <TextField
                  label={"Butik ID"}
                  type="text"
                  style={{ width: "150px" }}
                  value={this.state.filterDb.SourceId}
                  onChange={(event) => {
                    this.setState({
                      disabledButton: false,
                      filterDb: {
                        ...this.state.filterDb,
                        SourceId: event.target.value,
                        page: 1,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={2.4} mt={1}>
                <TextField
                  label={"Group Id"}
                  type="text"
                  style={{ width: "150px" }}
                  value={this.state.filterDb.GroupId}
                  onChange={(event) => {
                    this.setState({
                      disabledButton: false,
                      filterDb: {
                        ...this.state.filterDb,
                        GroupId: event.target.value,
                        page: 1,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={1} mt={1}>
                <SCUIButton
                  text={"Ara"}
                  disabled={this.state.disabledButton}
                  type="primary"
                  sizeType="fixed"
                  size={"m"}
                  onClick={() => this.getOrderList(this.state.filterDb)}
                />
              </Grid>

              <Grid item mt={1} ml={3}>
                <SCUIButton
                  text={"Temizle"}
                  type="secondary"
                  sizeType="fixed"
                  size={"m"}
                  className="custom-width"
                  onClick={() =>
                    this.setState(
                      {
                        disabledButton: true,
                        messageText: "",
                        disabledCsvButton: false,
                        disabledExcelButton: false,
                        selectedType: [],
                        selectedMpOrigin: [],
                        filterDb: {
                          ...this.state.filterDb,
                          // OrderId: "",
                          marketplaceOrderId: "",
                          isConsignee: false,
                          OrderNumber: "",
                          OriginCode: [],
                          OrderStatus: [],
                          startDate: null,
                          endDate: null,
                          page: 1,
                          SourceId: "",
                          GroupId: "",
                        },
                      },
                      () => {
                        this.getOrderList(this.state.filterDb);
                        this.props.handlerMpOriginCodeList(this.state.filterDb.isConsignee)
                      }
                    )
                  }
                />
              </Grid>
              <Grid item xs={1} mt={1} mr={3}>
                <SCUIButton
                  text="CSV"
                  type="secondary"
                  disabled={this.state.disabledCsvButton}
                  sizeType="fixed"
                  size={"m"}
                  onClick={() => this.downloadOrderCsv()}
                />
              </Grid>

              <Grid item xs={1} mt={1}>
                <SCUIButton
                  text="Excel"
                  type="secondary"
                  disabled={this.state.disabledExcelButton}
                  sizeType="fixed"
                  size={"m"}
                  onClick={() => this.downloadOrderExcel()}
                //  style={{height:"2rem"}}
                />
              </Grid>

              <Grid item xs={1} mt={3.3} ml={5}>
                <button style={messageCss}>{this.state.messageText}</button>
              </Grid>
            </Grid>
          </SCUICollapsiblePaper>

          <Paper sx={{ width: "100%", margin: "1rem", padding: "1rem" }}>
            <div style={{ height: 2500, width: "100%" }}>
              {this.state.loading ? (
                <SCUILoading />
              ) : (
                <>
                  <DataTable
                    columns={columns}
                    data={this.state.data}
                    highlightOnHover
                    expandableRows
                    customStyles={customStylesTable}
                    paginationPerPage={50}
                    pagination={true}
                    paginationServer
                    paginationTotalRows={this.state.totalCount}
                    expandableRowsComponent={OrdersAndCancelledDetail}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    paginationDefaultPage={this.state.currentPage}
                    onChangePage={(page) => this.onChange(page)}
                  />

                  {this.state.showDeepTotal ? (
                    <>
                      <div
                        className="footer"
                        style={{
                          textAlign: "right",
                          marginRight: "250px",
                          fontSize: "1rem",
                          color: "#000",
                        }}
                      >
                        Toplam Tutar:{this.state.totalAmountFilteredOrders}
                      </div>
                      <div
                        className="footer"
                        style={{
                          textAlign: "right",
                          marginRight: "273px",
                          fontSize: "1rem",
                          color: "#000",
                        }}
                      >
                        Toplam Ürün:{this.state.totalOrderItemFilteredOrders}
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </div>
          </Paper>
        </Grid>
      </SCUIContent>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handlerMpOrderStatusList: handlerMpOrderStatusList(dispatch),
    handlerMpOriginCodeList: (isConsignee) => {
      handlerMpOriginCodeList(dispatch, isConsignee);
    },
  };
};

const mapStateToProps = ({ lookups }) => ({
  mpOrderStatusList: lookups.mpOrderStatusList,
  mpOrderOriginCodeList: lookups.mpOrderOriginCodeList,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersAndCancelled);
