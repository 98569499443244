import React, { FC, useState, useEffect } from "react";
import { SCUIContent } from "../../../atomic/organisms";
import "./AuthorizationRuleManagement.scss";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {
  SCUIRolesMainRow,
  SCUICollapsiblePaper,
} from "../../../atomic/molecules";

import { Paper, Grid, TextField } from "@mui/material";
import MerchantQueryService from "../../../services/query/MerchantQueryService";

interface IProps {
  row: any;
  onClose?: any;
  onEditOperator?: any;
}

export const AuthorizationRuleManagement: FC<IProps> = ({
  row,
  onClose,
  onEditOperator,
}) => {
  const [searchedRolesName, setSearchedRolesName] = React.useState("");
  const [rolesList, setRolesList] = useState<any>();
  const [searchedRolesList, setSearchedRolesList] = useState<any>();
  const getRolesList = () => {
    MerchantQueryService.GetRolesList()
      .then((res: any) => {
        if (res) {
          var parsedData = res.model.data;
          setRolesList(parsedData);
          setSearchedRolesList(parsedData);
        }
      })
      .catch((error: any) => {
        console.log("Hata :>> ", error);
      });
  };
  useEffect(() => {
    getRolesList();
  }, []);

  const requestSearchRole = (searchedVal: any) => {
    setSearchedRolesName(searchedVal.target.value);
    let filteredRoles = searchedRolesList.filter(
      (o: any) =>
        o.roleNameKey
          .toLowerCase()
          .indexOf(searchedVal.target.value.toLowerCase()) !== -1
    );
    setRolesList(filteredRoles);
  };

  return (
    <SCUIContent>
      <Grid container direction="row">
        <Grid item lg={10} md={12} xs={12}>
          <h1 className="headtxt">Rol Düzenleme </h1>
        </Grid>
        <SCUICollapsiblePaper>
          <TextField
            value={searchedRolesName}
            onChange={(searchVal) => requestSearchRole(searchVal)}
            fullWidth
            placeholder="Rol adı"
          />
        </SCUICollapsiblePaper>{" "}
        <Paper sx={{ padding: "2rem", margin: "2rem", width: "100%" }}>
          <TableContainer component={Paper}>
            <Table aria-label="operators">
              <TableHead>
                <TableRow key={"operatortable"}>
                  <TableCell align="center">Rol Adı</TableCell>
                  <TableCell align="center">Kullanıcı Düzenle</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {rolesList?.map((row: any) => (
                  <SCUIRolesMainRow key={row.roleId} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </SCUIContent>
  );
};
