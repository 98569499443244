import React, { FC  } from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import "./SCUIMenuRoleDialog.scss";
import { SCUIMenuRoleDialogContent } from "../SCUIMenuRoleDialogContent/SCUIMenuRoleDialogContent";

interface IProps {
  selectedRow?: any;
  open?: boolean;
  onClose?: any;
  roles?:any;
}
export const SCUIMenuRoleDialog: FC<IProps> = ({
  selectedRow = null,
  open = false,
  onClose,
  roles,
}) => { 
   
  return (
    <Dialog open={open} onClose={onClose}>
    <DialogTitle> {selectedRow && selectedRow.text}</DialogTitle>
    <SCUIMenuRoleDialogContent selectedRow={selectedRow}   onCloseDialog={onClose}  roles={roles}/>
    </Dialog>
  );
};
