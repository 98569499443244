import React, { FC } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
//--
import "./SCUILoading.scss";

export const SCUILoading: FC<any> = () => {
  return (
    <Box className="sc-loading">
      <CircularProgress className="icon-loading" />
    </Box>
  );
};
