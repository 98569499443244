import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import {
  Home,
  Dashboard,
  NotFound,
  Login,
  Logout,
  CommentList,
  CommentDetail,
  CommentTicket,
  CacheManagement,
  MenuManagement,
  AuthorizationRuleManagement,
  EditUser,
  EditRoles,
  StoreLcwaikiki,
  // PaymentLogs,
} from "../../pages";

import { PageUrl } from "../../types/enums/pageUrl-enums";
import { AuthorizedRoute } from "../../atomic/shared";
import { SCUIPage } from "../../atomic/templates";
import SaleDashboard from "../../pages/Orders/SalesAndReturns/Dashboard/SaleDashboard";
import TransactionActivity from "../../pages/Orders/Logs/TransactionActivity";
import ActivityDashboard from "../../pages/Orders/Logs/Dashboard/ActivityDashboard";
import Orders from "../../pages/Orders/SalesAndReturns/Orders";
import OrdersDashboard from "../../pages/Orders/Orders/Dashboard/OrdersDashboard";
import OrdersAndCancelled from "../../pages/Orders/Orders/OrdersAndCancelled";
import ProductImport from "../../pages/Product/Import/ProductImport";
import ProductDataList from "../../pages/Product/ProductDataList";
import ProductSentLogs from "../../pages/Product/logs/ProductSentLogs";
import SalesImport from "../../pages/SalesTransfer/SalesImport/SalesImport";



export default function MainRouter() {
  return (
    <div>
      <Router>
        <div>
          <SCUIPage showFooter={false} />

          <Switch>
            <Redirect exact from="/" to={PageUrl.home.url} />
            <AuthorizedRoute path={PageUrl.dashboard.url} Component={Dashboard} />
            <AuthorizedRoute path={PageUrl.home.url} Component={Home} />
            <AuthorizedRoute path={PageUrl.commentDetail.url} Component={CommentDetail} />
            <AuthorizedRoute path={PageUrl.commentTicket.url} Component={CommentTicket} />
            <AuthorizedRoute path={PageUrl.cacheManagement.url} Component={CacheManagement} />
            <AuthorizedRoute path={PageUrl.commentList.url} Component={CommentList} />
            <AuthorizedRoute path={PageUrl.menuManagement.url} Component={MenuManagement} />
            <AuthorizedRoute path={PageUrl.authorizationRuleManagement.url} Component={AuthorizationRuleManagement} />
            <AuthorizedRoute path={PageUrl.editUser.url} Component={EditUser} />
            <AuthorizedRoute path={PageUrl.editRoles.url} Component={EditRoles} />
            <AuthorizedRoute path={PageUrl.storeLcWaikiki.url} Component={StoreLcwaikiki} />
            <Route path="/signin-oidc" component={Login} exact />

            <AuthorizedRoute  path={PageUrl.transactionSales.url} Component={Orders} />
            <AuthorizedRoute  path={PageUrl.SaleDashboard.url} Component={SaleDashboard} />
            <AuthorizedRoute  path={PageUrl.ActivityDashboard.url} Component={ActivityDashboard} />
            <AuthorizedRoute  path={PageUrl.OrdersDashboard.url} Component={OrdersDashboard} />
            <AuthorizedRoute  path={PageUrl.TransactionLogs.url} Component={TransactionActivity} />
            <AuthorizedRoute  path={PageUrl.OrdersAndCancelled.url} Component={OrdersAndCancelled} />
            <AuthorizedRoute  path={PageUrl.ProductImport.url} Component={ProductImport} />
            <AuthorizedRoute  path={PageUrl.ProductDataList.url} Component={ProductDataList} />
            <AuthorizedRoute  path={PageUrl.ProductSentLogs.url} Component={ProductSentLogs} />
            <AuthorizedRoute  path={PageUrl.SalesImport.url} Component={SalesImport} />
            {/* <AuthorizedRoute path={PageUrl.paymentLogs.url} Component={PaymentLogs} /> */}
            <Route path="/signout-callback-oidc" component={Logout} exact />
            <Route path="*" component={NotFound} exact />
          </Switch>
        </div>
      </Router>
    </div>
  );
}
