import React, { FC } from "react";
import { Controller } from "react-hook-form";
//--
import { SCUITextField } from "../../../atoms";

export const SCUIControllerTextField: FC<any> = (props: any) => {
  return (
    <Controller
      name={props.stateName}
      control={props.formControl}
      rules={{
        required: props.requiredMessage,
        pattern: {
          value: props.patternValue,
          message: props.patternMessage,
        },
      }}
      defaultValue={props.value}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <SCUITextField
          {...props}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          stateName={props.stateName}
          value={value}
          helperText={error ? error.message : props.helperText}
          error={Boolean(error)}
        />
      )}
    />
  );
};
