import React, { useEffect, FC } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SCUIContent } from "../../../atomic/organisms";
import StyleVariables from "../../../assets/styles/variables/_export.module.scss";
import Helper from "../../../utils/helper";
import jwt_decode, { JwtPayload } from "jwt-decode";

import "./Home.scss";
import AuthService from "../../../services/auth/Auth.services";

export const Home: FC<any> = (props: any) => {

  const isTablet = useMediaQuery(`(min-width:  ${StyleVariables.breakpointSm});
  max-width:  ${StyleVariables.breakpointMd})
  `);
  const isDesktop = useMediaQuery(
    `(min-width:  ${StyleVariables.breakpointMd})`
  );
  const [commentCount, setCommentCount] = React.useState("");
  const [userName, setUserName] = React.useState("");

  const authService = new AuthService();
  interface JwtPayload {
    name: string
  }

  useEffect(() => {
    const hash = props?.history?.location?.hash;
    const el = hash && document.getElementById(hash.substr(1));
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }

  }, [props?.history?.location?.hash]);

  useEffect(() => {
    getUserName();
  }, []);
  const getUserName = () => {
    authService.getUserInfo().then((getUserInfo: any) => {
      if (!Helper.isNil(getUserInfo)) {
        const decodedToken = jwt_decode<JwtPayload>(getUserInfo.access_token);
        setUserName(decodedToken.name);
      }
    });
  }

  return (
    <div className="home">
      <SCUIContent headerName={" "}>
        {userName} hoşgeldiniz
      </SCUIContent>

    </div>
  );
};
