import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
//-----
import { UnAuthorizedRoute } from "../index";
import { PageAuthName } from "../../../types/enums";
import Helper from "../../../utils/helper";
import AuthService from "../../../services/auth/Auth.services";
import LocalStorageWrapper from "../../../core/wrappers/browserStorage/localStorage-wrapper";

interface IProps {
  setCurrentUser?: {};
  location?: any;
  Component?: any;
  res?: any;
  path: string;
  urlId?: boolean;
}

interface IState {
  user?: any;
  userIsLogin: boolean;
}

export class AuthorizedRoute extends Component<IProps, IState> {
  static propTypes = {
    setCurrentUser: PropTypes.any,
    location: PropTypes.any,
    Component: PropTypes.any,
    res: PropTypes.any,
    path: PropTypes.string,
    urlId: PropTypes.bool,
  };

  state = {
    user: null,
    userIsLogin: false,
  };

  private authService;
  constructor(props: IProps) {
    super(props);
    this.authService = new AuthService();
  }

  componentDidMount() {
    this.getCurrentUser();
  }

  async checkStsLogin(): Promise<boolean> {
    const accessToken = await this.authService.getAccessToken();
    if (accessToken !== null) {
      return true;
    } else {
      let returnPath = window.location.pathname;
      if (!Helper.isNil(window.location.search))
        returnPath += window.location.search;
      LocalStorageWrapper.setItemToLocalStorage("returnPath", returnPath);
      this.authService.login();
      return false;
    }
  }

  getCurrentUser() {

    this.setState({ ...this.state, user: true });
    //api cal get user Info
  }

  checkAuthorization() {
    console.log(this.props)
    const path = this.props.path.substring(1, this.props.path.length).replaceAll('/', '-');
    console.log(path)
    //const userScopes = this.state.user?.scopes;
    let result = false;
    if (PageAuthName.hasOwnProperty(path)) {
      if (PageAuthName[path] === "-") {
        result = true;
      }
    }
    return result;
  }

  render() {
    const { Component, ...rest } = this.props;

    /* +"/:id" */
    if (rest.urlId) { rest.path += "/details/:id"; rest.location.id = "id"; }

    let stsControl = this.checkStsLogin().catch((e: any) => console.log(e));

    return (
      <Route
        {...rest}
        render={(props: any): any =>

          Boolean(stsControl) ? (
            this.state.user ? (
              this.checkAuthorization() ? (
                <Component {...props} />
              ) : (
                <UnAuthorizedRoute {...props} />
              )
            ) : null
          ) : (
            this.authService.login()
          )
        }
      />
    );
  }
}
