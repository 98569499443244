import React, { useState, useEffect, FC } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Icon from "@mui/material/Icon";
import PersonIcon from "@mui/icons-material/Person";
import { Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";
import {
  SCUIMenuManagementDialog,
  SCUIMenuRoleDialog,
} from "../../../atomic/molecules";
import Swal from "sweetalert2";
import MerchantCommandService from "../../../services/command/MerchantCommandService";
 
import "./SCUIMenuManagementMainRow.scss";

interface IProps {
  row: any;
  open?: boolean;
  onClose?: any;
  parent?:any;
  roles?:any;
  refresh?:any;
}
export const SCUIMenuManagementMainRow: FC<IProps> = ({
  row,
  open = false,
  onClose,
  parent,
  roles,
  refresh,
}) => {
  const [opened, setOpened] = useState(false);
  const [editedMenu, setEditedMenu] = useState<any>();
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openEditRoleModal, setOpenEditRoleModal] = React.useState(false);
  const onModalEditClose = () => setOpenEditModal(false);
  const onModalRoleClose = () => setOpenEditRoleModal(false);
  const onModalEditOpen = (row: any, type: string) => {
    setEditedMenu(row);
    setOpenEditModal(true);
  };

  const onModalEditRole = (row: any) => {
    setEditedMenu(row);
    setOpenEditRoleModal(true);
  };

  function deleteRecord(row: any) {
    Swal.fire({
      text:
        /*   params.id +
       " nolu " + */
        row.text + " menüsünü silmek istediğinizden emin misiniz?",
      cancelButtonText: "İptal",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sil",
    }).then((result: any) => {
      if (result.isConfirmed) {
        MerchantCommandService.DeleteMenu(row.menuItemId)
          .then((res: any) => {
             Swal.fire("Silindi!", " ", "success");
             refresh();
          })
          .catch((error: any) => {
            Swal.fire("", " ", "error");
            console.log("Hata :>> ", error);
          })
          .finally(() => {});
      }
    });
  }
  return (
    <React.Fragment>
      <SCUIMenuManagementDialog
        selectedRow={editedMenu}
        open={openEditModal}
        onClose={onModalEditClose}
        parent={parent}
        refresh={refresh}
      />
      <SCUIMenuRoleDialog
        selectedRow={editedMenu}
        open={openEditRoleModal}
        onClose={onModalRoleClose}
        roles={roles}
      />

      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        key={row.menuItemId}
      >
        <TableCell className="mainMenuHeader">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpened(!opened)}
          >
            {opened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="mainMenuHeader">
          {row.text}
        </TableCell>

       <TableCell align="center" className="mainMenuHeader">
          {row.orderNo}
        </TableCell>
        <TableCell align="center" className="mainMenuHeader">
          <Icon>{row.iconName ? row.iconName : "-"}</Icon>
        </TableCell>
        <TableCell
          align="center"
          className="mainMenuHeader"
          onClick={() => onModalEditRole(row)}
        >
          <PersonIcon fontSize="large" />
        </TableCell>
        <TableCell
          align="center"
          className="mainMenuHeader"
          onClick={() => deleteRecord(row)}
        >
          <DeleteIcon fontSize="large" />
        </TableCell>
        <TableCell
          align="center"
          className="mainMenuHeader"
          onClick={() => onModalEditOpen(row, "edit")}
        >
          <EditIcon fontSize="large" />
        </TableCell>
      </TableRow>
      <TableRow key={"id" + row.menuItemId}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={opened} timeout="auto" unmountOnExit>
            <Table aria-label="purchases">
              <TableHead>
                <TableRow key={"l" + row.menuItemId}>
                  <TableCell>Text</TableCell>
                  <TableCell>NavigateViewUrl</TableCell>
                  <TableCell>OrderNo</TableCell>
                  <TableCell>icon</TableCell>
                  <TableCell>Rol</TableCell>
                  <TableCell>Sil</TableCell>
                  <TableCell>Düzenle</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row.items?.menuItem.map((menuItem: any) => (
                  <TableRow key={menuItem.menuItemId}>
                    <TableCell>{menuItem.text}</TableCell>
                    <TableCell>{menuItem.navigateViewUrl}</TableCell>
                    <TableCell>{menuItem.orderNo}</TableCell>
                    <TableCell>
                      <Icon>{menuItem.iconName ? menuItem.iconName : "-"}</Icon>
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={() => onModalEditRole(menuItem)}
                    >
                      <PersonIcon fontSize="large" />
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={() => deleteRecord(menuItem)}
                    >
                      <DeleteIcon fontSize="large" />
                    </TableCell>
                    <TableCell
                      align="center"
                      onClick={() => onModalEditOpen(menuItem, "edit")}
                    >
                      <EditIcon fontSize="large" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
