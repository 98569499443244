import * as React from "react";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import { styled, alpha } from "@mui/material/styles";
import DownloadIcon from "@mui/icons-material/Download";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import SettingsApplications from "@mui/icons-material/SettingsApplications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledMenu = styled((props) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 3,
    maxWidth: "130px",
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 10,
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkProductFileButtonStatus = (row) => {
    // Koşul 1: Eğer durum 'Sent' ise, true dön
    if (row.status === "Sent") {
      return true;
    }

    // Koşul 2: Eğer durum 'InProgress' ve SentDate'ten 4 saat 10 dakika geçmişse, true dön
    if (row.status === "InProgress" && row.sentDate) {
      const sentDate = new Date(row.sentDate);
      const limitTime = new Date(
        sentDate.getTime() + 4 * 60 * 60 * 1000 + 10 * 60 * 1000
      ); // 4 saat 10 dakika sonrası
      const now = new Date();

      if (now > limitTime) {
        return true;
      }
    }

    // Diğer durumlarda false dön
    return false;
  };

  const checkValidationErrorsDownloadDisabled = (row) => {
    console.log(row.status);
    if (row.status === "ValidationError" || row.status === 7) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
        >
          <SettingsApplications />
        </IconButton>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          style={{ fontSize: 12 }}
          onClick={props?.onClick}
          disabled={
            props?.rowData?.status === 2 || props?.rowData?.status === "Ready"
              ? false
              : true
          }
          disableRipple
        >
          <SendIcon style={{ marginRight: 5 }} />
          mp gönder
        </MenuItem>
        <MenuItem
          style={{ fontSize: 12 }}
          onClick={props.onClickDownload}
          disableRipple
        >
          <DownloadIcon style={{ marginRight: 5 }} />
          indir
        </MenuItem>
        <MenuItem
          style={{ fontSize: 12 }}
          onClick={props.productStatusFileDownload}
          disabled={!checkProductFileButtonStatus(props?.rowData)}
          disableRipple
        >
          <FindReplaceIcon style={{ marginRight: 5 }} />
          Ürün Durum Sorgula
        </MenuItem>

        <MenuItem
          style={{ fontSize: 12 }}
          onClick={props.validationErrorFileDownload}
          disabled={!checkValidationErrorsDownloadDisabled(props?.rowData)}
          disableRipple
        >
          <DownloadIcon style={{ marginRight: 5 }} />
          Validasyon Hatalarını İndir
        </MenuItem>

      </StyledMenu>
    </div>
  );
}
