import React, { FC, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Paper, Grid, TextField } from "@mui/material";
import { SCUIButton } from "../../../atomic/atoms";
import { SCUIContent } from "../../../atomic/organisms";
import {
  SCUIMenuManagementDialog,
  SCUICollapsiblePaper,
  SCUIMenuManagementMainRow
} from "../../../atomic/molecules";
 import MerchantQueryService from "../../../services/query/MerchantQueryService";
import "./MenuManagement.scss";

export const MenuManagement: FC<any> = (props: any) => {
  const [searchedMenuName, setSearchedMenuName] = React.useState(""); 
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const onModalEditClose = () => setOpenEditModal(false);
  const onModalEditOpen = (row: any, type: string) => {setOpenEditModal(true);};
  const [menuList, setMenuList] = useState<any>();
  const [searchedMenuList, setSearchedMenuList] = useState<any>();
  const [parentMenu, setParentMenu] = useState<any>();
  const [roles, setRoles] = useState<any>();
  const [refresh, setRefresh] = useState(false);

  const getMenuList = () => {
    MerchantQueryService.GetMenuAll()
      .then((res: any) => {
        if (res) {
          var parsedData = res.menuItem;
          setMenuList(parsedData);
          setSearchedMenuList(parsedData);
        }
        setRefresh(false);
      })
      .catch((error: any) => {
        console.log("Hata :>> ", error);
      });
  };
  const GetRolesList = () => {
    MerchantQueryService.GetRolesList()
      .then((res: any) => {
        if (res) {
          setRoles(res.model.data);
        }
      })
      .catch((error: any) => {
        console.log("Hata :>> ", error);
      });
  };
  const GetMenuItemsByParent = () => {
    MerchantQueryService.GetMenuItemsByParent()
      .then((res: any) => {
        if (res) {
          setParentMenu(res.model.data);
        }
      })
      .catch((error: any) => {
        console.log("Hata :>> ", error);
      });
  };
  useEffect(() => {
    GetMenuItemsByParent();
    GetRolesList();
  }, []);
 useEffect(()=>{getMenuList();},[refresh])
  const requestSearch = (searchedVal: any) => {
    setSearchedMenuName(searchedVal.target.value);
    let filteredMenu = searchedMenuList.filter((rows: any) => {
      return rows.items.menuItem.some((record: any) => {
        return (
          record.text
            .toLowerCase()
            .indexOf(searchedVal.target.value.toLowerCase()) !== -1
        );
      });
    });
    setMenuList(filteredMenu);
  };
  const refreshMenu=()=>{
 setRefresh(true);  }

  return (
    <SCUIContent>
      <SCUIMenuManagementDialog
        selectedRow={[]}
        open={openEditModal}
        onClose={onModalEditClose}
        parent={parentMenu}
        refresh={refreshMenu}
      />
      <Grid container direction="row">
      <Grid item lg={10} md={6} xs={6}> <h1 className="headtxt">Menü Moderasyon</h1></Grid> 
        <Grid item lg={2} md={6} xs={6}>
          <SCUIButton
            text={"Menü Ekle"}
            type="primary"
            sizeType="fixed"
            size={"m"}
            onClick={() => onModalEditOpen(null, "create")}
          />
        </Grid>
         <SCUICollapsiblePaper >
          <TextField
            value={searchedMenuName}
            onChange={(searchVal) => requestSearch(searchVal)}
            fullWidth
            placeholder="Menü adı"
          />
        </SCUICollapsiblePaper>{" "}
        <Paper sx={{ padding: "2rem", margin: "2rem", width: "100%" }}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow key={"id"}>
                  <TableCell />
                  <TableCell></TableCell>
                   <TableCell align="center">Order</TableCell>
                  <TableCell align="center">Icon</TableCell>
                  <TableCell align="center">Rol</TableCell>
                  <TableCell align="center">Sil</TableCell>
                  <TableCell align="center">Düzenle</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {menuList?.map((row: any, index: number) => (
                  <SCUIMenuManagementMainRow
                    key={index}
                    row={row}
                    parent={parentMenu}
                    roles={roles}
                    refresh={refreshMenu}
                    />
                ))}
                {null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </SCUIContent>
  );
};
