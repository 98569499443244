import React, { Component } from "react";
import DataTable from "react-data-table-component";
import customStylesTable from "../Const";
import { ReturnReason, ReturnReasonDescriptions } from "../../../types/enums/returnReason";

class OrdersAndCancelledDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getReturnItems(row) {
    return this.props.data.returnItems.filter(item => item.barcode === row.barcode);
  }

  prepareData() {
    const { orderItems, orderStatus } = this.props.data;
    const preparedData = [];
  
    orderItems.forEach((orderItem) => {
      const returnItemsForOrder = this.getReturnItems(orderItem);
      let remainingQuantity = orderItem.quantity;
  
      const groupedByReason = returnItemsForOrder.reduce((acc, returnItem) => {
        if (!acc[returnItem.reason]) {
          acc[returnItem.reason] = [];
        }
        acc[returnItem.reason].push(returnItem);
        return acc;
      }, {});
  
      Object.keys(groupedByReason).forEach(reason => {
        const items = groupedByReason[reason];
        const returnCount = items.length;
  
        if (remainingQuantity > 0) {
          remainingQuantity -= returnCount;
  
          preparedData.push({
            ...orderItem,
            quantity: returnCount,
            status: ReturnReasonDescriptions[reason],
          });
        }
      });

      if (remainingQuantity > 0) {
        preparedData.push({
          ...orderItem,
          quantity: remainingQuantity,
          status: orderStatus,
        });
      }
    });
  
    return preparedData;
  }
  
  render() {
    const columns = [
      {
        omit: false,
        selector: (row) => row.optionSizeRef,
        name: "Ürün Id",
        grow: 1,
      },
      {
        selector: (row) => row.barcode,
        name: "Barcode",
        grow: 1,
        width: "20rem",
      },
      // { selector: row => row.title, name: "Ürün Tanımı", grow: 1 , width: "40rem"},
      { selector: (row) => row.taxPrice.toFixed(2), name: "Kdv", grow: 1 },
      {
        selector: (row) => row.destTaxableUnitPrice,
        name: "Toplam Tutar",
        grow: 1,
        cell: (row) => (row.unitPrice - row.taxPrice).toFixed(2),
      },
      { selector: (row) => row.unitPrice, name: "Birim Fiyat", grow: 1 },
      { selector: (row) => row.taxableAmount, name: "Kdv Hariç Birim Fiyat", grow: 1 },
      {
        selector: (row) => this.props.data.currency,
        name: "Para Birimi",
        grow: 1,
      },
      { selector: (row) => row.quantity, name: "Adet", grow: 1 },
      {
        selector: (row) => row.status,
        name: "Durum",
        grow: 1,
      },
      // { selector: row => row.destTaxRate, name: "Kdv Oranı", grow: 1, cell: (row) => (row.destTaxRate).toFixed(2)},
    ];

    const preparedData = this.prepareData();

    return (
      <div>
        {
          <DataTable
            columns={columns}
            data={preparedData}
            highlightOnHover
            customStyles={customStylesTable}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            pagination={true}
            progressPending={this.state.loading}
          />
        }
      </div>
    );
  }
}
export default OrdersAndCancelledDetail;
