import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { SCUILoading } from "../../../atomic/atoms";
import MerchantQueryService from "../../../services/query/MerchantQueryService";
import TimeHelper from "../../../utils/time";
import { Input } from "reactstrap";
import customStylesTable from "../Const";
class TransactionActivityLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      currentPage: 1,
      filterDb: {
        page: 1,
        retailTransactionActivityId: this.props.data.id,
        ActivityTypeId: this.props.data.activityType,
      },
    };
  }
  componentDidMount() {
    this.getTransactionActivityLog(this.state.filterDb);
  }
  onChange(e) {
    var filterNew = {
      page: e,
      retailTransactionActivityId: this.props.data.id,
    };
    this.getTransactionActivityLog(filterNew);
  }
  getTransactionActivityLog(filterDb) {
    this.setState({ loading: true });
    MerchantQueryService.GetActivityLogById(filterDb).then((res) => {
      if (res.data) {
        res.data.forEach((x) => {
          x.createdOn = TimeHelper.momentDate(x.createdOn);
        });
        this.setState({
          data: res.data,
          loading: false,
          currentPage: filterDb.page,
        });
      }
    });
  }
  render() {
    const columns = [
      { omit: true, selector: (row) => row.id, name: "Id", grow: 1 },
      { selector: (row) => row.activityLogType, name: "Olay Tipi", grow: 1 },
      {
        selector: (row) => row.activityType,
        name: "Entegrasyon Alanı",
        grow: 1,
      },
      { selector: (row) => row.computerName, name: "Bilgisayar Adı", grow: 1 },
      { selector: (row) => row.createdOn, name: "Oluşma Tarihi", grow: 1 },
      {
        selector: (row) => row.message,
        name: "Mesaj",
        grow: 2,
        cell: (row) => {
          return (
            <Input
              readOnly
              style={{ border: "none" }}
              type="textarea"
              value={row.message}
            />
          );
        },
      },
      {
        selector: (row) => row.retailTransactionActivityStatus,
        name: "Durum",
        grow: 1,
        cell: (row) => {
          return (
            <span
              style={{
                color:
                  row.retailTransactionActivityStatus == "Failed"
                    ? "red"
                    : "green",
              }}
            >
              {row.retailTransactionActivityStatus}
            </span>
          );
        },
      },
    ];

    return (
      <>
        {this.state.loading ? (
          <SCUILoading />
        ) : (
          <>
            {
              <DataTable
                columns={columns}
                data={this.state.data}
                // pagination={true}
                progressPending={this.state.loading}
                highlightOnHover
                customStyles={customStylesTable}
                // paginationServer
                paginationTotalRows={100}
                // paginationComponentOptions={{
                //     noRowsPerPage: true,
                //   }}
                paginationDefaultPage={this.state.currentPage}
                onChangePage={(page) => this.onChange(page)}
              />
            }
          </>
        )}
      </>
    );
  }
}

export default TransactionActivityLog;
