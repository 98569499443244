import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import customStylesTable from '../../Const';

class WholeSaleDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };

    }
    render() {
        const columns = [
            { omit: true, selector: row => row.id, name: "Id", grow: 0, },
            { selector: row => row.itemId, name: 'Ürün Id', grow: 1, cell: (row) => (row.itemId) },
            { selector: row => (row.regularUnitPrice - row.taxableAmount).toFixed(2), name: "Kdv", grow: 1 },
            { selector: row => row.regularUnitPrice.toFixed(2), name: "Birim Fiyat", grow: 1 },
            { selector: row => row.quantity, name: "Adet", grow: 1 },
            { selector: row => row.taxPercent, name: "Kdv Oranı", grow: 1, cell: (row) => (row.taxPercent) },
        ];
        return (
            <div>
                {
                    <DataTable
                        columns={columns}
                        data={this.props.data.wholeSale.wholeSaleLineItems}
                        highlightOnHover
                        customStyles={customStylesTable}
                        paginationComponentOptions={{
                            noRowsPerPage: true,
                        }}
                        pagination={true}
                        progressPending={this.state.loading}
                    />
                }
            </div>
        );
    }
}

export default WholeSaleDetail;