import React, { useEffect, useRef, useState } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { SCUILoading } from '../../../../atomic/atoms';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import DataTable from "react-data-table-component";
import "./OrdersDashboard.scss";
import { Grid } from "@mui/material";
import MerchantQueryService from "../../../../services/query/MerchantQueryService";
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);
function OrdersTotalDashboard(props) {
  const [labels, setLabels] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [saleTotal, setSaleTotal] = useState(0);
  const [returnTotal, setReturnTotal] = useState(0);
  const [cancelTotal, setCancelTotal] = useState(0);
  const [ratio, setRatio] = useState(0);

  const data = {
    labels,
    datasets: [
      // {
      //   type: "line",
      //   yAxisID: "y2",
      //   label: "İade Oranı",
      //   borderColor: "rgba(246, 194, 48, 0.88)",
      //   borderWidth: 3,
      //   fill: false,
      //   data: ratio,
      //   datalabels: {
      //     display: true,
      //     anchor: "end",
      //     offset: 0,
      //     align: "right",
      //     marginBottom: "100px",
      //     formatter: function (value) {
      //       return  Number.isFinite(value)? value.toFixed(2) +" % ":0 +" % ";
      //     },
      //     font: {
      //       weight: 'bold',
      //       size: 12,
      //       marginBottom: "100px",
      //       align:"right"
      //     },
      //   },
      // },
      {
        type: "bar",
        label: "Sipariş",
        backgroundColor: "rgba(40, 87, 162, 0.88)",
        data: saleTotal,
        borderColor: "white",
        borderWidth: 2,
        datalabels: {
          display: true,
          anchor: "end",
          offset: -20,
          align: "start",
          marginBottom: "100px",
          formatter: function (value) {
            return `$ ${parseInt(value * 10, 10) % 2 == 0 ? Math.floor(value) : Math.ceil(value)}`;
          },
          font: {
            weight: 'bold',
            size: 12,
            marginBottom: "100px",
          },
        },
      },
      // {
      //   type: "bar",
      //   label: "İade",
      //   backgroundColor: "rgba(255, 99, 9, 0.77)",
      //   data: returnTotal,

      //   datalabels: {
      //     display: true,
      //     anchor: "end",
      //     offset: -20,
      //     align: "start",
      //     marginBottom: "100px",
      //     formatter: function (value) {
      //      return `€ ${ parseInt(value*10, 10) % 2 == 0 ? Math.floor(value) : Math.ceil(value)}`;
      //     },
      //     font: {
      //       weight: 'bold',
      //       size: 12,
      //       marginBottom: "100px",
      //     },
      //   },
      // },


      {
        type: "bar",
        label: "İptal",
        backgroundColor: "rgba(0, 0, 0, 0.35)",
        data: cancelTotal,

        datalabels: {
          display: true,
          anchor: "end",
          offset: -20,
          align: "start",
          marginBottom: "100px",
          formatter: function (value) {
            return `$ ${parseInt(value * 10, 10) % 2 == 0 ? Math.floor(value) : Math.ceil(value)}`;
          },
          font: {
            weight: 'bold',
            size: 12,
            marginBottom: "100px",
          },
        },
      }



    ],
  };

  const optionsForecast = {
    plugins: {
      legend: {
        position: "bottom",

        labels: {
          fontColor: "white",
          boxWidth: 20,
          padding: 20,
        },
      },
    },
    scales: {
      y: {
        id: "y1",
        ticks: {
          callback: function (value, index, ticks) {
            return "$" + value;
          },
        },
      },
      y2: {
        id: "y2",
        type: "linear",
        position: "right",
      },
    },
  };

  useEffect(() => {
    GetOrdersTotalDashboard(props.filter);
  }, [props?.filter]);

  const GetOrdersTotalDashboard = (filter) => {
    setIsLoading(true);
    MerchantQueryService.GetOrdersTotalDashboard(filter).then((res) => {
      if (res.data) {
        setLabels(res.data.labels);
        setSaleTotal(res.data.saleTotal);
        setReturnTotal(res.data.returnTotal);
        setRatio(res.data.ratio);
        setCancelTotal(res.data.cancelTotal);
        setIsLoading(false);
      }
    });
  };
  const columns = [
    { selector: (row) => row.ordersTotal, name: "Toplam Sipariş Tutarı", grow: 1 },
    { selector: (row) => row.cancelTotal, name: "Toplam İptal Tutarı", grow: 1 },
  ]
  const aaaaaaa = {
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
}
  return (
    <>
      {isLoading ? (
        <SCUILoading />
      ) : (
        <>
          <Grid >
            <Chart
              type="bar"
              data={data}
              plugins={[ChartDataLabels]}
              options={optionsForecast}
              style={{ height: "375px", width: "500px", marginLeft: "70px" }}
            />
          </Grid>
          <Grid  className="center" style={{backgroundColor:"darkorange"}}>
            <DataTable
              columns={columns}
              data={[{
                ordersTotal: `$ ${parseInt(saleTotal.reduce((a, b) => a + b, 0) * 10, 10) % 2 == 0 ? Math.floor(saleTotal.reduce((a, b) => a + b, 0)) : Math.ceil(saleTotal.reduce((a, b) => a + b, 0))}`,
                cancelTotal: `$ ${parseInt(cancelTotal.reduce((a, b) => a + b, 0) * 10, 10) % 2 == 0 ? Math.floor(cancelTotal.reduce((a, b) => a + b, 0)) : Math.ceil(cancelTotal.reduce((a, b) => a + b, 0))}`,

              }]}     
              progressPending={isLoading}
              highlightOnHover
             customStyles={aaaaaaa}
            />
          </Grid>
        </>
      )}
    </>
  );
}
export default OrdersTotalDashboard;