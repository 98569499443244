import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
/*
import { lighten, Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
*/
//--
import "./SCUIGridToolbar.scss";

const PREFIX = "SCUIGridToolbar";

const classes = {
  root: `${PREFIX}-root`,
  highlight: `${PREFIX}-highlight`,
  title: `${PREFIX}-title`,
};
/*
const StyledToolbar = styled(Toolbar)(({ theme: Theme }) => ({
  [`&.${classes.root}`]: {
    paddingLeft: theme?.spacing(2),
    paddingRight: theme?.spacing(1),
  },
  [`&.${classes.highlight}`]: theme.palette.mode === "light"
    ? {
        color: "#E3F3FF",
        backgroundColor: lighten("#E3F3FF", 0.85),
      }
    : {
        color:"#E3F3FF",
        backgroundColor: "#E3F3FF",
      },

  [`& .${classes.title}`]: {
    flex: "1 1 100%",
  }
}));
*/
/*
const useToolbarStyles = makeStyles((
  {
    theme: Theme
  }
) => ({
  [`&.${classes.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },

  [`&.${classes.highlight}`]: theme.palette.mode === "light"
    ? {
        color: "#E3F3FF",
        backgroundColor: lighten("#E3F3FF", 0.85),
      }
    : {
        color:"#E3F3FF",
        backgroundColor: "#E3F3FF",
      },

  [`& .${classes.title}`]: {
    flex: "1 1 100%",
  }
}),
);

*/
const StyledToolbar = styled(Toolbar)(({ theme: Theme }) => ({}));
const useToolbarStyles = makeStyles(() => ({
  [`&.${classes.root}`]: {},
}));
interface IProps {
  numSelected: number;
  headerText: string;
}

export const SCUIGridToolbar: FC<IProps> = ({ numSelected, headerText }) => {
  const classes = useToolbarStyles();

  return (
    <StyledToolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component={"div"}
        >
          {numSelected} adet seçildi
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {headerText}
        </Typography>
      )}
      {/*numSelected > 0 ? (
      <Tooltip title="Delete">
        <IconButton aria-label="delete">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title="Filter list">
        <IconButton aria-label="filter list">
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    )*/}
    </StyledToolbar>
  );
};
