import React, { FC, ReactElement } from "react";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
//--
import "./SCUITooltip.scss";

const PREFIX = "SCUITooltip";

const classes = {
  tooltip: `${PREFIX}-tooltip`,
};

const Root = styled("div")(({ theme: Theme }) => ({
  [`& .${classes.tooltip}`]: {
    textAlign: "center",
  },
}));

enum enumPositionType {
  top = "top",
  topStart = "top-start",
  topEnd = "top-end",
  left = "left",
  leftStart = "left-start",
  leftEnd = "left-end",
  right = "right",
  rightStart = "right-start",
  rightEnd = "right-end",
  bottom = "bottom",
  bottomStart = "bottom-start",
  bottomEnd = "bottom-end",
}

enum enumPositionLocation {
  top='top',
  bottom='bottom',
  right='right',
  left='left'
}

enum enumPositionDirection {
  start='start',
  end='end',
  default='default'
}

interface IProps {
  text: string;
  children: ReactElement<any, any>;
  positionLocation: enumPositionLocation.top | enumPositionLocation.bottom | enumPositionLocation.right |enumPositionLocation.left;
  positionDirection: enumPositionDirection.start | enumPositionDirection.default |  enumPositionDirection.end;
  arrow?: boolean;
  interactive?: boolean;
}
export const SCUITooltip: FC<IProps> = ({
  text='',
  children,
  positionLocation=enumPositionLocation.top ,
  positionDirection=enumPositionDirection.start ,
  arrow = false,
  interactive = false,
}) => {
  
  const getPosition = (): any => {
    if (positionDirection === "default") {
      return positionLocation;
    } else {
      return `${positionLocation}-${positionDirection}`;
    }
    return "";
  };

  return (
    <Root className={"sc-tooltip"}>
      <Tooltip
        open={true}
        classes={classes}
        title={text}
        arrow={arrow}
        placement={getPosition()}
      >
        {children}
      </Tooltip>
    </Root>
  );
};
