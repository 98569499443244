import * as moment from "moment";
const formatDate = (date: any): string => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = d.getUTCHours(),
    minutes = d.getUTCMinutes(),
    second = d.getUTCSeconds();
  var time = " " + hour + ":" + minutes + ":" + second;
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-") + " " + time;
};

const momentDate = (date: any): string => {
  return moment.parseZone(date).format("DD-MM-YYYY, HH:mm:ss");
};

const momentDateAddThreeHours = (date: any): string => {
  return moment.parseZone(date).add(3, "hours").format("DD-MM-YYYY, HH:mm:ss");
};

const TimeHelper = {
  formatDate,
  momentDate,
  momentDateAddThreeHours,
};

export default TimeHelper;
