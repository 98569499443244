import DoneIcon from "@mui/icons-material/Done";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { purple, red, blue, grey } from "@mui/material/colors";

//---
import { IObjectInfoString } from "../interfaces/baseObject";
import { enumRoleCategories } from "./roles";
import { enumSellerCurrentSaveFlowStatus } from "./status";

//#region Seller Register
enum enumNameSellerRegisterTabs {
  all = "all",
  pending = "pending",
  revisedApprovedPending = "revisedApprovedPending",
  revised = "revised",
  approved = "approved",
  reject = "reject",
}
const tabSellerRegisterIcon: any = {
  initial: {
    icon: ModeEditOutlineOutlinedIcon,
    color: "default",
  }, //kalem işlem yapılır sadece user işlem yapar.
  edited: {
    icon: HourglassFullIcon,
    color: "default",
  }, //Kum saati  user admin işlemini bekliyor , admin onaylama işlemi yapıyor
  decline: {
    icon: ErrorOutlinedIcon,
    color: "error",
  }, //Kırmızı ünlem => admin revise istedi
  empty: {
    icon: ErrorOutlineOutlinedIcon,
    color: "warning",
  }, //user boş alan gösterimi
  approved: {
    icon: CheckCircleIcon,
    color: "info",
  }, //Mavi Tık  => admin approved
  userApproved: {
    icon: CheckOutlinedIcon,
    color: "info",
  }, //user in doldurduğu alanlar
};
//#endregion

const enumRolesFlowTabIcon: any = {
  [enumRoleCategories.admin]: {
    [enumSellerCurrentSaveFlowStatus.preNewCreate]: {
      pending: tabSellerRegisterIcon.edited,
      positive: tabSellerRegisterIcon.edited,
      negative: tabSellerRegisterIcon.edited,
    },
    [enumSellerCurrentSaveFlowStatus.newCreate]: {
      pending: tabSellerRegisterIcon.edited,
      positive: tabSellerRegisterIcon.edited,
      negative: tabSellerRegisterIcon.edited,
    },
    [enumSellerCurrentSaveFlowStatus.draft]: {
      pending: tabSellerRegisterIcon.edited,
      positive: tabSellerRegisterIcon.edited,
      negative: tabSellerRegisterIcon.edited,
    },
    [enumSellerCurrentSaveFlowStatus.submit]: {
      //admin işlem yapar.
      pending: tabSellerRegisterIcon.initial,
      positive: tabSellerRegisterIcon.approved,
      negative: tabSellerRegisterIcon.decline,
    },
    [enumSellerCurrentSaveFlowStatus.revision]: {
      //admin statu atadı user işlem yapar.
      pending: tabSellerRegisterIcon.edited,
      positive: tabSellerRegisterIcon.approved,
      negative: tabSellerRegisterIcon.decline,
    },
    [enumSellerCurrentSaveFlowStatus.revisionDraft]: {
      pending: tabSellerRegisterIcon.edited,
      positive: tabSellerRegisterIcon.approved,
      negative: tabSellerRegisterIcon.edited,
    },
    [enumSellerCurrentSaveFlowStatus.reject]: {
      pending: tabSellerRegisterIcon.decline,
      positive: tabSellerRegisterIcon.decline,
      negative: tabSellerRegisterIcon.decline,
    },
    [enumSellerCurrentSaveFlowStatus.approved]: {
      pending: tabSellerRegisterIcon.approved,
      positive: tabSellerRegisterIcon.approved,
      negative: tabSellerRegisterIcon.approved,
    },
  },
  [enumRoleCategories.user]: {
    [enumSellerCurrentSaveFlowStatus.preNewCreate]: {
      pending: tabSellerRegisterIcon.initial,
      positive: tabSellerRegisterIcon.userApproved,
      negative: tabSellerRegisterIcon.empty,
    },
    [enumSellerCurrentSaveFlowStatus.newCreate]: {
      pending: tabSellerRegisterIcon.initial,
      positive: tabSellerRegisterIcon.userApproved,
      negative: tabSellerRegisterIcon.empty,
    },
    [enumSellerCurrentSaveFlowStatus.draft]: {
      pending: tabSellerRegisterIcon.initial,
      positive: tabSellerRegisterIcon.userApproved,
      negative: tabSellerRegisterIcon.empty,
    },
    [enumSellerCurrentSaveFlowStatus.submit]: {
      //user İşlem yapamaz.
      pending: tabSellerRegisterIcon.edited,
      positive: tabSellerRegisterIcon.edited,
      negative: tabSellerRegisterIcon.edited,
    },
    [enumSellerCurrentSaveFlowStatus.revision]: {
      //admin statü atadı bizden revisyon istiyor user işlem yapar
      pending: tabSellerRegisterIcon.edited,
      positive: tabSellerRegisterIcon.userApproved,
      negative: tabSellerRegisterIcon.decline,
    },
    [enumSellerCurrentSaveFlowStatus.revisionDraft]: {
      //user işlem yapar.
      pending: tabSellerRegisterIcon.initial,
      positive: tabSellerRegisterIcon.userApproved,
      negative: tabSellerRegisterIcon.decline,
    },
    [enumSellerCurrentSaveFlowStatus.reject]: {
      //user İşlem yapamaz.
      pending: tabSellerRegisterIcon.decline,
      positive: tabSellerRegisterIcon.decline,
      negative: tabSellerRegisterIcon.decline,
    },
    [enumSellerCurrentSaveFlowStatus.approved]: {
      //user İşlem yapamaz.
      pending: tabSellerRegisterIcon.approved,
      positive: tabSellerRegisterIcon.approved,
      negative: tabSellerRegisterIcon.approved,
    },
  },
};

//#region Seller Register Tab
const enumSellerRegisterTabs = {
  all: 0,
  pending: enumSellerCurrentSaveFlowStatus.submit,
  revised: enumSellerCurrentSaveFlowStatus.revision,
  revisedApprovedPending: 9,
  reject: enumSellerCurrentSaveFlowStatus.reject,
  approved: enumSellerCurrentSaveFlowStatus.approved,
};
 const enumIndexNameSellerRegisterTabs = {
  [enumSellerRegisterTabs.all]: "all",
  [enumSellerRegisterTabs.pending]: "pending",
  [enumSellerRegisterTabs.revised]: "revised",
  [enumSellerRegisterTabs.revisedApprovedPending]: "revisedApprovedPending",
  [enumSellerRegisterTabs.approved]: "approved",
  [enumSellerRegisterTabs.reject]: "reject",
};
 const enumSellerRegisterIndexTabs = {
  [enumSellerRegisterTabs.all]: {
    id: enumSellerRegisterTabs.all,
    name: "Tümü",
    priority: 0,
  },
  [enumSellerRegisterTabs.pending]: {
    id: enumSellerRegisterTabs.pending,
    name: "Onay Bekleyenler",
    priority: 1,
  },
  [enumSellerRegisterTabs.revised]: {
    id: enumSellerRegisterTabs.revised,
    name: "Revize istenilenler",
    priority: 3,
  },
  [enumSellerRegisterTabs.revisedApprovedPending]: {
    id: enumSellerRegisterTabs.revisedApprovedPending,
    name: "Revize Onayı Bekleyenler",
    priority: 2,
  },
  [enumSellerRegisterTabs.approved]: {
    id: enumSellerRegisterTabs.approved,
    name: "Onaylananlar",
    priority: 4,
  },
  [enumSellerRegisterTabs.reject]: {
    id: enumSellerRegisterTabs.reject,
    name: "Reddedilenler",
    priority: 5,
  },
};
//#endregion
 
//#region Current Record Create
 const currentRecordCreateTabNames: IObjectInfoString = {
  supplierInfo: "supplierInfo",
  operationInfo: "operationInfo",
  addressInfo: "addressInfo",
  contactInfo: "contactInfo",
  documentInfo: "documentInfo",
  vendorAgreementInfo: "vendorAgreementInfo",
};
 
 const enumCurrentRecordCreateTabs = {
  [currentRecordCreateTabNames.supplierInfo]: 0,
  [currentRecordCreateTabNames.operationInfo]: 1,
  [currentRecordCreateTabNames.addressInfo]: 2,
  [currentRecordCreateTabNames.contactInfo]: 3,
  [currentRecordCreateTabNames.documentInfo]: 4,
  [currentRecordCreateTabNames.vendorAgreementInfo]: 5,
  //applicationStatus: 6,
};

 const enumCurrentRecordCreateTabName = {
  [enumCurrentRecordCreateTabs.supplierInfo]: currentRecordCreateTabNames.supplierInfo,
  [enumCurrentRecordCreateTabs.operationInfo]: currentRecordCreateTabNames.operationInfo,
  [enumCurrentRecordCreateTabs.addressInfo]: currentRecordCreateTabNames.addressInfo,
  [enumCurrentRecordCreateTabs.contactInfo]: currentRecordCreateTabNames.contactInfo,
  [enumCurrentRecordCreateTabs.documentInfo]: currentRecordCreateTabNames.documentInfo,
  [enumCurrentRecordCreateTabs.vendorAgreementInfo]: currentRecordCreateTabNames.vendorAgreementInfo,
};

 const enumCurrentRecordCreateIndexTabs = {
  [enumCurrentRecordCreateTabs.supplierInfo]: {
    id: enumCurrentRecordCreateTabs.supplierInfo,
    name: "Şirket Bilgileri",
    activeIcon: HourglassFullIcon,
    passiveIcon: DoneIcon,
    color: "primary",
  },
  [enumCurrentRecordCreateTabs.operationInfo]: {
    id: enumCurrentRecordCreateTabs.operationInfo,
    name: "Operasyon Bilgileri",
    activeIcon: HourglassFullIcon,
    passiveIcon: DoneIcon,
    color: "primary",
  },
  [enumCurrentRecordCreateTabs.addressInfo]: {
    id: enumCurrentRecordCreateTabs.addressInfo,
    name: "Adres Bilgileri",
    activeIcon: HourglassFullIcon,
    passiveIcon: DoneIcon,
    color: "primary",
  },
  [enumCurrentRecordCreateTabs.contactInfo]: {
    id: enumCurrentRecordCreateTabs.contactInfo,
    name: "İletişim Bilgileri",
    activeIcon: HourglassFullIcon,
    passiveIcon: DoneIcon,
    color: "primary",
  },
  [enumCurrentRecordCreateTabs.documentInfo]: {
    id: enumCurrentRecordCreateTabs.documentInfo,
    name: "Belgeler",
    activeIcon: HourglassFullIcon,
    passiveIcon: DoneIcon,
    color: "primary",
  },
  [enumCurrentRecordCreateTabs.vendorAgreementInfo]: {
    id: enumCurrentRecordCreateTabs.vendorAgreementInfo,
    name: "Satıcı Sözleşmesi ve Onay",
    activeIcon: HourglassFullIcon,
    passiveIcon: DoneIcon,
    color: "primary",
  },
};
//#endregion
 
//#region My Demands Tab
const enumMyDemandsTabs = {
  vendorApplication: 0,
  changeRequests: 1,
  myChangeRequests: 2,
};
const enumMyDemandsTabsIndexTabs = {
  [enumMyDemandsTabs.vendorApplication]: {
    id: enumMyDemandsTabs.vendorApplication,
    name: "Satıcı Başvurusu",
  },
  /* [enumMyDemandsTabs.changeRequests]: {
    id: enumMyDemandsTabs.changeRequests,
    name: "Değişiklik Talepleri",
  },
  [enumMyDemandsTabs.myChangeRequests]: {
    id: enumMyDemandsTabs.myChangeRequests,
    name: "Değişiklik Taleplerim",
  },*/
};
//#endregion

export {
  enumMyDemandsTabsIndexTabs,
  enumMyDemandsTabs,
  //--
  enumCurrentRecordCreateIndexTabs,
  enumCurrentRecordCreateTabName,
  enumCurrentRecordCreateTabs,
  currentRecordCreateTabNames,
  //--
  enumSellerRegisterIndexTabs,
  enumIndexNameSellerRegisterTabs,
  enumSellerRegisterTabs,
  //--
  enumRolesFlowTabIcon,
  //--
  tabSellerRegisterIcon,
  enumNameSellerRegisterTabs
}