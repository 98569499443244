import request from "../../core/wrappers/axios-wrapper";
import { ApiURL } from "../../types/constants/api-urls";
import { RequestType } from "../../types/enums/request-enums";

const PostAssigToMe = (data: any): Promise<any> =>
  request({
    url: ApiURL.Command.PostCommentAssignToMe,
    method: RequestType.PUT,
    data,
  });

const PostCommentTicketAdd = (data: any): Promise<any> =>
  request({
    url: ApiURL.Command.PostCommentTicketAdd,
    method: RequestType.POST,
    data,
  });

const PostCommentStatusUpdate = (data: any) =>
  request({
    url: ApiURL.Command.PostCommentStatusUpdate,
    method: RequestType.POST,
    data,
  });

const DeleteCommentTicket = (data: any) =>
  request({
    url: ApiURL.Command.DeleteCommentTicket + "/" + data + "/remove",
    method: RequestType.DELETE,
    data,
  });

const DeleteMenu = (data: any) =>
  request({
    url: ApiURL.Command.DeleteMenu + "/" + data + "/delete",
    method: RequestType.DELETE,
  });

const DeleteOperatorFromRole = (data: any) =>
  request({
    url: ApiURL.Command.DeleteOperatorFromRole,
    method: RequestType.POST,
    data,
  });

const PostMenuAdd = (data: any) =>
  request({
    url: ApiURL.Command.PostMenuAdd,
    method: RequestType.POST,
    data,
  });

const PostMenuItemRoleAdd = (data: any) =>
  request({
    url: ApiURL.Command.PostMenuItemRoleAdd,
    method: RequestType.POST,
    data,
  });

const PutMenuUpdate = (data: any) =>
  request({
    url: ApiURL.Command.PutMenuUpdate,
    method: RequestType.PUT,
    data,
  });

const PutOperatorUpdate = (data: any) =>
  request({
    url: ApiURL.Command.PutOperatorUpdate,
    method: RequestType.PUT,
    data,
  });
//#region Mock Data (Json Server)

const PostOperatorCreate = (data: any) =>
  request({
    url: ApiURL.Command.PostOperatorCreate,
    method: RequestType.POST,
    data,
  });

const PostAddOperatorToRole = (data: any) =>
  request({
    url: ApiURL.Command.PostAddOperatorToRole,
    method: RequestType.POST,
    data,
  });

const ImportExcel = (data: any) =>
  request({
    url: ApiURL.Command.ExcelImport,
    method: RequestType.POST,
    data,
  });
  const ImportSalesExcel = (data: any) =>
  request({
    url: ApiURL.Command.ImportSalesExcel,
    method: RequestType.POST,
    data,
  });
const SendFileToMarketPlace = (data: any) =>
  request({
    url: ApiURL.Command.SendFileToMarketPlace,
    method: RequestType.POST,
    data,
  });

/*const PostTestRegisterVendors = (id: string, formData: any) =>
  request({
    url: `${ApiURL.Command.PostTestRegisterVendors}/${id}`,
    method: RequestType.PUT,
    data: { ...formData },
  });*/
//#endregion

const MerchantCommandService = {
  PostAssigToMe,
  PostCommentStatusUpdate,
  PostCommentTicketAdd,
  DeleteCommentTicket,
  PostMenuAdd,
  PutMenuUpdate,
  DeleteMenu,
  PostOperatorCreate,
  PutOperatorUpdate,
  PostMenuItemRoleAdd,
  PostAddOperatorToRole,
  DeleteOperatorFromRole,
  ImportExcel,
  ImportSalesExcel,
  SendFileToMarketPlace,
  //test
  // PostTestRegisterVendors
};

export default MerchantCommandService;
