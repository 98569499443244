import React, { FC, useState } from "react";
import { SCUIButton } from "../../../atomic/atoms";
import {
  starScoreLookupList,
  commentStatusLookupList
} from "../../../pages/ProductComments/CommentList/CommentList.config";
import "./SCUICommentListForm.scss";
import {
  SCUIControllerSelectbox,
  SCUIControllerTextField,
} from "../../../atomic/molecules";
import { FormControl, InputLabel, Grid, Select, MenuItem } from "@mui/material";
import { useForm } from "react-hook-form";
import * as XLSX from "xlsx";
import MerchantQueryService from "../../../services/query/MerchantQueryService";
import { enumCommentStatusFlowStatusTrName } from "../../../types/enums/status";
import TimeHelper from "../../../utils/time";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
interface IProps {
  formFilterInfo: any;
  tagNames: any;
  operators: any;
  commentList: any;
  pageCount: any;
  param: any;
  urls: any;
  myFilterInfo: any;
}
export const SCUICommentListForm: FC<IProps> = ({
  formFilterInfo,
  tagNames,
  operators,
  commentList,
  urls,
  pageCount,
  myFilterInfo,
}) => {
  const { handleSubmit: _filterFormSubmit, control: filterFormControl } =
    useForm();
  const [tagName, setTagName] = React.useState<string[]>(myFilterInfo.tagName);
  const [operator, setOperator] = React.useState<string[]>(myFilterInfo.operator);
console.log(myFilterInfo)
  const _handlChangeTag = (event: any) => {
    const {
      target: { value },
    } = event;
    setTagName(value);
    filterInfo.tagName = [...tagName];
  };

  const handleChangeClick = (event: any) => {
    setOperator(event.target.value);
    filterInfo.operator = [...operator];
  };

  const downloadExcel = () => {
    urls = urls.slice(31);
    urls = "size=" + pageCount + "&" + urls;
    MerchantQueryService.GetCommentData(urls).then((res: any) => {
      if (res) {
        let parseData = res.data.map((item: any) => {
          return {
            "Yorum Id": item.id || "-",
            Mail: item.userEmail || "-",
            "Özel Kod": item.specialCode || "-",
            "Renk Kod": item.colorcode || "-",
            "Yorum Tarihi": TimeHelper.formatDate(item.commentDate) || "-",
            Puan: item.starScore || "-",
            "Yorum Statü": enumCommentStatusFlowStatusTrName[item.status],
            Etiket: item.tagName || "-",
            "Atanan Kişi": item?.userOperatorName || "-",
            "Ürün Yorumu":item.commentText || "-",
          };
        });
        const workSheet = XLSX.utils.json_to_sheet(parseData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "comment");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(workBook, "CommentList.xlsx");
      }
    });
  };

  const [filterInfo, setFilterInfo] = useState(myFilterInfo);
  const _onSubmitFilterForm = (filterForm: any, event: any) => {
    filterForm.tagName = [...tagName];
    filterForm.operator = [operator];
    setFilterInfo(filterForm);
    formFilterInfo(filterForm);
  };
  
   return (
    <form onSubmit={_filterFormSubmit(_onSubmitFilterForm)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={3} xl={1.5}>
          <SCUIControllerTextField
            fullWidth
            //placeholder={"Option Id"}
            label={"Option Id"}
            size={"l"}
            formControl={filterFormControl}
            value={filterInfo.optionId}
            variant={"outlined"}
            shrink={true}
            stateName={"optionId"}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={1.5}>
          <SCUIControllerTextField
            fullWidth
            label={"Special Code"}
            formControl={filterFormControl}
            // placeholder={"specialCode"}
            size={"l"}
            value={filterInfo.specialCode}
            variant={"outlined"}
            shrink={true}
            stateName={"specialCode"}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} xl={1.5}>
          <SCUIControllerTextField
            fullWidth
            label={"Color Code"}
            formControl={filterFormControl}
            // placeholder={"specialCode"}
            size={"l"}
            value={filterInfo.colorCode}
            variant={"outlined"}
            shrink={true}
            stateName={"colorCode"}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2} xl={2}>
          <SCUIControllerTextField
            fullWidth
            label={"Başlangıç Tarihi"}
            stateName={"createDate"}
            type={"date"}
            shrink={true}
            formControl={filterFormControl}
            value={filterInfo.createDate}
            size={"l"}
            variant={"outlined"}
            placeholder={"Başlangıç Tarihi"}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2} xl={2}>
          <SCUIControllerTextField
            fullWidth
            label={"Bitiş Tarihi"}
            stateName={"endDate"}
            type={"date"}
            shrink={true}
            formControl={filterFormControl}
            value={filterInfo.endDate}
            size={"l"}
            variant={"outlined"}
            placeholder={"Bitiş Tarihi"}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={1} xl={1.7}>
          <SCUIControllerSelectbox
            fullWidth
            name="starScore"
            label={"Puan seçiniz"}
            selectList={filterInfo.starScore}
            list={starScoreLookupList}
            formControl={filterFormControl}
            value={"Puan Seçiniz"}
            stateName={"starScore"}
            size={"m"}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={1} xl={1.7}>
          <SCUIControllerSelectbox
            fullWidth
            name="commentStatus"
            label={"Statu Seçiniz"}
            selectList={filterInfo.commentStatus}
            list={commentStatusLookupList}
            formControl={filterFormControl}
            value={"Statu Seçiniz"}
            stateName={"commentStatus"}
            size={"m"}
          />
        </Grid>
        <Grid item md={12} xs={6} lg={1} xl={2}>
          <FormControl fullWidth>
            <InputLabel id="opertorselectlabel">İşlem yapan kişi</InputLabel>
            <Select
              labelId="opertorselectlabel"
              id="opertorselect"
              label="İşlem yapan kişi"
              value={operator}
              onChange={handleChangeClick}
            >
              {/* <MenuItem key={"[Kullanıcı Seçiniz]"} value={-1}>{"[Kullanıcı Seçiniz]"}</MenuItem> */}
              {operators.map((operator: any) => (
                <MenuItem key={operator.username} value={operator.combinedId}>
                  {operator.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={12} xs={6} lg={1} xl={5}>
          <FormControl fullWidth>
            <InputLabel id="tagMultipleSelectLabel">Etiket</InputLabel>

            <Select
              labelId="tagMultipleSelectLabel"
              id="tagMultipleSelect"
              multiple
              value={tagName}
              onChange={_handlChangeTag}
            >
              {tagNames.map((name: any) => (
                <MenuItem key={name.id} value={name.id}>
                  {name.keyWord}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid className="button" item xs={12} md={6} lg={2} xl={2}>
          <SCUIButton
            text={"Filtrele"}
            type="primary"
            sizeType="fixed"
            size={"m"}
          />
        </Grid>

        <Grid className="button" item xs={12} md={6} lg={2} xl={1}>
        <SCUIButton
                           
                        text={"export"}
                        type="ghost"
                        sizeType="default"
                        size="l"
                        className="changeColor"
                        showStartIcon
                        startIcon={<AssignmentReturnedIcon />}
                        onClick={() =>
                           downloadExcel()
                        }
                      ></SCUIButton>
          </Grid>
      </Grid>
    </form>
  );
};
