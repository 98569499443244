 
import {  enumCommentFlowStatus} from "../../../../src/types/enums";
export interface IProps {
  history: any;
  filterInfo: any;
  id: number;
}
 
export const initialState = {
  loading: false,
  filterInfo: {
    commentStatus: null,
  },
 
  CommentDetailType: {
    applicationId: " ",
    id: " ",
    optionImgUrl: " ",
    language: " ",
    colorCode: " ",
    commentTitle: " ",
    commentText: " ",
    amount: " ",
    createOn: " ",
    changeOn: " ",
    commentDate: "",
    createdBy: " ",
    criteriaId: " ",
    customerId: " ",
    explicitConsentId: " ",
    firstCommentDate: " ",
    isDeleted: " ",
    isEmptyComment: " ",
    isUserNameShow: " ",
    modelId: " ",
    optionId: " ",
    optionName: " ",
    optionUrl: " ",
    orderId: " ",
    regionId: " ",
    score: " ",
    searchingArea: " ",
    serviceId: " ",
    serviceType: " ",
    shipmentDate: " ",
    shipmentId: " ",
    siteId: " ",
    specialCode: " ",
    starScore: " ",
    status: " ",
    suggest: " ",
    tag: " ",
    userEmail: " ",
    userName: " ",
  },
}

 