import React, { Component } from "react";
//--
import AuthService from "../../../services/auth/Auth.services";

interface IProps {
  history: any;
  login: () => any;
}
export class Logout extends Component<IProps, any> {
  authService;

  constructor(props: any) {
    super(props);
    this.authService = new AuthService();
  }

  componentDidMount() {
    this.authService.completeLogout();
    this.props.history.push("/");
  }

  render() {
    return (
      <div>
        <p>Redirect Works!</p>
      </div>
    );
  }
}
