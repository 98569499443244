import React, { FC} from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import MerchantCommandService from "../../../services/command/MerchantCommandService";
 
import "./SCUIMenuManagementDialog.scss";
import { SCUIMenuManagementDialogContent } from "../SCUIMenuManagementDialogContent/SCUIMenuManagementDialogContent";

interface IProps {
  selectedRow?: any;
  open?: boolean;
  onClose?: any;
  parent?:any;
  refresh?:any;
}
export const SCUIMenuManagementDialog: FC<IProps> = ({
  selectedRow = null,
  open = false,
  onClose,
  parent,
  refresh
}) => { 

  const addMenu = async (addedMenuItem:any) => {
  
    MerchantCommandService.PostMenuAdd(addedMenuItem)
      .then((res: any) => {
        onClose();
        refresh();
        Swal.fire("Eklendi!", " ", "success");

       })
      .catch((error: any) => {
        console.log("Hata :>> ", error);
      })
      .finally(() => {});
  };
   const editMenu = async (editedMenuItem:any) => {
      
    MerchantCommandService.PutMenuUpdate(editedMenuItem)
      .then((res: any) => {
        onClose();
        refresh();
        Swal.fire("Güncellendi", " ", "success");

      })
      .catch((error: any) => {
        console.log("Hata :>> ", error);
      })
      .finally(() => {});
  }; 
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle> Menü Bilgileri Giriniz</DialogTitle>
      <SCUIMenuManagementDialogContent selectedRow={selectedRow} onAddMenu={addMenu} onEditMenu={editMenu} onCloseMenu={onClose} parent={parent}/>
    </Dialog>
  );
};
