import { ActionType as Type } from "../../types";

const initialState = {
    mpCountries: [],
    mpList: [],
    mpIntegratorList: [],
    mpOrderStatusList:[],
    mpOrderOriginCodeList:[]
};

export const handlerLookUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case Type.HANDLER_MP_COUNTRIES:
            return {
                ...state,
                mpCountries: action?.mpCountriesList,
            };
        case Type.HANDLER_MP_LIST:
            return {
                ...state,
                mpList: action?.mpList,
            };
        case Type.HANDLER_MP_INTEGRATORS:
            return {
                ...state,
                mpIntegratorList: action?.mpIntegratorList,
            };
            case Type.HANDLER_MP_ORDERSTATUS:
                return {
                    ...state,
                    mpOrderStatusList: action?.mpOrderStatusList,
                };
                case Type.HANDLER_MP_ORIGINCODE:
                return {
                    ...state,
                    mpOrderOriginCodeList: action?.mpOrderOriginCodeList,
                };
        default:
            return state;
    }
};
