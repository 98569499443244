import React, { FC, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import DoneIcon from "@mui/icons-material/Done";
import StarBorderIcon from "@mui/icons-material/StarBorder"; //-
import Tooltip from "@mui/material/Tooltip";
import {
  SCUIGridHeader,
  SCUIGridToolbar,
  SCUILoading,
  SCUINoData,
} from "../../atoms";
import {
  enumCellType,
  enumSellerCurrentSaveFlowStatus,
} from "../../../types/enums";
//--
import "./SCUIGrid.scss";
//#region styles

const PREFIX = "SCUIGrid";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
  visuallyHidden: `${PREFIX}-visuallyHidden`,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.table}`]: {
    minWidth: 750,
  },

  [`& .${classes.visuallyHidden}`]: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

//#endregion

type Order = "asc" | "desc";

function _getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  headInfo: any
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  let cellInfo: any =
    String(orderBy).trim().length > 0
      ? headInfo.find((item: any) => item.id === orderBy)
      : null;
  return order === "desc"
    ? (a, b) => _descendingComparator(a, b, orderBy, cellInfo)
    : (a, b) => -_descendingComparator(a, b, orderBy, cellInfo);
}

function _stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const getCellInfo = (cellInfo: any, sortValue: any) => {
  if (cellInfo) {
    return cellInfo?.cellType === enumCellType.string || cellInfo?.cellType === enumCellType.icons
      ? sortValue
      : String(sortValue?.text);
  }
  return sortValue;
};

function _descendingComparator<T>(a: any, b: any, orderBy: any, cellInfo: any) {
  let aValue: any = getCellInfo(cellInfo, a[orderBy]);
  let bValue: any = getCellInfo(cellInfo, b[orderBy]);
  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

interface IRow {
  [key: string]: string;
}

interface IProps {
  headCells?: {
    id: string;
    numeric: boolean;
    disablePadding: boolean;
    label: string;
  }[];
  rows?: IRow[];
  onClickRow?: (rowInfo: any, rowIndex: number) => void;
  onClickRowField?: (
    rowId: string | number,
    fieldInfo: any | null,
    rowInfo: any
  ) => void;
  onChangePage?: (key: string, value: number) => void;
  onClickSelectAllRow?: (rowList: Array<any>) => void;
  type?: string;
  headerText?: string;
  history?: any;
  showPagination?: boolean;
  paginationInfo?: {
    rowsPerPage: number;
    currentPage: number;
    pageCount: number;
    totalCount: number;
  };
  loading?: boolean;
  emptyDataMessage?: string;
  numSelected?: number;
  showCheckbox?: boolean;
  checkboxFilterKey?: string;
}

export const SCUIGrid: FC<IProps> = ({
  numSelected = 1,
  headCells = [],
  rows = [],
  onClickRow,
  onClickRowField,
  onClickSelectAllRow,
  onChangePage,
  type = "other",
  emptyDataMessage = "Kayıt bulunamadı.",
  headerText = "",
  history,
  showPagination = true,
  paginationInfo = {
    rowsPerPage: 10,
    currentPage: 0,
    pageCount: 1,
    totalCount: 10,
  },
  loading = false,
  showCheckbox = false,
  checkboxFilterKey,
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedCheckboxType, setSelectedCheckboxType] = React.useState("");

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  //#region UseEffects
  useEffect(() => {
    if (paginationInfo.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(paginationInfo.rowsPerPage);
    }
  }, [paginationInfo.rowsPerPage]);
  //#endregion

  //#region Events
  const _onRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const _onSelectAllClickHead = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      if (rows.length > 0) {
        const allRowListId = rows.map((n) => n?.id);
        if (allRowListId.length > 0 && onClickSelectAllRow) {
          setSelected(allRowListId);
          onClickSelectAllRow(rows);
        }
      }
    } else {
      setSelectedCheckboxType("");
      setSelected([]);
      if (onClickSelectAllRow) {
        onClickSelectAllRow([]);
      }
    }
  };

  const _onChangeCheckbox = (event: React.MouseEvent<unknown>, row: any) => {
    const selectedIndex = selected.indexOf(row.id);
    let newSelectedList: string[] = [];

    if (selectedIndex === -1) {
      //yoksa ekler.
      newSelectedList = [...selected];
      newSelectedList.push(row.id);
      if (checkboxFilterKey && row.hasOwnProperty(checkboxFilterKey)) {
        setSelectedCheckboxType(row[checkboxFilterKey].id);
      }
    } else {
      //varsa siler
      newSelectedList = selected.filter((id: string) => id !== row.id);
    }
    setSelected(newSelectedList);
    if (newSelectedList.length === 0) {
      setSelectedCheckboxType("");
    }
    _onClickRowField("checkbox", null, row);
  };

  const _onClickTableRow = (
    event: React.MouseEvent<unknown>,
    row: any,
    rowIndex: number
  ) => {
    /* const selectedIndex = selected.indexOf(row.id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
     setSelected(newSelected);*/
    _onSelectRow(row, rowIndex);
  };

  const _onChangePage = (event: unknown, newPage: number) => {
    if (onChangePage) {
      setPage(newPage);
      onChangePage("currentPage", newPage);
    }
  };

  const _onChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChangePage) {
      let perPage = parseInt(event.target.value, 10);
      onChangePage("rowsPerPage", perPage);
      onChangePage("currentPage", 0);
      setRowsPerPage(perPage);
      setPage(0);
    }
  };

  const _onChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const _onSelectRow = (rowInfo: any, rowIndex: number) => {
    if (onClickRow) {
      onClickRow(rowInfo, rowIndex);
    }
  };

  const _onClickRowField = (
    headRowId: string | number,
    fieldInfo: any,
    rowInfo: any
  ) => {
    if (onClickRowField) {
      onClickRowField(headRowId, fieldInfo, rowInfo);
    }
  };
  //#endregion

  //#region Methods & Variable
  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  /* const emptyRows =
   rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);*/
  //#endregion

  //#region Render
  const _renderCellData = (headInfo: any, row: any) => {
    if (headInfo.cellType === enumCellType.status) {
      return (
        <p
          className={
            headInfo.cellType === enumCellType.status
              ? `txt-${row.commentStatus.className}`
              : ""
          }
        >
          {row[headInfo.id].text}
        </p>
      );
    } else if (headInfo.cellType === enumCellType.button) {
      if (headInfo?.icon) {
        return (
          <div className="cell-data">
            <Tooltip title={headInfo?.label} placement="right-start">
              <headInfo.icon />
            </Tooltip>
          </div>
        );
      }
      return row[headInfo.id]?.text;
    } else if (headInfo.cellType === enumCellType.icons) {
      var rows = [];
      for (var i = 0; i < Number(row.status); i++) {
        rows.push(<headInfo.icon color="warning" />);
      }
      for (var j = 0; j < 5 - Number(row.status); j++) {
        rows.push(<StarBorderIcon color="warning" />);
      }
      return <div className="cell-data">{rows}</div>;
    }
    return row[headInfo.id];
  };

  const _renderCheckbox = (rowInfo: any, isItemSelected: any) => {
    let _renderCheckboxElements = (
      <Checkbox
        checked={isItemSelected}
        className={`sc-checkbox`}
        onChange={(event: any) => _onChangeCheckbox(event, rowInfo)}
        inputProps={{ "aria-labelledby": "item" }}
      />
    );

    if (checkboxFilterKey) {
      if (selectedCheckboxType === "") {
        return _renderCheckboxElements;
      } else if (
        rowInfo.hasOwnProperty(checkboxFilterKey) &&
        selectedCheckboxType === rowInfo[checkboxFilterKey].id
      ) {
        return _renderCheckboxElements;
      }
      return <></>;
    } else {
      return _renderCheckboxElements;
    }
  };
  //#endregion

  return (
    <div className={`sc-grid ${classes.root}`}>
      <Paper className={`sc-grid__paper ${classes.paper}`}>
        {headerText !== "" && (
          <SCUIGridToolbar
            numSelected={selected.length}
            headerText={headerText}
          />
        )}

        <TableContainer className={`sc-grid__table-container`}>
          <Table
            className={`sc-grid__table ${classes.table}`}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <SCUIGridHeader
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={_onSelectAllClickHead}
              onRequestSort={_onRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              showCheckbox={showCheckbox}
            />
            <TableBody className={`sc-grid__table-body`}>
              {loading ? (
                <TableRow>
                  <TableCell
                    className={`sc-grid__table-cell`}
                    component="th"
                    colSpan={7}
                    scope="row"
                    padding="none"
                  >
                    <SCUILoading />
                  </TableCell>
                </TableRow>
              ) : rows.length > 0 ? (
                _stableSort(
                  rows,
                  _getComparator(order, orderBy, headCells)
                ).map((row: any, rowIndex: number) => {
                  const isItemSelected = isSelected(row?.id);
                  const labelId = `enhanced-table-checkbox-${rowIndex}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) =>
                        _onClickTableRow(event, row, rowIndex)
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={rowIndex}
                      selected={isItemSelected}
                      className={`sc-grid__table-row cursor-pointer`}
                    >
                      {showCheckbox && (
                        <StyledTableCell
                          className={`sc-grid__table-cell`}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {_renderCheckbox(row, isItemSelected)}
                        </StyledTableCell>
                      )}
                      {headCells.map((headInfo: any, indexHead: number) => {
                        if (row[headInfo.id]) {
                          return (
                            <TableCell
                              className={`sc-grid__table-cell`}
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                              key={indexHead}
                            >
                              <div
                                className={"sc-grid__table-cell__data"}
                                onClick={() =>
                                  _onClickRowField(
                                    headInfo.id,
                                    row[headInfo.id],
                                    row
                                  )
                                }
                              >
                                {_renderCellData(headInfo, row)}
                                {/*index === Object.values(row).length - 1 && (
                                  <IconButton onClick={() => _onSelectRow(row)}>
                                    <LineWeightIcon />
                                  </IconButton>
                                )*/}
                              </div>
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow className={`sc-grid__table-row`}>
                  <TableCell colSpan={7} className={`sc-grid__table-cell`}>
                    <SCUINoData message={emptyDataMessage} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {showPagination && (
          <TablePagination
            labelRowsPerPage="Sayfadaki satır sayısı"
            rowsPerPageOptions={[5, 10, 20, 50]}
            className={`sc-grid__table-pagination`}
            component="div"
            count={paginationInfo.totalCount}
            rowsPerPage={paginationInfo.rowsPerPage}
            page={paginationInfo.currentPage}
            onPageChange={_onChangePage}
            onRowsPerPageChange={_onChangeRowsPerPage}
          />
        )}
      </Paper>
      {/*<FormControlLabel
        control={<Switch checked={dense} onChange={_onChangeDense} />}
        label="Dense padding"
      />*/}
    </div>
  );
};
