import React, { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
//import useMediaQuery from "@mui/material/useMediaQuery";
//import { useTheme } from "@mui/material/styles";
//--
import "./SCUIModal.scss";
import { SCUIButton } from "../index";

/*
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "#FFFFFF",
      borderRadius: 8,
      borderColor: "none",
      boxShadow: "none",
      padding: 48,
      marginLeft: "auto",
      marginRight: "auto",
      //marginLeft: "5%",
      // marginRight: "5%",
      //overflow: "auto",
      //maxHeight: "90%",
    },
  }),
);
*/
const defaultSize = {
  xs: 4,
  sm: 4,
  md: 6,
  lg: 8,
  xl: 8,
};
interface IProps {
  open: boolean;
  onClose: () => void;
  headerText?: string;
  children: any;
  onClickApproveBtn?: () => void;
  txtApproveBtn?: string;
  txtCancelBtn?: string;
  showApproveButton?: boolean;
  sizeInfo?: any;
  approveBtnDisabled?: boolean;
}

//#region

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <div className="sc-modal__header">
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
      </DialogTitle>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} className="btn-close">
          <CloseIcon className="icon" />
        </IconButton>
      ) : null}
    </div>
  );
};

//#endregion

export const SCUIModal: FC<IProps> = ({
  open,
  onClose,
  headerText,
  children,
  onClickApproveBtn,
  txtApproveBtn = "Evet",
  txtCancelBtn="Vazgeç",
  showApproveButton = false,
  sizeInfo = defaultSize,
  approveBtnDisabled = false,
}) => {
  //const classes = useStyles();
  // const theme = useTheme();

  //const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const _renderHeader = () => {
    return (
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {headerText}
      </BootstrapDialogTitle>
    );
  };

  const _renderFooter = () => {
    return (
      <DialogActions>
        <div className="sc-modal__bottom">
          <SCUIButton
            text={txtCancelBtn}
            type="secondary"
            size="m"
            sizeType={"fixed"}
            className="footer-btn btn-cancel"
            onClick={onClose}
          />
          <SCUIButton
            text={txtApproveBtn}
            type="primary"
            size="m"
            sizeType={"fixed"}
            className="footer-btn btn-approve"
            onClick={onClickApproveBtn}
            disabled={approveBtnDisabled}
          />
        </div>
      </DialogActions>
    );
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={false}
      maxWidth={"md"}
      className="sc-modal"
    >
      {_renderHeader()}
      <DialogContent dividers className="sc-modal__content">
        {children}
      </DialogContent>
      {showApproveButton && _renderFooter()}
    </BootstrapDialog>
  );
};

/*

 <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      onBackdropClick={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Grid container className="sc-modal">
          <Grid item className="sc-modal__area" {...sizeInfo}>
            <div className={classes.paper}>
              <div className="sc-modal__header">
                <h2 id="transition-modal-title">{headerText}</h2>
                <IconButton className="btn-icon icons--m" onClick={onClose} size="large">
                  <CloseIcon className="icon" />
                </IconButton>
              </div>
              <div className="sc-modal__content" id="transition-modal-description"> {children} </div>

              {showApproveButton && (
                <div className="sc-modal__bottom">
                  <SCUIButton
                    text={"Vazgeç"}
                    type="secondary"
                    size="m"
                    sizeType={"fixed"}
                    className="footer-btn btn-cancel"
                    onClick={onClose}
                  />
                  <SCUIButton
                    text={txtApproveBtn}
                    type="primary"
                    size="m"
                    sizeType={"fixed"}
                    className="footer-btn"
                    onClick={onClickApproveBtn}
                  />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
*/
