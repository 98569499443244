import { Grid, Paper, TextField } from "@mui/material";
import { Component } from "react";
import { connect } from "react-redux";
import { SCUIContent } from "../../../atomic/organisms";
import { handlerMpOriginCodeList } from "./../../../stores/redux/actions/lookupAction/lookupAction";
import { SCUIButton, SCUIButtonFileUpload, SCUICheckbox, SCUILoading, SCUITextField } from "../../../atomic/atoms";
import { SCUISnackbar, SCUIAlertDialog } from "../../../atomic/molecules";
import Select from "react-select";
import Helper from "../../../utils/helper";
import MerchantCommandService from "../../../services/command/MerchantCommandService";
import customStylesTable from "../../Orders/Const";
import DataTable from "react-data-table-component";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { tr } from "date-fns/locale";
import MerchantQueryService from "../../../services/query/MerchantQueryService";
import "./SalesImport.scss"

class SalesImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mpOrigins: [],
            openMessage: false,
            openDialog: false,
            selectedMpOrigin: {},
            disabledButton: false,
            loading: false,
            selectedFiles: [],
            validationMessage: "",
            status: "",
            showErrorTable: false,
            errorData: [],
            checkedFinance: false,
            checkedBIReport: false,
            salesPeriod: null,
            disabledSalesPeriod: true,
            disabledFinanceCheck: true,
            disabledBıReportCheck: true,
            salesPeriodError: false
        };
    }
    componentDidMount() {
        this.getMpOriginCodeList();
        this.getMpOriginsFromIntegration();
    }

    getMpOriginCodeList() {
        this.props.handlerMpOriginCodeList();
    }

    getMpOriginsFromIntegration() {
        this.setState({ loading: true });
        MerchantQueryService.GetMpOriginsFromIntegration({ IsConsignee: true }).then((res) => {
            this.setState({
                mpOrigins: res ?? [],
                loading: false,
            });
        });
    }

    onChange(e) {
        if (e.length > 1) {
            this.setState({
                selectedFiles: [],
                openMessage: true,
                validationMessage: "Yalnız 1 adet seçebilirsiniz !",
                status: "warning",
            });
        } else {
            if (!Helper.objectIsEmpty(this.state?.selectedMpOrigin)) {
                this.setState({
                    selectedFiles: e,
                    showErrorTable: false,
                    errorData: [],
                    disabledButton: false,
                });
            } else {
                this.setState({
                    // selectedFiles: [],
                    openMessage: true,
                    validationMessage:
                        "Ürün Yüklemesi Yapmak İçin Önce MarketPlace Seçilmelidir!",
                    status: "warning",
                });
            }
        }
    }

    onChangeOriginCode(e) {
        var origin = this.state.mpOrigins.filter(x => x.originCode == e.value)[0];
        if(!origin.salesPeriod){
            this.setState(
                {
                    disabledSalesPeriod:true,
                    salesPeriod:null
                },
                () => { })
        }
        else{
            this.setState(
                {
                    disabledSalesPeriod:false,
                    salesPeriod:null
                },
                () => { })
        }
        if (origin.transferToAll) {
            this.setState(
                {
                    disabledButton: this.state.selectedFiles.length > 0 ? false : true,
                    selectedMpOrigin: e,
                    disabledBıReportCheck: true,
                    disabledFinanceCheck: true,
                    checkedBIReport: true,
                    checkedFinance: true
                },
                () => { })
        }
        else {
            this.setState(
                {
                    disabledButton: this.state.selectedFiles.length > 0 ? false : true,
                    selectedMpOrigin: e,
                    disabledBıReportCheck: false,
                    disabledFinanceCheck: false,
                    checkedBIReport: false,
                    checkedFinance: false
                },
                () => { })
        }
    }

    pushData() {
        if (this.state.selectedFiles.length > 0) {
            if (this.state.checkedFinance == true || this.state.checkedBIReport == true) {
                var origin = this.state.mpOrigins.filter(x => x.originCode == this.state?.selectedMpOrigin?.value)[0];
                if (origin.salesPeriod == true && this.state.salesPeriod == null && this.state.checkedFinance == true) {
                    this.setState({
                        openMessage: true,
                        validationMessage: `${origin.name} için dönem seçimi zorunlu!`,
                        status: "danger",
                        salesPeriodError: true
                    });
                }
                else {
                    this.setState({ disabledButton: true, loading: true, showErrorTable: false })

                    var transferredTo = [];
                    if (this.state.checkedFinance)
                        transferredTo.push(1)
                    if (this.state.checkedBIReport)
                        transferredTo.push(2)

                    var excelImportModel = {
                        OriginCode: this.state?.selectedMpOrigin?.value,
                        FileType: "Sales",
                        PeriodDate: this.state.salesPeriod,
                        TransferType: transferredTo

                    };

                    const data = Helper.jsonToFormData(excelImportModel);
                    for (let index = 0; index < this.state.selectedFiles.length; index++) {
                        data.append("File", this.state.selectedFiles[index]);
                    }

                    try {
                        MerchantCommandService.ImportSalesExcel(data)
                            .then((res) => {
                                this.setState({
                                    selectedFiles: [],
                                    openMessage: true,
                                    validationMessage: "Excel veri yükleme başarıyla tamamlandı",
                                    status: "success",
                                    disabledButton: true,
                                    loading: false
                                });
                            })
                            .catch((res) => {
                                this.setState({
                                    showErrorTable: true,
                                    errorData: res?.data?.data,
                                    openMessage: true,
                                    validationMessage: res?.data?.message ?? "İçerik hatası !",
                                    status: "danger",
                                    disabledButton: false,
                                    loading: false
                                });
                            });
                    }
                    catch (error) {
                        console.log(error)
                    }
                }

            }
            else {
                this.setState({
                    openMessage: true,
                    validationMessage: "En az bir aktarım yöntemi seçmelisiniz !",
                    status: "danger",
                });
            }


        } else {
            this.setState({
                openMessage: true,
                validationMessage: "Herhangi bir dosya yüklenmedi!",
                status: "warning",
            });
        }
    }

    render() {
        return (
            <SCUIContent>
                <Grid container direction="column">
                    <Paper sx={{ padding: "2rem", margin: "2rem", width: "100%" }}>

                        <Grid container spacing={4}>
                            <Grid item xs={2} mt={3}  >
                                <Select
                                    styles={customStyles}
                                    placeholder={"Origin Code"}
                                    value={this.state.selectedMpOrigin}
                                    onChange={(e) =>
                                        this.onChangeOriginCode(e)
                                    }
                                    options={this.props?.mpOrderOriginCodeList.filter(item => { return this.state.mpOrigins.some(item2 => item2.originCode == item.value) })}
                                />
                            </Grid>

                            <Grid item xs={1} mt={3} className="containers" >
                                <div className="smallText"> BI Report</div>
                                <SCUICheckbox
                                    checked={this.state.checkedBIReport}
                                    defaultChecked={false}
                                    disabled={this.state.disabledBıReportCheck}
                                    onChange={(e) => {
                                        this.setState(
                                            {
                                                checkedBIReport: e.target.checked,
                                                disabledSalesPeriod:true,
                                                checkedFinance: e.target.checked ?
                                                this.state.mpOrigins.filter(x => x.originCode == this.state.selectedMpOrigin.value)[0].transferToAll ?
                                                    false
                                                    : null
                                                
                                                : null,
                                                salesPeriod:null,
                                            },
                                            () => { }
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1} mt={3} className="containers">
                                <div className="smallText"> Finance</div>
                                <SCUICheckbox
                                    checked={this.state.checkedFinance}
                                    defaultChecked={false}
                                    disabled={this.state.disabledFinanceCheck}
                                    onChange={(e) => {
                                        this.setState(
                                            {
                                                checkedFinance: e.target.checked,
                                                disabledSalesPeriod: e.target.checked ?
                                                    this.state.mpOrigins.filter(x => x.originCode == this.state.selectedMpOrigin.value)[0].salesPeriod ?
                                                        false
                                                        : true
                                                    : true,
                                                    checkedBIReport:e.target.checked ?
                                                    this.state.mpOrigins.filter(x => x.originCode == this.state.selectedMpOrigin.value)[0].transferToAll ?
                                                        false
                                                        : null
                                                    
                                                    : null
                                            },
                                            () => { }
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={3} mt={3} className="containers">
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                                    <DatePicker
                                        renderInput={(props) => (
                                            <TextField {...props} style={{ width: "190px" }} />
                                        )}
                                        label={"Dönem"}
                                        inputFormat="dd/MM/yyyy"
                                        value={this.state.salesPeriod}
                                        disabled={this.state.disabledSalesPeriod}
                                        className={!this.state.salesPeriodError ? "" : 'red-border'}
                                        onChange={(event) => {
                                            event.setHours(0, 0, 0, 0);
                                            this.setState({
                                                // disabledButton: false,
                                                salesPeriod: event.toLocaleDateString("en-US"),
                                                salesPeriodError: false
                                            },
                                            );
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={10} mt={3}>
                                <SCUIButtonFileUpload
                                    onChange={(e) => this.onChange(e)}
                                    selectedFiles={this.state.selectedFiles}
                                />
                            </Grid>
                            <Grid item xs={2} mt={3}>

                                <SCUIButton
                                    text={"Satış Dosyası Yükle"}
                                    type="primary"
                                    disabled={
                                        !Helper.objectIsEmpty(this.state?.selectedMpOrigin) && !this.state.disabledButton
                                            ? false
                                            : true
                                    }
                                    sizeType="fixed"
                                    size={"m"}
                                    onClick={() => this.pushData()}
                                />
                            </Grid>
                        </Grid>
                    </Paper>

                    <Paper sx={{ width: "100%", margin: "1rem", padding: "1rem" }}>
                        <div style={{ height: 2550, width: "100%" }}>
                            {this.state.showErrorTable && this.state.errorData ? (
                                <DataTable
                                    columns={columns}
                                    data={this.state.errorData}
                                    highlightOnHover
                                    // expandableRows
                                    customStyles={customStylesTable}
                                    paginationPerPage={50}
                                    pagination={true}
                                    paginationServer
                                    // paginationTotalRows={this.state.totalCount}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true,
                                    }}
                                // paginationDefaultPage={this.state.currentPage}
                                // onChangePage={(page) => this.onChange(page)}
                                />
                            ) : this.state.loading ? <SCUILoading /> : null}
                        </div>
                    </Paper>
                </Grid>

                <SCUISnackbar
                    messageText={this.state.validationMessage}
                    status={this.state.status}
                    show={this.state.openMessage}
                    onClose={() => this.setState({ openMessage: false })}
                    autoHideDuration={3000}
                />
            </SCUIContent>
        );
    }
}

const columns = [
    { omit: true, selector: (row) => row.id, name: "Id", grow: 0 },
    { selector: (row) => row.sheetName, name: "Sheet Name", grow: 2 },
    { selector: (row) => row.row - 2, name: "Line", grow: 2 },
    { selector: (row) => row.column, name: "Column", grow: 2 },
    {
        selector: (row) => row.message,
        name: "Error",
        cell: (row) => <div>Kontrol ediniz</div>,
        grow: 2
    },
];

const customStyles = {
    zIndex: "inherit",
    control: (base) => ({
        ...base,
        height: 55,
        minHeight: 35,
        zIndex: 9999,
        flex: 10,
    }),
    menuPortal: (base) => ({
        ...base,

        zIndex: 9999,
    }),
    container: (provided) => ({
        ...provided,
        width: "250px",
        height: "50px", // Ana container'ın genişliğini belirleyebilirsiniz
    }),
};

const mapDispatchToProps = (dispatch) => {
    return {
        handlerMpOriginCodeList:()=> handlerMpOriginCodeList(dispatch,true),
    };
};

const mapStateToProps = ({ lookups }) => ({
    mpOrderOriginCodeList: lookups.mpOrderOriginCodeList,
});
export default connect(mapStateToProps, mapDispatchToProps)(SalesImport);