import React from "react";

import { Button, Dialog, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import { useHistory } from "react-router-dom";
//--
import "./UnAuthroizedRoute.scss";
import { PageAuthName } from "../../../types/enums/page-auth";

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
  location: {
    pathname: string;
  };
}

export const UnAuthorizedRoute = (props: IProps) => {
  const { location } = props;
  const [open, setOpen] = React.useState(true);
  const goBackFunc: any = useHistory();

  const handleClose = () => {
    setOpen(false);
    goBackFunc?.back();
  };

  const userInfo = { name: "" }; //useSelector((state:any) => state.commonReducers.currentUser);

  const getClaimName = () => {
    const pathname: string = location?.pathname;
    const path: string = pathname.replace(/[/]+/g, "");

    if (
      Object.keys(PageAuthName).length !== 0 &&
      PageAuthName.hasOwnProperty(path) &&
      path !== ""
    ) {
      const claim = PageAuthName[path];
      return claim;
    }
    return null;
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      className="sc-unauthorized-route"
      open={open}
    >
      <div className="modal">
        <div className="head">
          <Button className="btn-close trigger" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </div>
        <div className="content">
          <div className="auth-error">
            <LockIcon />
            <h3>Yetkiniz Bulunmamaktadır.</h3>
            <h5>
              Sayın {userInfo?.name?.replace(/[.]+/g, " ")}, <br /> Sistem
              yöneticinizle iletişime geçiniz.
            </h5>
            <h6>
              Sayfaya erişim için <u> {getClaimName()} </u>
              yetkisi tanımlanmalıdır
            </h6>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
