import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { find, isNil } from "lodash";
import { enumRetailTransactionActivityStatus } from "../../../../types/enums";

export default function CountryDataDashboardBar(props) {
  let { chartType, countryData } = props;

  const [countryName, setCountryName] = useState([]);

  const [pendingStatus, setPendingStatusStatus] = useState([]);
  const [inProgressStatus, setInProgressStatus] = useState([]);
  const [completedStatus, setCompletedStatus] = useState([]);
  const [failedStatus, setFailedStatusStatus] = useState([]);

  useEffect(() => {
    if (countryData.length > 0) {
      var countryName = [];
      var pendingArray = [];
      var inProgressArray = [];
      var completedArray = [];
      var failedArray = [];

      countryData.forEach((country) => {
        countryName.push(country?.countryName);

        var activityType = find(country?.activityType, {
          activityTypeId: chartType,
        });

        var activityStatus = activityType?.activityStatus;

        if (isNil(activityType)) {
          pendingArray.push(0);
          inProgressArray.push(0);
          completedArray.push(0);
          failedArray.push(0);
        } else {
          var activityPending = find(activityStatus, {
            activityStatusId: enumRetailTransactionActivityStatus.Pending,
          });
          var activityInProgress = find(activityStatus, {
            activityStatusId: enumRetailTransactionActivityStatus.InProgress,
          });
          var activityCompleted = find(activityStatus, {
            activityStatusId: enumRetailTransactionActivityStatus.Completed,
          });
          var activityFailed = find(activityStatus, {
            activityStatusId: enumRetailTransactionActivityStatus.Failed,
          });

          pendingArray.push(
            isNil(activityPending) ? 0 : activityPending?.activityStatusCount
          );
          inProgressArray.push(
            isNil(activityInProgress)
              ? 0
              : activityInProgress?.activityStatusCount
          );
          completedArray.push(
            isNil(activityCompleted)
              ? 0
              : activityCompleted?.activityStatusCount
          );
          failedArray.push(
            isNil(activityFailed) ? 0 : activityFailed?.activityStatusCount
          );
        }
      });

      setCountryName(countryName);
      setPendingStatusStatus(pendingArray);
      setInProgressStatus(inProgressArray);
      setCompletedStatus(completedArray);
      setFailedStatusStatus(failedArray);
    }
  }, [countryData]);

  const data = {
    labels: countryName,
    datasets: [
      {
        label: "Pending",
        data: pendingStatus,
        backgroundColor: "#f9af29",
      },
      {
        label: "InProgress",
        data: inProgressStatus,
        backgroundColor: "#055160",
      },
      {
        label: "Completed",
        data: completedStatus,
        backgroundColor: "#21bf73",
      },
      {
        label: "Failed",
        data: failedStatus,
        backgroundColor: "#f76262",
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked",
      },
      datalabels: {
        color: "white",
        font: {
          size: 12,
        },
        padding: 6,
        formatter: (value) => {
          return value;
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <Bar
      options={options}
      data={data}
      width={700}
      height={400}
      plugins={[ChartDataLabels]}
    />
  );
}
