import { request, requestCsv } from "../../core/wrappers/axios-wrapper";
import { ApiURL } from "../../types/constants/api-urls";
import { RequestType } from "../../types/enums/request-enums";

//#region comment metod

const GetCommentData = (params: any) =>
  request({
    url: ApiURL.Query.GetCommentData + "?" + params,
    method: RequestType.GET,
    // params:params,
  });

const GetCommentTicketList = () =>
  request({
    url: ApiURL.Query.GetCommentTicketList,
    method: RequestType.GET,
  });

const GetCommentStatusList = () =>
  request({
    url: ApiURL.Query.GetCommentStatusList,
    method: RequestType.GET,
  });

const GetCommentRejectReasonList = () =>
  request({
    url: ApiURL.Query.GetCommentRejectReasonList,
    method: RequestType.GET,
  });
//#endregion

const GetOperatorService = () =>
  request({
    url: ApiURL.Query.GetOperatorService,
    method: RequestType.GET,
  });

const GetMenuList = () =>
  request({
    url: ApiURL.Query.GetMenuList,
    method: RequestType.GET,
    //params:params,
  });

const GetCheckOperatorList = () =>
  request({
    url: ApiURL.Query.GetCheckOperatorList,
    method: RequestType.GET,
    //params:params,
  });

const GetCommentDataById = (params: any) =>
  request({
    url: ApiURL.Query.GetCommentDataById + "/" + params.id,
    method: RequestType.GET,
    //params:params,
  });
//#endregion

const GetOperatorList = () =>
  request({
    url: ApiURL.Query.GetOperatorList,
    method: RequestType.GET,
    //params:params,
  });

const GetOperatorListByRoleId = (params: any) =>
  request({
    url: ApiURL.Query.getOperatorListByRoleId + "/" + params + "/operators",
    method: RequestType.GET,
    //params:params,
  });

const GetMenuItemsByParent = () =>
  request({
    url: ApiURL.Query.GetMenuItemsByParent,
    method: RequestType.GET,
    //params:params,
  });

const GetStoreSaleOrderByOrderNo = (params: any) =>
  request({
    url: ApiURL.Query.GetStoreSaleOrderByOrderNo + "?orderNo=" + params,
    method: RequestType.GET,
    //params:params,
  });

const GetStoreInvoiceFile = (params: any) =>
  request({
    url: ApiURL.Query.GetStoreInvoiceFile,
    method: RequestType.GET,
    params: params,
    responseType: "blob",
  });

const GetRolesList = () =>
  request({
    url: ApiURL.Query.GetRolesList,

    method: RequestType.GET,
    //params:params,
  });

const GetPaymentLogsListCustomerId = (params: any) =>
  request({
    url: ApiURL.Query.GetPaymentLogsListCustomerId + "?customerId=" + params,

    method: RequestType.GET,
    // params:params,
  });

const GetPaymentLogsListOrderNumber = (params: any) =>
  request({
    url: ApiURL.Query.GetPaymentLogsListOrderNumber + "?orderNumber=" + params,

    method: RequestType.GET,
    //params:params,
  });

const GetOperatorUpdate = (params: any) =>
  request({
    url: ApiURL.Query.GetOperatorUpdate,
    method: RequestType.GET,
    //params:params,
  });

const GetMenuAll = () =>
  request({
    url: ApiURL.Query.GetMenuAll,
    method: RequestType.GET,
    //params:params,
  });

const GetMenuRoleList = (params: any) =>
  request({
    url: ApiURL.Query.GetMenuRoleList + "?menuId=" + params,
    method: RequestType.GET,
    //params:params,
  });

const GetTransactions = (params: any) =>
  request({
    url: ApiURL.Query.GetTransactionList,

    method: RequestType.GET,
    params: params,
  });

const GetOrderList = (params: any) =>
  request({
    url: ApiURL.Query.GetOrderList,

    method: RequestType.GET,
    params: params,
  });

const GetSaleAndReturnDashboard = (params: any) =>
  request({
    url: ApiURL.Query.GetSaleAndReturnDashboard,

    method: RequestType.GET,
    params: params,
  });

const GetSaleAndReturnTotalDashboard = (params: any) =>
  request({
    url: ApiURL.Query.GetSaleAndReturnTotalDashboard,
    method: RequestType.GET,
    params: params,
  });

const GetSaleAndReturnQuantitylDashboard = (params: any) =>
  request({
    url: ApiURL.Query.GetSaleAndReturnQuantitylDashboard,
    method: RequestType.GET,
    params: params,
  });

const GetOrdersQuantityDashboard = (params: any) =>
  request({
    url: ApiURL.Query.GetOrdersQuantityDashboard,
    method: RequestType.GET,
    params: params,
  });

const GetOrdersTotalDashboard = (params: any) =>
  request({
    url: ApiURL.Query.GetOrdersTotalDashboard,
    method: RequestType.GET,
    params: params,
  });

const GetDataForFilter = (params: any) =>
  request({
    url: ApiURL.Query.GetDataForFilter,
    method: RequestType.GET,
    params: params,
  });

const ManuelRetransmission = (params: any) =>
  request({
    url: ApiURL.Query.ManuelRetransmission,
    method: RequestType.POST,
    params: params,
  });

const DownloadExcel = (params: any) =>
  request({
    url: ApiURL.Query.TransactionExportToExcel,
    responseType: "blob",
    method: RequestType.GET,
    params: params,
  });

const GetDocumentByOrderNumber = (params: any) =>
  request({
    url: ApiURL.Query.GetDocumentByOrderNumber,
    responseType: "blob",
    method: RequestType.GET,
    params: params,
  });

const DownloadCsv = (params: any) =>
  requestCsv({
    url: ApiURL.Query.TransactionExportToCsv,
    responseType: "blob",
    method: RequestType.GET,
    params: params,
  });

const DownloadOrderExcel = (params: any) =>
  request({
    url: ApiURL.Query.TransactionOrderExportToExcel,
    responseType: "blob",
    method: RequestType.GET,
    params: params,
  });

const DownloadOrderCsv = (params: any) =>
  requestCsv({
    url: ApiURL.Query.TransactionOrderExportToCsv,
    responseType: "blob",
    method: RequestType.GET,
    params: params,
  });

const DownloadProductFileExport = (params: any) =>
  requestCsv({
    url: ApiURL.Query.DownloadProductFileExport,
    responseType: "blob",
    method: RequestType.GET,
    params: params,
  });

  const DownloadValidationErrorExcel = (params: any) =>
  request({
    url: ApiURL.Query.DownloadProductValidationErrorExport,
    responseType: "blob",
    method: RequestType.GET,
    params: params,
  });

const GetAllactivity = (params: any) =>
  request({
    url: ApiURL.Query.GetActivityList,

    method: RequestType.GET,
    params: params,
  });

const GetProductList = (params: any) =>
  request({
    url: ApiURL.Query.GetProductList,
    method: RequestType.GET,
    params: params,
  });

const GetMpList = (params: any) =>
  request({
    url: ApiURL.Query.GetMpList,
    method: RequestType.GET,
    params: params,
  });

const GetMpCountryList = (params: any) =>
  request({
    url: ApiURL.Query.GetMpCountryList,
    method: RequestType.GET,
    params: params,
  });

const GetMpIntegratorList = (params: any) =>
  request({
    url: ApiURL.Query.GetMpIntegratorList,
    method: RequestType.GET,
    params: params,
  });

const GetMpOrderStatusList = (params: any) =>
  request({
    url: ApiURL.Query.GetMpOrderStatusList,
    method: RequestType.GET,
    params: params,
  });

const GetMpOriginCodeList = (params: any) =>
  request({
    url: ApiURL.Query.GetMpOriginCodeList,
    method: RequestType.GET,
    params: params,
  });
  const GetMpOriginsFromIntegration = (params: any) =>
  request({
    url: ApiURL.Query.GetMpOriginsFromIntegration,
    method: RequestType.GET,
    params: params,
  });

  const GetMpOriginsFromSales = (params: any) =>
  request({
    url: ApiURL.Query.GetMpOriginsFromSales,
    method: RequestType.GET,
    params: params,
  });
  

const GetProductSentLogList = (params: any) =>
  request({
    url: ApiURL.Query.GetProductSentLogList,
    method: RequestType.GET,
    params: params,
  });

const DownloadProductExcel = (params: any) =>
  request({
    url: ApiURL.Query.DownloadProductExcel,
    responseType: "blob",
    fileName: params.fileName,
    method: RequestType.GET,
    params: params,
  });

const GetActivityLogById = (params: any) =>
  request({
    url: ApiURL.Query.GetActivityLogsById,

    method: RequestType.GET,
    params: params,
  });

const GetCountryTransfer = (params: any) =>
  request({
    url: ApiURL.Query.GetCountryTransferStatistics,

    method: RequestType.GET,
    params: params,
  });

const GetActivityData = (params: any) =>
  request({
    url: ApiURL.Query.GetActivityData,

    method: RequestType.GET,
    params: params,
  });

const MerchantQueryService = {
  GetCommentData,
  GetCommentDataById,
  GetCommentTicketList,
  GetCommentStatusList,
  GetCommentRejectReasonList,
  GetMenuList,
  GetOperatorService,
  GetOperatorList,
  GetCheckOperatorList,
  GetStoreSaleOrderByOrderNo,
  GetStoreInvoiceFile,
  GetCountryTransfer,
  GetActivityData,
  GetRolesList,
  GetOperatorUpdate,
  GetMenuItemsByParent,
  GetMenuAll,
  GetMenuRoleList,
  GetOperatorListByRoleId,
  GetPaymentLogsListCustomerId,
  GetPaymentLogsListOrderNumber,
  GetTransactions,
  GetAllactivity,
  GetActivityLogById,
  DownloadExcel,
  GetDocumentByOrderNumber,
  DownloadCsv,
  DownloadOrderExcel,
  DownloadOrderCsv,
  DownloadProductFileExport,
  DownloadValidationErrorExcel,
  GetSaleAndReturnDashboard,
  GetSaleAndReturnTotalDashboard,
  GetDataForFilter,
  GetOrdersTotalDashboard,
  GetSaleAndReturnQuantitylDashboard,
  GetOrdersQuantityDashboard,
  GetOrderList,
  GetProductList,
  GetProductSentLogList,
  DownloadProductExcel,
  ManuelRetransmission,
  GetMpList,
  GetMpCountryList,
  GetMpIntegratorList,
  GetMpOrderStatusList,
  GetMpOriginCodeList,
  GetMpOriginsFromIntegration,
  GetMpOriginsFromSales
};

export default MerchantQueryService;
