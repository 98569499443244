import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History, createBrowserHistory } from "history";

import { handlerLeftMenuReducer } from "./leftMenuReducer/leftMenuReducer";
import { handlerLookUpReducer } from "./lookupReducer/lookupReducer";
export const history = createBrowserHistory();

export const rootReducer = (history: History) =>
  combineReducers({
    leftMenu: handlerLeftMenuReducer,
    lookups: handlerLookUpReducer ,
    router: connectRouter(history),
  });

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
