import React, { FC, useState, useEffect } from "react";
import InputMask from "react-input-mask";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
//--
import "./SCUITextField.scss";

//#region Enums
enum enumVariant {
  standard = "standard",
  outlined = "outlined",
  filled = "filled",
}
enum enumAutoComplate {
  currentPassword = "current-password",
}
enum enumInputType {
  text = "text",
  number = "number",
  email = "email",
  password = "password",
  range = "range",
  reset = "reset",
  search = "search",
  tel = "tel",
  url = "url",
  month = "month",
  week = "week",
  time = "time",
  datetime = "datetime",
  file = "file",
  hidden = "hidden",
  image = "image",
  info = "info",
}
enum enumSizeType {
  s = "s",
  m = "m",
  l = "l",
}
enum enumColorType {
  primary = "primary",
  secondary = "secondary",
}
//#endregion

interface IProps {
  value?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  readOnly?: boolean;
  shrink?: boolean;
  type?: string; //enumInputType.text| enumInputType.info | enumInputType.number |enumInputType.email |enumInputType.password |enumInputType.range |enumInputType.reset |enumInputType.search |enumInputType.tel |enumInputType.url |enumInputType.month |  enumInputType.week | enumInputType.time | enumInputType.datetime | enumInputType.file | enumInputType.hidden| enumInputType.image;
  autoComplete?: string;
  defaultValue?: string;
  helperText?: string;
  label?: string;
  variant?: string; //enumVariant.standard | enumVariant.outlined | enumVariant.filled ;
  multiline?: boolean;
  maxRows?: number;
  placeholder?: string;
  onChange?: (textValue: string, name: string) => void;
  onBlur?: (textValue?: string) => void;
  fullWidth?: boolean;
  size?: string; //enumSizeType.s | enumSizeType.m | enumSizeType.l;
  iconVisible?: boolean;
  autoFocus?: boolean;
  stateName: string;
  maxLength?: number;
  minLength?: number;
  isShowClearBtn?: boolean;
  color?: enumColorType.primary | enumColorType.secondary;
  isMask?: boolean;
  maskScheme?: string;
}

export const SCUITextField: FC<IProps> = ({
  value = null,
  required = false,
  disabled = false,
  error = false,
  readOnly = false,
  shrink,
  type = "text",
  autoComplete,
  defaultValue = "",
  helperText = null,
  label = "",
  variant = enumVariant.outlined,
  multiline = false,
  maxRows = 15,
  placeholder,
  onChange,
  onBlur,
  fullWidth = false,
  size = enumSizeType.l,
  iconVisible = false,
  autoFocus = false,
  stateName = "changeText",
  maxLength = 800,
  minLength = 0,
  isShowClearBtn = false,
  color,
  isMask = false,
  maskScheme = null,
}) => {
  const [textValue, setTextValue] = useState("");

  //#region useEffect
  useEffect(() => {
    if (value !== null && value !== textValue) {
      setTextValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  //#endregion

  //#region events

  const _onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length <= maxLength && onChange) {
      let value: string = (event.target as HTMLInputElement).value;

      if (value.length === 0 || value.trim() !== "") {
        changeText(value);
      }
    }
  };

  const _onClickClear = () => {
    changeText("");
    _onBlur();
  };
  const _onKeyPress = (event: any): void => {
    if (type === "number") {
      [43, 45, 69, 101].includes(event.which) && event.preventDefault();
    }
  };

  const _onBlur = (): void => {
    if (onBlur) {
      onBlur(textValue);
    }
  };
  //#endregion

  //#region methods
  const getVariant = () => {
    switch (variant) {
      case enumVariant.outlined:
        return enumVariant.outlined;
      case enumVariant.filled:
        return enumVariant.filled;
      default:
        return enumVariant.standard;
    }
  };

  const changeText = (value: string) => {
    if (onChange) {
      onChange(value, stateName);
      setTextValue(value);
    }
  };

  const getValue = () => {
    return type === "number" && Number(textValue) === -1 ? "" : textValue;
  };
  //#endregion

  //#region enums
  enum enumVariant {
    standard = "standard",
    outlined = "outlined",
    filled = "filled",
  }

  enum enumAutoComplate {
    currentPassword = "current-password",
  }

  //#endregion

  /*const funcInputRef= (element:any)=> {
    if(inputRef !== null && inputRef){  
           inputRef(element)
     } 
  }*/

  return (
    <Box
      className={` sc-text-field ${error ? "error" : ""} ${
        iconVisible ? "text-field-icon" : ""
      }`}
    >
      {isMask ? (
        <InputMask
          mask={maskScheme || ""}
          onChange={_onChangeText}
          value={type === "number" && Number(textValue) === -1 ? "" : textValue}
        >
          {() => (
            <TextField
              label={label}
              inputProps={{
                maxLength: maxLength,
                readOnly: readOnly,
              }}
              InputProps={{
                startAdornment: iconVisible && (
                  <InputAdornment
                    position="start"
                    className="sc-text-field__icon"
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: iconVisible && isShowClearBtn && (
                  <InputAdornment
                    position="start"
                    className="sc-text-field__icon cursor-pointer"
                  >
                    <CloseIcon onClick={_onClickClear} />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: shrink,
              }}
              helperText={error ? `⚠️ ${helperText}` : helperText}
              variant={getVariant()}
              maxRows={maxRows}
              fullWidth={fullWidth}
              multiline={multiline}
              rows={multiline ? 8 : 1}
              error={error}
              className={`sc-text-field--${size}`}
            />
          )}
        </InputMask>
      ) : (
        <TextField
          id={`${getVariant()}-basic`}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={`sc-text-field--${size}`}
          disabled={disabled}
          error={error}
          fullWidth={fullWidth}
          helperText={error ? `⚠️  ${helperText}` : helperText}
          InputLabelProps={{
            shrink: shrink,
          }}
          InputProps={{
            startAdornment: iconVisible && (
              <InputAdornment position="start" className="sc-text-field__icon">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: iconVisible && isShowClearBtn && (
              <InputAdornment
                position="start"
                className="sc-text-field__icon cursor-pointer"
              >
                <CloseIcon onClick={_onClickClear} />
              </InputAdornment>
            ),
          }}
          inputProps={{
            maxLength: maxLength,
            minLength: minLength,
            readOnly: readOnly,
          }}
          label={label}
          maxRows={maxRows}
          multiline={multiline}
          onChange={_onChangeText}
          onKeyPress={_onKeyPress}
          onBlur={_onBlur}
          placeholder={placeholder}
          required={required}
          rows={multiline ? 8 : 1}
          type={type}
          value={getValue()}
          variant={getVariant()}
          color={color}
        />
      )}
    </Box>
  );
};
{
  /**  defaultValue={defaultValue}
   * 
   * enum enumAutoComplate {
  currentPassword = "current-password",
}
enum enumInputType {
  text = "text",
  number = "number",
  email = "email",
  password = "password",
  range = "range",
  reset = "reset",
  search = "search",
  tel = "tel",
  url = "url",
  month = "month",
  week = "week",
  time = "time",
  datetime = "datetime",
  file = "file",
  hidden = "hidden",
  image = "image",
}
   */
}
