export const PageUrl = {
  home: {
    name: "home",
    url: "/home",
  },
  dashboard: {
    name: "dashboard",
    url: "/dashboard",
  },

  signOut: {
    name: "signout-callback-oidc",
    url: "/signout-callback-oidc",
  },
  signIn: {
    name: "signin-oidc",
    url: "/signin-oidc",
  },
  commentList: {
    name: "productcomments-comment-list",
    url: "/productcomments/comment-list",
  },
  commentDetail: {
    name: "productcomments-comment-detail",
    url: "/productcomments/comment-detail",
  },
  commentTicket: {
    name: "productcomments-comment-ticket",
    url: "/productcomments/comment-ticket",
  },  
  menuManagement: {
    name: "sitemanagement-menu-management",
    url: "/sitemanagement/menu-management",
  },
  authorizationRuleManagement: {
    name: "sitemanagement-authorization-rule-management",
    url: "/sitemanagement/authorization-rule-management",
  },
  cacheManagement: {
    name: "sitemanagement-cache-management",
    url: "/sitemanagement/cache-management",
  }, 
  editUser: {
    name: "sitemanagement-edit-user",
    url: "/sitemanagement/edit-user",
  },
  editRoles: {
    name: "sitemanagement-edit-roles",
    url: "/sitemanagement/edit-roles",
  },
  storeLcWaikiki: {
    name: "storelcwaikiki",
    url: "/storelcwaikiki",
  },
  paymentLogs: {
    name: "paymentlogs",
    url: "/paymentlogs",
  }, 
  transactionSales: {
    name: "transaction-sales",
    url: "/transaction/sales",
  },
  SaleDashboard: {
    name: "transaction-dashboard",
    url: "/transaction/dashboard",
  },
  ActivityDashboard: {
    name: "activity-dashboard",
    url: "/activity/dashboard",
  },
  OrdersDashboard: {
    name: "orders-dashboard",
    url: "/orders/dashboard",
  },
  TransactionLogs: {
    name: "transaction-logs",
    url: "/transaction/logs",
  },
  OrdersAndCancelled: {
    name: "orders-list",
    url: "/orders/list",
  },
  ProductImport: {
    name: "product-import",
    url: "/product/import",
  },
  ProductDataList: {
    name: "product-list",
    url: "/product/list",
  },
  ProductSentLogs: {
    name: "product-logs",
    url: "/product/logs",
  },
  SalesImport: {
    name: "sales-import",
    url: "/sales/import",
  },
};
