import React, { FC, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
//import Hidden from "@mui/material/Hidden";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Badge from "@mui/material/Badge";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { SCUIMenus, SCUIMainMenu } from "../../atoms";
import Helper from "../../../utils/helper";
import AuthService from "../../../services/auth/Auth.services";
import { EnvironmentTypes, PageUrl } from "../../../types/enums";
import "./SCUIHeader.scss";
import {
  myMenuList,
  ISCUIHeader,
  AppBar,
  JwtPayload,
} from "./SCUIHeader.config";
import useMediaQuery from "@mui/material/useMediaQuery";
import StyleVariables from "../../../assets/styles/variables/_export.module.scss";
import { useHistory } from "react-router-dom";
import MerchantQueryService from "../../../services/query/MerchantQueryService";
import Switch from "@mui/material/Switch";
import LocalStorageWrapper from "../../../core/wrappers/browserStorage/localStorage-wrapper";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blue, lightBlue, deepPurple, deepOrange } from "@mui/material/colors";
import LanguageIcon from "@mui/icons-material/Language";
import { ActionType } from "../../../stores/redux/types/leftMenuModel/ActionType";

export const SCUIHeader: FC<ISCUIHeader> = (props) => {
  const dispatch: any = useDispatch();

  const [isLogin, setIsLogin] = useState(true);
  const isMobile = useMediaQuery(
    `(max-width:  ${StyleVariables.breakpointSm})`,
    {
      noSsr: true,
    }
  );
  const isTablet = useMediaQuery(
    `(max-width:  ${StyleVariables.breakpointMd})`,
    {
      noSsr: true,
    }
  );

  const [menusAnchorEl, setMenusAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const history = useHistory();
  const drawerWidth = 260;
  var scuiContentElement = window.document.getElementById("scuiContent")!;
  const [menuList, setMenuList] = useState<any>();

  const authService = new AuthService();
  const envIsDev = process.env.REACT_APP_ENVIRONMENT === EnvironmentTypes.dev;
  const [menuOpen, setMenuOpen] = React.useState(isTablet ? false : true);
  const [darkState, setDarkState] = useState(
    localStorage.getItem("isDarkMode") === "true" ? true : false
  );
  const clickNestedMenu = () => {
    console.log("denenee");
    if (isTablet) {
      _menuClose();
    }
  };
  const palletType = darkState ? "dark" : "light";
  const mainPrimaryColor = darkState ? blue[500] : lightBlue[500];
  const mainSecondaryColor = darkState ? deepOrange[900] : deepPurple[500];
  const darkTheme = createTheme({
    palette: {
      mode: palletType,
      primary: {
        main: mainPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
      },
    },
  });

  const [isMenuClosed, setIsMenuClosed] = useState(false);
  const _closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: isMobile ? 0 : `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(9)} + 1px)`,
    },
  });
  const getMenuList = () => {
    MerchantQueryService.GetMenuList()
      .then((res: any) => {
        if (res) {
          var parsedData = res.menuItem;
          setMenuList(parsedData);
        }
      })
      .catch((error: any) => {
        console.log("Hata :>> ", error);
      });
  };

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(!open && {
      ..._closedMixin(theme),
      "& .MuiDrawer-paper": _closedMixin(theme),
    }),
  }));

  useEffect(() => {
    loginCheck();
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 764) _menuClose();
      else {
        _menuOpen();
      }
    }

    window.addEventListener("resize", handleResize);
  });
  const _onClickRedirect = (urlName: string) => {
    console.log(urlName);
    history.push(urlName);
  };
  const _onClickProfileMenu = (urlName: string) => {
    if (urlName === PageUrl.signOut.url) {
      authService.logout();
    } else {
      _onClickRedirect(urlName);
    }
  };
  const _onClickMenu = (event: React.MouseEvent<HTMLElement>, name: string) => {
    console.log(event);
    if (event.currentTarget) {
      console.log(event);
      setMenusAnchorEl(event.currentTarget);
    }
  };

  const handleThemeChange = () => {
    setDarkState(!darkState);
    LocalStorageWrapper.setItemToLocalStorage("isDarkMode", !darkState);
    window.location.reload();
  };

  const _menuClose = () => {
    dispatch({
      type: ActionType.HANDLER_LEFT_MENU,
      payload: {
        isOpenLeftMenu: false,
      },
    });
    setMenuOpen(false);
    setIsMenuClosed(true);
  };

  const _menuOpen = () => {
    dispatch({
      type: ActionType.HANDLER_LEFT_MENU,
      payload: {
        isOpenLeftMenu: true,
      },
    });
    setMenuOpen(true);
    setIsMenuClosed(false);
  };

  const _handleDrawerToggle = () => {
    if (menuOpen) {
      _menuClose();
    } else {
      _menuOpen();
    }
  };

  const _onClickLogo = () => {
    if (isMobile) _menuClose();
    history.push(PageUrl.home.url);
    window.scrollTo(0, 0);
  };

  const _onClickSignIn = () => {
    //props.history.push(PageUrl.signIn.url);
    authService.login();
  };

  const _onClickSignOut = () => {
    //props.history.push(PageUrl.signOut.url);
    authService.logout();
  };

  const loginCheck = () => {
    authService.getUserInfo().then((getUserInfo: any) => {
      if (!Helper.isNil(getUserInfo)) {
        getMenuList();
        const decodedToken = jwt_decode<JwtPayload>(getUserInfo.access_token);
        LocalStorageWrapper.setItemToLocalStorage(
          "userName",
          decodedToken.name
        );
        setIsLogin(true);
      } else {
        setTimeout(function () {
          getMenuList();
        }, 1000);
      }
    });
  };
  return (
    <ThemeProvider theme={darkTheme}>
      <div id="isMenuClosedDiv" className="menuopened"></div>
      <header className={"sc-header " + (isLogin ? " login" : " no-login")}>
        <div className="haeder__area">
          <AppBar position="fixed" open={menuOpen}>
            <Toolbar>
              <Grid
                key="header-logo"
                item
                xs={2}
                sm={2}
                md={1}
                lg={1}
                xl={1}
                className="drawer-menu-area"
              >
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={_handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
              <Grid key="logo-area" item xs={2} sm={2} md={2} lg={2} xl={2}>
                <div className="logo-area" onClick={_onClickLogo}></div>
              </Grid>
              <Grid key="logo-text" item sm={2} md={2} lg={2} xl={4}>
                <Typography
                  className="testYayin"
                  color={"white"}
                  variant="h4"
                  noWrap
                  component="div"
                  sx={{ display: { xs: "none", sm: "block" } }}
                >
                  Market Place Yönetim Paneli
                </Typography>
              </Grid>

              <Grid
                key="sign-up-btn-area"
                item
                xs={8}
                sm={8}
                lg={6}
                xl={6}
                className="right-btn-area navbar-area"
              >
                <div className="nav-area">
                  <div className="navbar">
                    <ul className="navbar-list justify-content-end">
                      <li>
                        {" "}
                        <LightModeIcon
                          fontSize="large"
                          sx={{ color: "#fff" }}
                        />
                        <Switch
                          checked={darkState}
                          onChange={handleThemeChange}
                        />
                        <DarkModeIcon fontSize="large" sx={{ color: "#fff" }} />
                      </li>
                      {/* <li>
                        {" "}
                        <LanguageIcon
                          fontSize="large"
                          sx={{ color: "#fff" }}
                        />
                        <Switch
                          checked={darkState}
                          onChange={handleThemeChange}
                        />
                        <DarkModeIcon fontSize="large" sx={{ color: "#fff" }} />
                      </li> */}
                      {/*                    <li className="navbar-list-item">
                        <Link
                          to={PageUrl.sellerRegisterList.url}
                          className="menu-link"
                        >
                          <Badge badgeContent={29} color={"secondary"}>
                            <div className="icon-area">
                              <NotificationsOutlinedIcon
                                color="action"
                                className="icon"
                              />
                            </div>
{/*                           </Badge>
                       </Link>
                      </li> */}
                      <li className="navbar-list-item">
                        <Link
                          to={"#"}
                          className="menu-link"
                          aria-controls="profile-menu"
                          aria-haspopup="true"
                          onClick={(e: any) => _onClickMenu(e, "profile")}
                        >
                          <span className="head-text">Profil</span>
                          <ArrowDropDownIcon />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Toolbar>
          </AppBar>

          <Drawer variant="permanent" open={menuOpen}>
            <div
              className="drawer-area"
              // onMouseEnter={_onMouseOverMenuList}
              // onMouseLeave={_onMouseOutMenuList}
            >
              <SCUIMainMenu
                id={"main-menu"}
                menuList={menuList}
                onClickNestedMenu={clickNestedMenu}
              />
            </div>
          </Drawer>
        </div>
        <SCUIMenus
          id={"profile-menu"}
          anchorEl={menusAnchorEl}
          onClose={() => {
            setMenusAnchorEl(null);
          }}
          onClickMenu={_onClickProfileMenu}
          list={myMenuList}
        />
      </header>
    </ThemeProvider>
  );
};
