enum ReturnReason {
    CustomerRequest = 1,
    Out_Of_Stock = 2,
    CustomerReturn = 3
  }
  
  const ReturnReasonDescriptions = {
    [ReturnReason.CustomerRequest]: "İptal Edildi",
    [ReturnReason.Out_Of_Stock]: "Eksik Ürün",
    [ReturnReason.CustomerReturn]: "İade Edildi",
  };

  export { ReturnReason, ReturnReasonDescriptions };
  