import React, { FC } from "react";
import Checkbox from "@mui/material/Checkbox";

//--
import "./SCUICheckbox.scss";
 
enum EnumToastType {
  default = "default",
  primary = "primary",
  secondary = "secondary",
}

enum EnumSizeType {
   medium ='medium',
   small='small'
}
 
interface IProps {
  color?:
    | EnumToastType.primary
    | EnumToastType.secondary
    | EnumToastType.default;
  checked?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  indeterminate?: boolean;
  size?:EnumSizeType.medium | EnumSizeType.small;
  onChange?: () => void;
}

export const SCUICheckbox: FC<IProps> = ({
  color = EnumToastType.default,
  checked,
  disabled = false,
  defaultChecked = false,
  indeterminate = false,
  size = EnumSizeType.medium,
  onChange,
}) => {
  const getSize = (): any => {
    return size;
  };

  return (
    <Checkbox
      className="sc-checkbox"
      size={getSize()}
      color={color}
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      indeterminate={indeterminate}
      onChange={onChange}
      inputProps={{ "aria-label": "primary checkbox" }}
    />
  );
};
