const activityTypeList = [
  { label: "RetailIntegration", value: 10 },
  { label: "FinanceIntegration", value: 20 },
  { label: "InvoiceGeneration", value: 30 },
];

enum enumActivityTypeList {
  RetailIntegration = 10,
  FinanceIntegration = 20,
  InvoiceGeneration = 30,
}

enum enumRetailTransactionActivityStatus {
  Pending = 10,
  InProgress = 20,
  Completed = 30,
  Failed = 40,
}

export {
  activityTypeList,
  enumActivityTypeList,
  enumRetailTransactionActivityStatus,
};
