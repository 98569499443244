const emailValidation = /^(([a-z0-9^<>()[\]\\._-\s@"]+(\.[a-z0-9^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(((?!lcwaikiki)([a-z0-9._-])[\s\S]))((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
const tcknValidation = /^[1-9]{1}[0-9]{9}[02468]{1}$/;
const vknValidation =/^[0-9]{10}$/;
const mersisNoValidation=/^([0-9]{4})\s?([0-9]{4})\s?([0-9]{4})\s?([0-9]{4})$/;
const ibanNoValidation = /^[A-Z]{2}(?:[ ]?[0-9]){18,24}$/;
const mobilePhoneValidation  = /^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/
const defaultPhoneValidation= /^(0)([2348]{1})([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/
const postalCodeValidation=/^[0-9]{5}$/;

const minTwoCharacter= /^[a-zA-Z0-9ğüşöçıİĞÜŞÖÇ]{2,}$|\s/;
const minTwoCharacterLetter= /^[a-zA-Z0-9ğüşöçıİĞÜŞÖÇ./-]{2,}$|\s/;
const minNumberLength=/^[0-9]{3,}$/;

const adressValidation=/^[a-zA-Z0-9ğüşöçıİĞÜŞÖÇ./-]{2,}(([\s])*[a-zA-Z0-9ğüşöçıİĞÜŞÖÇ./-]{2,})+([\s])*$/;
const nameValidation=/^[a-zA-Z0-9ğüşöçıİĞÜŞÖÇ./-]{2,}([\s]*[a-zA-Z0-9ğüşöçıİĞÜŞÖÇ./-]{2,})+([\s])*$/;
 
const RegexCode = {
    emailValidation,
    tcknValidation,
    vknValidation,
    mersisNoValidation,
    ibanNoValidation,
    mobilePhoneValidation,
    defaultPhoneValidation,
    minTwoCharacter,
    minTwoCharacterLetter,
    minNumberLength,
    postalCodeValidation,
    adressValidation,
    nameValidation
}

export default RegexCode;