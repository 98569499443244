import { IBaseObject } from "./../types/interfaces/baseObject";
import { EnvironmentTypes } from "../types/enums/env-enums";
import RegexCode from "../types/constants/regex";
import { currentRecordCreateTabNames } from "../types/enums/tabs-enums";
//#region Control

//#region Environment Control
const isLocalEnv = () =>
  !!(process.env.REACT_APP_ENVIRONMENT === EnvironmentTypes.local);

const isDevEnv = () =>
  !!(process.env.REACT_APP_ENVIRONMENT === EnvironmentTypes.dev);

const isTestEnv = () =>
  !!(process.env.REACT_APP_ENVIRONMENT === EnvironmentTypes.test);

const isProdEnv = () =>
  !!(process.env.REACT_APP_ENVIRONMENT === EnvironmentTypes.prod);

//#endregion

//#region Login control
const isLogin = () =>
  localStorage.hasOwnProperty("access_token") &&
  localStorage.access_token !== "";
//#endregion

//#region Empty Null Undefined Control
const objectIsEmpty = (info: object): boolean => {
  if (!isNil(info)) {
    return Object.keys(info).length === 0;
  }
  return false;
};

const isDiff = (value1: any, value2: any): boolean =>
  JSON.stringify(value1) !== JSON.stringify(value2);

const isEmpty = (value: any) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else if (isObject(value)) {
    return objectIsEmpty(value);
  } else if (typeof value === "string") {
    return value.trim() === "";
  }
};

const isNil = (value: any) => {
  return isUndefined(value) || isNull(value);
};

const isUndefined = (value: any) => {
  return value === undefined;
};

const isNull = (value: any) => {
  return value === null;
};

//#endregion

//#region Type Check
const getType = (value: any): string =>
  Object.prototype.toString.call(value).slice(8, -1).toLowerCase();

const isArray = (value: any): boolean => Array.isArray(value);

const isObject = (value: any): boolean =>
  typeof value === "object" && !isArray(value) && getType(value) === "object";
//#endregion

//#region Parse
const objectParseData = (basisObj: IBaseObject, filledObj: IBaseObject) => {
  let result: IBaseObject = {};
  Object.keys(basisObj).forEach((fieldName: string) => {
    result[fieldName] = filledObj[fieldName];
  });
  return result;
};

const buildFormData = (formData: any, data: any, parentKey: any) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
};

const jsonToFormData = (data: any) => {
  const formData = new FormData();

  buildFormData(formData, data, null);

  return formData;
};

const getCollectionField = (data: any, subField: string): any => {
  let result = null;
  if (!isNil(data)) {
    if (isArray(data)) {
      let list: any = [];
      data.forEach((item: any) => {
        if (!isNil(item) && !isNil(item[subField]) && item[subField]) {
          list.push(item[subField]);
        }
      });
      result = list.length > 0 ? list : null;
    } else {
      result = data[subField] || null;
    }
  }
  return result;
};
const toFixed = (value: number, digits: number): string =>
  value.toFixed(digits);
const turkishCharacterConvertToEnglish = (textValue: string): string => {
  let charMap: IBaseObject = {
    Ç: "C",
    Ö: "O",
    Ş: "S",
    İ: "I",
    I: "i",
    Ü: "U",
    Ğ: "G",
    ç: "c",
    ö: "o",
    ş: "s",
    ı: "i",
    ü: "u",
    ğ: "g",
  };
  let stringList = textValue.split("");
  for (let i = 0, len = stringList.length; i < len; i++) {
    stringList[i] = charMap[stringList[i]] || stringList[i];
  }
  let result: string = stringList.join("");
  return result;
};
const toTitleCase = (str: string, language: string): string => {
  if (language) {
    return (
      str.charAt(0).toLocaleUpperCase(language) +
      str.substr(1).toLocaleLowerCase(language).replace("_", "").trim()
    );
  }
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const capitalizeFirstCharacter = (text: string): string => {
  return `${text[0].toUpperCase()}${text.slice(1)}`;
};

const lowercaseFirstCharacter = (text: string): string => {
  return `${text[0].toLowerCase()}${text.slice(1)}`;
};
//#endregion

//#region Random
const getRandomId = (): Number => {
  return getRandomNumber(100000);
};
const getRandomNumber = (parametrer: number): number => {
  return Math.floor(Math.random() * parametrer);
};

//#endregion

const getTwoArrayField = (
  list1: any,
  value: any,
  fieldName: any,
  resultField: any
): any => {
  let result = list1.find((item: any) => item[fieldName] === value);

  if (!isNil(result) && !objectIsEmpty(result) && !isNil(result[resultField])) {
    if (!isNil(result[resultField])) {
      return result[resultField];
    }
  }
  return null;
};

const textEqualOrInclude = (value1: string, value2: string) => {
  return (
    value1?.toLowerCase() === value2?.toLowerCase() ||
    value1?.toLowerCase().includes(value2?.toLowerCase())
  );
};

const searchList = (list: any, search: string, notSearchFields: any) => {
  let resultList: any = [];
  list.forEach((row: any) => {
    for (let index = 0; index < Object.keys(row).length; index++) {
      const key = Object.keys(row)[index];
      if (
        !notSearchFields.includes(key) &&
        textEqualOrInclude(String(row[key]), String(search))
      ) {
        resultList.push(row);
        break;
      }
    }
  });
  return resultList;
};

const stringIsEmpty = (value: string): boolean => {
  return !value.trim() || value.trim().length === 0;
};

const validateEmail = (value: string): boolean => {
  return RegexCode.emailValidation.test(value.trim().toLowerCase());
};

const validateTckNo = (value: number): boolean => {
  let tcknString: string = value.toString();
  if (tcknString.length !== 11) {
    return false;
  } else if (tcknString.slice(0, 1) === "0") {
    return false;
  }
  return true;
};

const getObjectField = (objectValue: any, fieldName: string): any => {
  if (objectValue !== null && !isNil(objectValue[fieldName])) {
    return objectValue[fieldName];
  }
  return null;
};

const controlObjectField = (obj: any, fieldName: string): boolean => {
  return obj.hasOwnProperty(fieldName);
};

const apiErrorMessage = (error: any, fieldName: string = ""): string => {
  //redux ile toast mesageye bağlanacak ve hatalar toast olarak gösterilecek.
  let message = isObject(error) ? error?.data?.Message : error;
  let messageText = `⚠️ Hata Oluştu  ${fieldName}   ${JSON.stringify(message)}`;
  console.error(messageText);
  return messageText;
};

const dynamicFormParse = (mainValue: any, list: any): any => {
  let resultInfo: any = {};
  list.forEach((item: any, index: number) => {
    Object.keys(item).forEach((mainItemKey: any, indexItem: number) => {
      resultInfo[mainItemKey] = {};

      item[mainItemKey].forEach((info: any, infoIndex: number) => {
        let getValueName = mainValue[currentRecordCreateTabNames[mainItemKey]];

        if (!isNil(info.endpointKeys)) {
          if (info.endpointKeys.length === 1) {
            resultInfo[mainItemKey][info.key] =
              getValueName[info.endpointKeys[0]];
          } else {
            //array
            resultInfo[mainItemKey][info.key] = [
              {
                id: getValueName[info.endpointKeys[0]],
                value: getValueName[info.endpointKeys[1]],
              },
            ];
          }
        }
      });
    });
  });

  return resultInfo;
};

const getLookupField = (
  list: Array<{ id: number; value: string }>,
  searchId: number
) => {
  return list.filter((item: { id: number; value: string }, index: number) => {
    if (item.id === searchId) {
      return item;
    }
  });
};

const displayGridControl = (fieldName: string) => {
  let visibleStatus = {
    xs: "block",
    sm: "block",
    md: "block",
    lg: "block",
    xl: "block",
  };
  if (fieldName === "empty") {
    return {
      display: { ...visibleStatus, xs: "none", sm: "none", md: "none" },
    };
  }
  return { display: visibleStatus };
};

const objectFieldControl = (objectValue: any, fieldName: string): boolean => {
  return objectValue.hasOwnProperty(fieldName);
};

const twoObjectDiff = (obj1: any, obj2: any): boolean => {
  return JSON.stringify(obj1) !== JSON.stringify(obj2);
};

const arraySort = (field: any): any => {
  var sortOrder = 1;
  if (field[0] === "-") {
    sortOrder = -1;
    field = field.substr(1);
  }
  return function (a: any, b: any) {
    var result: any = a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
    return result * sortOrder;
  };
};

const arrayFieldSort = (
  arrayValue: Array<any>,
  fieldName: string
): Array<any> => {
  return arrayValue?.sort(arraySort(fieldName));
};

const Helper = {
  toTitleCase,
  capitalizeFirstCharacter,
  lowercaseFirstCharacter,
  turkishCharacterConvertToEnglish,
  toFixed,
  getType,
  isObject,
  isArray,
  jsonToFormData,
  getCollectionField,
  getLookupField,
  getRandomId,
  getRandomNumber,
  objectParseData,
  getObjectField,
  objectFieldControl,
  objectIsEmpty,
  isLocalEnv,
  isDevEnv,
  isTestEnv,
  isProdEnv,
  isDiff,
  isEmpty,
  isNil,
  getTwoArrayField,
  textEqualOrInclude,
  searchList,
  stringIsEmpty,
  isUndefined,
  isNull,
  validateTckNo,
  validateEmail,
  apiErrorMessage,
  dynamicFormParse,
  displayGridControl,
  twoObjectDiff,
  arrayFieldSort,
};

export default Helper;
