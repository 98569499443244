enum enumAlertStatusType {
  success = "success",
  danger = "danger",
  warning = "warning",
}

enum enumSnackbarStatusType {
  success = "success",
  warning = "warning",
  danger = "danger",
  info = "info",
}

export { enumAlertStatusType, enumSnackbarStatusType };
