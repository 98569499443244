import React, { FC, useEffect } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import { Collapse, Paper, Grid, TextField, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { SCUIButton } from "../../../atomic/atoms";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import "./CommentTicket.scss";
import { SCUIContent } from "../../../atomic/organisms";
import MerchantQueryService from "../../../services/query/MerchantQueryService";
import MerchantCommandService from "../../../services/command/MerchantCommandService";

export const CommentTicket: FC<any> = (props: any) => {
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "keyWord", headerName: "Etiket Adı", flex: 1 },
    {
      field: "sil",
      headerName: " ",
      sortable: false,
      flex: 1,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        const onClick = (e: any) => {
          e.stopPropagation();
          Swal.fire({
            text:
              params.id +
              " nolu " +
              params.row.keyWord +
              " etiketini silmek istediğinizden emin misiniz?",
            cancelButtonText: "İptal",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sil",
          }).then((result: any) => {
            if (result.isConfirmed) {

               MerchantCommandService.DeleteCommentTicket( params.id)
                .then((res: any) => {
                  console.info(res);
                  Swal.fire("Silindi!", " ", "success");
                  setRefresh(true);
                })
                .catch((error: any) => {
                  Swal.fire("", " ", "error");
                  console.log("Hata :>> ", error);
                })
                .finally(() => {});
            }
          });
        };

        return (
          <Button onClick={onClick}>
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];
  const [tagNames, setTagNames] = React.useState([]);
  const Swal = require("sweetalert2");
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState("");
  const [refresh, setRefresh] = React.useState(false);

  useEffect(() => {
    GetCommentTicketList();
  }, [refresh]);
  const handleClick = () => {
    setOpen(!open);
  };
  const GetCommentTicketList = async (): Promise<any> => {
    try {
      let commentText: any = await MerchantQueryService.GetCommentTicketList();
      setTagNames(commentText.data);
    } catch (error) {
      console.log("Hata :>> ", error);
    }
    setRefresh(false);
  };

  const ticketEkle = async () => {
    let params = { ticketName: value };
    MerchantCommandService.PostCommentTicketAdd(params)
      .then((res: any) => {
        Swal.fire(res.message, " ", "success");
        setRefresh(true);
      })
      .catch((error: any) => {
        Swal.fire("", " ", "error");
        console.log("Hata :>> ", error);
      })
      .finally(() => {});
  };

  return (
    <SCUIContent>
      <Grid container direction="row">
        <Grid item lg={12} md={12} xs={12}>
          <h1 className="headtxt">Etiket Düzenleme </h1>
        </Grid>

        <Paper sx={{ padding: "2rem", margin: "2rem", width: "100%" }}>
          <div onClick={handleClick}>
            <AddCircleIcon className="svg_icons" sx={{ marginRight: "3rem" }} />
            Etiket Ekle
            {open ? (
              <ExpandLess className="svg_icons" sx={{ float: "right" }} />
            ) : (
              <ExpandMore className="svg_icons" sx={{ float: "right" }} />
            )}
          </div>
          <br />
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} lg={8} xl={8}>
                <TextField
                  label="Etiket Adı"
                  value={value}
                  onChange={(event) => setValue(event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3} sm={3} lg={2} xl={2}>
                <SCUIButton
                  text={"Ekle"}
                  type="primary"
                  sizeType="fixed"
                  size={"m"}
                  onClick={ticketEkle}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Paper>

        <Paper sx={{ width: "100%", margin: "2rem", padding: "2rem" }}>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={tagNames}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[5]}
            />
          </div>
        </Paper>
      </Grid>
    </SCUIContent>
  );
};
