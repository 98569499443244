import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import { TextField, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import MerchantCommandService from "../../../services/command/MerchantCommandService";
 import "./SCUIOperatorDialog.scss";

interface IProps {
  roleId?: any;
  open?: boolean;
  onClose?: any;
  refreshOperatorList?:any;
  operators?:any;
}
export const SCUIOperatorDialog: FC<IProps> = ({
  roleId,
  open = false,
  onClose,
  refreshOperatorList,
  operators,
}) => {
  const [userId, setUserId] = React.useState(0);
  const kaydetOpr = async () => {
     let params: any = {};
    params.userId = Number(userId);
    params.roleId = Number(roleId);
    if( userId===0 ) return;
    MerchantCommandService.PostAddOperatorToRole(params)
      .then((res: any) => {
        onClose();
        Swal.fire(res.message, "Operatör atandı ", "success");
         refreshOperatorList();
      })
      .catch((error: any) => {
        Swal.fire("", "Kullanıcı kaydedilemedi. ", "error");
        console.log("Hata :>> ", error);
      })
      .finally(() => {});
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle> Atanacak Operatör Seçiniz</DialogTitle>
      <DialogContent>
        <br />
        <Autocomplete
          getOptionLabel={(option: any) => (option && option.username) || ""}
          id="roles-box"
          options={operators}
          renderOption={(props, option: any) => (
            <li {...props}>
                    <Typography style={{ fontSize: '1.5rem' }}>{option.username}</Typography>

            </li>
          )}
          onChange={(event, newValue) => {
            setUserId(newValue.applicationUserId);
          }}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="operatorler"  />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>İptal</Button>
        <Button onClick={kaydetOpr}>Kaydet</Button>
      </DialogActions>
    </Dialog>
  );
};
