import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import customStylesTable from '../../Const';


class SaleDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }componentDidMount(){
    }
    render() {
        const columns = [
            { omit: true, selector: row => row.id, name: "Id", grow: 1, },
            { selector: row => row.sku, name: "SKU", grow: 1 ,width: "20rem"},
            { selector: row => row.title, name: "Ürün Tanımı", grow: 1 , width: "40rem"},
            { selector: row => row.itemId, name: 'Ürün Id', grow: 1, cell: (row) => (row.itemId) },
            { selector: row => row.barcode, name: "Barcode", grow: 1 ,width: "20rem"},
            { selector: row => row.taxes[0].taxAmount.toFixed(2), name: "Kdv", grow: 1 },
            { selector: row => row.regularUnitPrice.toFixed(2), name: "Birim Fiyat", grow: 1 },
            { selector: row => row.isoCurrencyCode, name: "Para Birimi", grow: 1, cell: (row) => (this.props.data.destISOCurrencyCode) },
            { selector: row => row.regularUnitPrice, name: "Toplam Tutar", grow: 1 ,cell:(row)=>(row.regularUnitPrice*row.quantity).toFixed(2)},
            { selector: row => row.quantity, name: "Adet", grow: 1 },
            { selector: row => row.taxPercent, name: "Kdv Oranı", grow: 1, cell: (row) => (row.taxes[0].taxPercent) },
            { selector: row => row.stockWarehouse, name: "Stok Depo", grow: 1,width: "25rem"},
        ];
        return (
            <div>
                {
                    <DataTable
                        columns={columns}
                        data={this.props.data.saleItems}
                        highlightOnHover
                        customStyles={customStylesTable}
                        paginationComponentOptions={{
                            noRowsPerPage: true,
                        }}
                        pagination={true}
                        progressPending={this.state.loading}
                    />
                }
            </div>
        );
    }
}

export default SaleDetail;