import React, { FC } from "react";
//--
import "./SCUINoData.scss";

interface IProps {
  message?:string | null
}
export const SCUINoData:FC<IProps> = ({message=''}) => {
  return (
    <div className="sc-no-data">
      <p className="sc-no-data__txt-info">{message || "Veri Bulunamadı."} </p>
    </div>
  );
};
