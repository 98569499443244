import React, { useEffect, useState } from "react";
import MerchantQueryService from "../../../../services/query/MerchantQueryService";
import "chartjs-plugin-datalabels";

import { activityTypeList } from "../../../../types/enums";

import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import CountryDataDashboardBar from "./CountryDataDashboardBar";
import { SCUILoading } from "./../../../../atomic/atoms/SCUILoading/SCUILoading";

export default function CountryDataDashboard(props) {
  let { filter } = props;

  const [countryTransfer, setCountryTransfer] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    GetCountryTransfer(filter);
  }, [filter]);

  const GetCountryTransfer = (filter) => {
    setIsLoading(true);
    MerchantQueryService.GetCountryTransfer(filter).then((res) => {
      if (res?.data) {
        setCountryTransfer(res?.data);
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      {activityTypeList?.map((activityType, index) => (
        <Grid item key={index}>
          <Box>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {activityType.label}
                </Typography>

                {isLoading ? (
                  <SCUILoading />
                ) : (
                  <CountryDataDashboardBar
                    countryData={countryTransfer}
                    chartType={activityType?.value}
                  />
                )}
              </CardContent>
            </Card>
          </Box>
        </Grid>
      ))}
    </>
  );
}
