import { PageUrl } from "../enums/pageUrl-enums";

interface IBase {
  [key: string]: string;
}

export const PageAuthName: IBase = {
  [PageUrl.home.name]: "-",
  [PageUrl.commentDetail.name]: "-",
  [PageUrl.commentList.name]: "-",
  [PageUrl.commentTicket.name]: "-",
  [PageUrl.menuManagement.name]: "-",
  [PageUrl.authorizationRuleManagement.name]: "-",
  [PageUrl.editUser.name]: "-",
  [PageUrl.editRoles.name]: "-",
  [PageUrl.storeLcWaikiki.name]: "-",
  [PageUrl.cacheManagement.name]: "-",
  [PageUrl.dashboard.name]: "-",
  [PageUrl.paymentLogs.name]: "-",
  [PageUrl.transactionSales.name]: "-",
  [PageUrl.SaleDashboard.name]: "-",
  [PageUrl.OrdersDashboard.name]: "-",
  [PageUrl.ActivityDashboard.name]: "-",
  [PageUrl.TransactionLogs.name]: "-",
  [PageUrl.OrdersAndCancelled.name]: "-",
  [PageUrl.ProductImport.name]: "-",
  [PageUrl.ProductDataList.name]: "-",
  [PageUrl.ProductSentLogs.name]: "-",
  [PageUrl.SalesImport.name]: "-",
};
