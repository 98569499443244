import React, {
  FC,
  ReactChildren,
  ReactChild,
  useState,
  useEffect,
} from "react";
import { useSelector } from "react-redux";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import { SCUIProgressbar } from "../../atoms";
import { enumActionStatus } from "../../../types/enums";
//--
import {
  orange,
  blue,
  lightBlue,
  deepPurple,
  deepOrange,
} from "@mui/material/colors";

import "./SCUIContent.scss";

interface IProps {
  headerName?: string;
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  proggressBarText?: string;
  proggressBarPercentage?: number;
  status?: number;
  contentClassName?: string;
}

export const SCUIContent: FC<IProps> = ({
  headerName = null,
  children,
  proggressBarText = null,
  proggressBarPercentage = null,
  status = enumActionStatus.create,
  contentClassName = "",
}) => {
  const [darkState, setDarkState] = useState(
    localStorage.getItem("isDarkMode") === "true" ? true : false
  );

  const palletType = darkState ? "dark" : "light";
  const [margin, setMargin] = useState("");

  const leftMenuStore: any = useSelector((state: any) => state?.leftMenu);

  const mainPrimaryColor = darkState ? blue[500] : lightBlue[500];
  const mainSecondaryColor = darkState ? deepOrange[900] : deepPurple[500];
  const darkTheme = createTheme({
    palette: {
      mode: palletType,
      primary: {
        main: mainPrimaryColor,
      },
      secondary: {
        main: mainSecondaryColor,
      },
    },
  });

  useEffect(() => {
    leftMenuStore?.isOpenLeftMenu ? setMargin("32rem") : setMargin("8rem");
  }, [leftMenuStore?.isOpenLeftMenu]);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div
        className={`sc-content ${contentClassName}`}
        id="scuiContent"
        style={{ marginLeft: margin }}
      >
        {headerName !== null && (
          <div className="header-area">
            <h4 className="txt-header">{headerName}</h4>
            {status !== enumActionStatus.read &&
            proggressBarText !== null &&
            proggressBarPercentage !== null &&
            proggressBarPercentage !== -1 ? (
              <div className="progress-area">
                <SCUIProgressbar
                  text={proggressBarText}
                  percentage={proggressBarPercentage}
                />
              </div>
            ) : null}
          </div>
        )}
        {children}
      </div>
    </ThemeProvider>
  );
};
