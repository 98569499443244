import React, { Component } from 'react';
import {
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { tr } from "date-fns/locale";
import { SCUIContent } from '../../../../atomic/organisms';
// import OrdersTotalDashboard from '../OrdersTotalDashboard';
// import { SCUICollapsiblePaper } from '../../../../../../atomic/molecules';
import { SCUIButton } from '../../../../atomic/atoms';
import MerchantQueryService from '../../../../services/query/MerchantQueryService';
import { SCUICollapsiblePaper } from '../../../../atomic/molecules';
import OrdersTotalDashboard from './OrdersTotalDashboard';
import OrdersQuantityDashboard from './OrdersQuantityDashboard';
import { connect } from 'react-redux';
import { handlerMpCountriesList, handlerMpList } from '../../../../stores/redux/actions/lookupAction/lookupAction';
const today = new Date();
class OrdersDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
        //   mpList: [],
        //   MpCountryList: [],
          selectedMp: null,
          selectedMpCountry: null,
          filter: {
            marketplace: null,
            country: null,
            startDate: this.addDays(new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate(),
              0,
              0,
              0
            ), -6).toLocaleString('en-US'),
            endDate:
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                23,
                59,
                59,
                59
              ).toLocaleString('en-US'),
          },
        };
      }
      componentDidMount() {
        this.getDataForFilter();
      }
      getDataForFilter() {
        this.props.handlerMpCountriesList()
        this.props.handlerMpList()
      }
      addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }


    render() {
        return (
            <SCUIContent>
                <Grid container direction="row">

                    <SCUICollapsiblePaper title="Filtre">
                        <Grid container spacing={1}>
                            <Grid item xs={1.5} mt={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                                    <DatePicker
                                        renderInput={(props) => <TextField {...props} />}
                                        label={"Sipariş Başlangıç"}
                                        inputFormat="dd/MM/yyyy"
                                        value={this.state.filter.startDate}
                                        onChange={(event) => {
                                            event.setHours(0, 0, 0, 0);
                                            this.setState({
                                                filter: {
                                                    ...this.state.filter,
                                                    startDate: event.toLocaleDateString('en-US'),
                                                    endDate: this.addDays(event, 6).toLocaleDateString('en-US'),
                                                },
                                            });
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={1.5} mt={3}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                                    <DatePicker
                                        renderInput={(props) => <TextField {...props} />}
                                        label={"Sipariş Bitiş"}
                                        minDate={this.state.filter.startDate}
                                        // maxDate={new Date(this.state.filter.startDate).setDate(
                                        //     new Date(this.state.filter.startDate).getDate() + 6
                                        // )}
                                        // maxDate={this.addDays(this.state.filter.startDate,6)}
                                        inputFormat="dd/MM/yyyy"
                                        value={this.state.filter.endDate}
                                        onChange={(event) => {
                                            event.setHours(23, 59, 59, 59);
                                            this.setState({
                                                filter: {
                                                    ...this.state.filter,
                                                    endDate: event.toLocaleDateString('en-US'),
                                                },
                                            });
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={1.5} mt={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select">Marketplace</InputLabel>
                                    <Select
                                        style={{ height: "55px", width: "200px" }}
                                        labelId="value"
                                        variant="outlined"
                                        id="demo-simple-select"
                                        value={this.state.selectedMp}
                                        label="Age"
                                        onChange={(event) => {
                                            this.setState({
                                                selectedMp: event.target.value,
                                                filter: {
                                                    ...this.state.filter,
                                                    marketplace: event.target.value,
                                                },
                                            });
                                        }}
                                    >
                                        {this.props.mpList.map((e) => (
                                            <MenuItem value={e.value}>{e.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={1.5} mt={3} ml={2}>
                                <FormControl fullWidth>
                                    <InputLabel id="MpCountry">MP Ülke</InputLabel>
                                    <Select
                                        style={{ height: "55px", width: "200px" }}
                                        labelId="value2"
                                        variant="outlined"
                                        id="MpCountry"
                                        value={this.state.selectedMpCountry}
                                        label="MP Ülke"
                                        onChange={(event) => {
                                            this.setState({
                                                selectedMpCountry: event.target.value,
                                                filter: {
                                                    ...this.state.filter,
                                                    country: event.target.value,
                                                },
                                            });
                                        }}
                                    >
                                        {this.props.mpCountriesList.map((e) => (
                                            <MenuItem value={e.value}>{e.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={1.2} ml={3} mt={4}>
                                <SCUIButton
                                    text={"Temizle"}
                                    type="secondary"
                                    sizeType="fixed"
                                    size={"m"}
                                    onClick={() =>
                                        this.setState({
                                            selectedMp: null,
                                            selectedMpCountry: null,
                                            filter: {
                                                ...this.state.filter,
                                                marketplace: null,
                                                country:null,
                                                startDate: this.addDays(
                                                    new Date(
                                                        today.getFullYear(),
                                                        today.getMonth(),
                                                        today.getDate(),
                                                        0,
                                                        0,
                                                        0
                                                    ), -6).toLocaleString('en-US'),
                                                endDate:
                                                    new Date(
                                                        today.getFullYear(),
                                                        today.getMonth(),
                                                        today.getDate(),
                                                        23,
                                                        59,
                                                        59,
                                                        59
                                                    ).toLocaleString('en-US'),
                                            },
                                        })
                                    }
                                />
                            </Grid>
                        </Grid>
                    </SCUICollapsiblePaper>

                    <Grid item lg={1} xl={12} ml={2}>
                        <Box
                            sx={{ minWidth: 50 }}
                            style={{ height: "750px", width: "1535px" }}
                        >
                            <Card variant="outlined">
                                <CardContent style={{ height: "700px", width: "1200px" }}>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        Sipariş Ve İptal tutarları
                                    </Typography>
                                    <OrdersTotalDashboard
                                        filter={this.state.filter}
                                        style={{ height: "800px", width: "900px", marginTop: 200 }}
                                    />
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>

                    <Grid item lg={1} xl={12} ml={2}>
                        <Box
                            sx={{ minWidth: 50 }}
                            style={{ height: "750px", width: "1535px" }}
                        >
                            <Card variant="outlined">
                                <CardContent style={{ height: "700px", width: "1200px" }}>
                                    <Typography
                                        sx={{ fontSize: 14 }}
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        Sipariş Ve İptal Adetleri
                                    </Typography>
                                    <OrdersQuantityDashboard
                                        filter={this.state.filter}
                                        style={{ height: "800px", width: "900px", marginTop: 200 }}
                                    />
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>

                </Grid>
            </SCUIContent>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return ({
      handlerMpCountriesList: handlerMpCountriesList(dispatch),
      handlerMpList: handlerMpList(dispatch),
    });
  };
  
  const mapStateToProps = ({ lookups }) => ({
    mpCountriesList: lookups.mpCountries,
    mpList: lookups.mpList,
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(OrdersDashboard);
  

