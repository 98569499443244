import React, { useEffect, useState } from "react";
import MerchantQueryService from "../../../../services/query/MerchantQueryService";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import {
  enumActivityTypeList,
  activityTypeList,
} from "../../../../types/enums";
import { SCUILoading } from "./../../../../atomic/atoms/SCUILoading/SCUILoading";
import { SCUINoData } from "./../../../../atomic/atoms/SCUINoData/SCUINoData";

export default function ActivityStatistics(props) {
  let { filter } = props;

  const [isLoading, setIsLoading] = useState(true);

  const [activityData, setActivityData] = useState([]);
  const [retailPieData, setRetailPieData] = useState();
  const [financePieData, setFinancePieData] = useState();
  const [invoicePieData, setInvoicePieData] = useState();

  useEffect(() => {
    GetActivityData(filter);
  }, [filter]);

  const GetActivityData = (filter) => {
    setIsLoading(true);
    MerchantQueryService.GetActivityData(filter).then((res) => {
      if (res?.data) {
        var activityArray = [];

        res?.data.forEach((activity) => {
          var activityStatusArray = {
            activityType: activity?.activityType,
            activityTypeName: activity?.activityTypeName,
            data: [],
          };
          activity?.activityStatus.forEach((activityStatus) => {
            activityStatusArray?.data.push({
              label: activityStatus?.activityStatusName,
              value: activityStatus?.activityStatusCount,
              color: activityStatusNameColor(
                activityStatus?.activityStatusName
              ),
            });
          });
          activityArray.push(activityStatusArray);
        });

        setActivityData(activityArray);

        var retailData = activityArray.find(
          (x) => x.activityType === enumActivityTypeList.RetailIntegration
        )?.data;
        var financeData = activityArray.find(
          (x) => x.activityType === enumActivityTypeList.FinanceIntegration
        )?.data;
        var invoicelData = activityArray.find(
          (x) => x.activityType === enumActivityTypeList.InvoiceGeneration
        )?.data;

        setRetailPieData(retailData ?? []);
        setFinancePieData(financeData ?? []);
        setInvoicePieData(invoicelData ?? []);

        setIsLoading(false);
      }
    });
  };

  const activityStatusNameColor = (activityStatusName) => {
    if (activityStatusName === "Completed") {
      return "#21bf73";
    }
    if (activityStatusName === "InProgress") {
      return "#055160";
    }
    if (activityStatusName === "Pending") {
      return "#f9af29";
    }
    if (activityStatusName === "Failed") {
      return "#f76262";
    }
  };

  const retailData = {
    labels: retailPieData?.map((e) => e?.label),
    datasets: [
      {
        data: retailPieData?.map((e) => e?.value),
        backgroundColor: retailPieData?.map((e) => e?.color),
      },
    ],
  };

  const syncData = {
    labels: financePieData?.map((e) => e.label),
    datasets: [
      {
        data: financePieData?.map((e) => e.value),
        backgroundColor: financePieData?.map((e) => e.color),
      },
    ],
  };
  const invoiceData = {
    labels: invoicePieData?.map((e) => e.label),
    datasets: [
      {
        data: invoicePieData?.map((e) => e.value),
        backgroundColor: invoicePieData?.map((e) => e.color),
      },
    ],
  };

  const options = {
    cutoutPercentage: 50,
    layout: {
      padding: 0,
    },
    legend: {
      fillStyle: "red",
      position: "right",
      align: "middle",
      labels: {
        boxWidth: 30,
        padding: 20,
      },
    },
    plugins: {
      datalabels: {
        color: "white",
        font: {
          size: 12,
        },
        padding: 6,
        formatter: (value) => {
          return value;
        },
      },
    },
    tooltips: {
      xPadding: 10,
      yPadding: 15,
    },
  };

  const PieChart = (props) => {
    let { data, options } = props;

    return <Pie data={data} options={options} plugins={[ChartDataLabels]} />;
  };

  return (
    <>
      {activityTypeList?.map((activity, index) => (
        <Grid item key={index}>
          <Box>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {activity?.label}
                </Typography>

                {isLoading ? (
                  <SCUILoading />
                ) : (
                  <PieChart
                    data={
                      activity?.value === enumActivityTypeList.RetailIntegration
                        ? retailData
                        : activity?.value ===
                          enumActivityTypeList.FinanceIntegration
                        ? syncData
                        : activity?.value ===
                          enumActivityTypeList.InvoiceGeneration
                        ? invoiceData
                        : retailData
                    }
                    options={options}
                  />
                )}

                {activity?.value === enumActivityTypeList.RetailIntegration &&
                retailPieData?.length === 0 ? (
                  <SCUINoData />
                ) : (
                  <></>
                )}
                {activity?.value === enumActivityTypeList.FinanceIntegration &&
                financePieData?.length === 0 ? (
                  <SCUINoData />
                ) : (
                  <></>
                )}
                {activity?.value === enumActivityTypeList.InvoiceGeneration &&
                invoicePieData?.length === 0 ? (
                  <SCUINoData />
                ) : (
                  <></>
                )}
              </CardContent>
            </Card>
          </Box>
        </Grid>
      ))}
    </>
  );
}
