import { Grid, Paper, TextField, MenuItem } from "@mui/material";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import {
  SCUIButton,
  SCUICheckbox,
  SCUILoading,
  SCUITextField,
} from "../../../atomic/atoms";
import { SCUISnackbar, SCUIAlertDialog } from "../../../atomic/molecules";
import { SCUICollapsiblePaper } from "../../../atomic/molecules";
import { SCUIContent } from "../../../atomic/organisms";
import MerchantQueryService from "../../../services/query/MerchantQueryService";
import TimeHelper from "../../../utils/time";
import TransactionActivityLog from "./TransactionActivityLog";
import Select from "react-select";

import { activityTypeList } from "../../../types/enums";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { tr } from "date-fns/locale";
import customStylesTable from "../Const";
import { connect } from "react-redux";
import {
  handlerMpCountriesList,
  handlerMpIntegratorList,
  handlerMpList,
} from "../../../stores/redux/actions/lookupAction/lookupAction";

var manuelRetransmissionCount = 0;

const selectLog = {
  DocumentNumbers: [],
};

const handleChange = (event) => {
  selectLog.DocumentNumbers = [];
  if (event.selectedCount > 0) {
    for (var i = 0; i < event.selectedCount; i++) {
      var id = event.selectedRows[i].documentNumber;
      if (selectLog.DocumentNumbers.find((x) => x == id) != id) {
        selectLog.DocumentNumbers.push(id);
      }
    }
  }
};

class TransactionActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      manuelRetransmissionCaption: "Hatalı Aktarımları Tetikle",
      disabledReTransmission: true,
      disabledButton: true,
      data: [],

      selectedStatus: null,
      selectedMp: null,
      selectedMpCountry: null,
      selectedMpActivityType: null,
      selectedIntegrator: null,

      StatusList: [
        { value: 10, label: "Pending" },
        { value: 20, label: "InProgress" },
        { value: 30, label: "Completed" },
        { value: 40, label: "Failed" },
      ],

      totalCount: null,
      loading: true,
      currentPage: 1,
      filterDb: {
        documentNumber: null,
        documentSequenceNumber: null,
        startDate: null,
        endDate: null,
        marketplace: null,
        Country: null,
        ActivityType: null,
        integrator: null,
        retailTransactionActivityStatus: null,
        page: 1,
      },
    };
  }

  componentDidMount() {
    this.getActivitys(this.state.filterDb);
    this.props.handlerMpCountriesList();
    this.props.handlerMpList();
    this.props.handlerMpIntegratorList();
  }

  onChange(e) {
    this.setState(
      {
        filterDb: {
          ...this.state.filterDb,
          page: e,
        },
      },
      () => {
        this.getActivitys(this.state.filterDb);
      }
    );
  }

  manuelRetransmission(event) {
    if (selectLog.DocumentNumbers.length <= 0) {
      this.setState({
        manuelRetransmissionCaption: "Hatalı Aktarımları Tetikle",
        showErrorTable: true,
        openDialog: true,
        validationMessage: "Seçim yapmadınız!",
        status: "info",
      });
    } else {
      manuelRetransmissionCount = manuelRetransmissionCount + 1;
      this.setState({
        manuelRetransmissionCaption: "Tetikleme Yapılıyor...",
      });
      if (manuelRetransmissionCount == 1) {
        MerchantQueryService.ManuelRetransmission(selectLog)
          .then((res) => {
            this.setState({
              manuelRetransmissionCaption: "Hatalı Aktarımları Tetikle",
              selectedFiles: [],
              openDialog: true,
              validationMessage:
                "Tetikleme başarıyla tamamlandı. Sayfayı yenileyiniz!",
              status: "success",
            });
            manuelRetransmissionCount = 0;
            this.getActivitys(this.state.filterDb);
          })
          .catch((res) => {
            this.setState({
              manuelRetransmissionCaption: "Hatalı Aktarımları Tetikle",
              showErrorTable: true,
              errorData: res.data.validations,
              openDialog: true,
              validationMessage: "Tetikleme yapılırken hata oluştu!",
              status: "info",
            });
            this.getActivitys(this.state.filterDb);
            manuelRetransmissionCount = 0;
          });
      }
    }
  }

  getActivitys(filterDb) {
    this.setState({ loading: true });

    if (this.state.selectedStatus == this.state.StatusList[3])
      this.state.disabledReTransmission = false;
    else this.state.disabledReTransmission = true;

    MerchantQueryService.GetAllactivity(filterDb).then((res) => {
      if (res.data) {
        res.data.forEach((x) => {
          x.originCreatedOn = TimeHelper.momentDate(x.originCreatedOn);
          x.createdOn = TimeHelper.momentDate(x.createdOn);
          x.modifiedOn = TimeHelper.momentDate(x.modifiedOn);
        });

        this.setState({
          data: res.data,
          loading: false,
          totalCount: res.totalCount,
          currentPage: filterDb.page,
        });
      }
    });
  }

  render() {
    const customStyles = {
      control: (base) => ({
        ...base,
        height: 52,
        minHeight: 35,
        zIndex: "100",
      }),
    };

    const columns = [
      { omit: true, selector: (row) => row.id, name: "Id", grow: 1 },
      {
        omit: true,
        selector: (row) => row.id,
        name: "Transaction Id",
        grow: 1,
      },
      {
        selector: (row) => row.mpOrigin.integrator,
        name: "Entegratör",
        grow: 1,
        width: "12rem",
      },
      {
        selector: (row) => row.activityTypeText,
        name: "Entegrasyon Alanı",
        grow: 1,
        width: "15rem",
      },
      { selector: (row) => row.country, name: "MP Ülke", grow: 1 },
      {
        selector: (row) => row.documentNumber,
        name: "Belge No",
        grow: 1,
        width: "15rem",
      },
      {
        selector: (row) => row.documentSequenceNumber,
        name: "Fatura No",
        grow: 1,
      },
      {
        selector: (row) => row.totalAmountIncludingTAX,
        name: "Toplam",
        grow: 1,
      },
      { selector: (row) => row.currency, name: "Para Birimi", grow: 1 },
      {
        selector: (row) => row.originCreatedOn,
        name: "Entegratör Oluşturma Tarihi",
        grow: 1,
        width: "20rem",
      },
      {
        selector: (row) => row.createdOn,
        name: "Aktarım Tarihi",
        grow: 1,
        width: "20rem",
      },
      {
        name: "Güncelleme Tarihi",
        selector: (row) => row.modifiedOn,
        grow: 1,
        width: "17rem",
      },
      {
        selector: (row) => row.retailTransactionActivityStatus,
        name: "Durum",
        grow: 2,
        cell: (row) => {
          return (
            <span
              style={{
                color:
                  row.retailTransactionActivityStatus == "Pending"
                    ? "blue"
                    : row.retailTransactionActivityStatus == "Failed"
                    ? "red"
                    : "green",
              }}
            >
              {row.retailTransactionActivityStatus}
            </span>
          );
        },
      },
    ];

    return (
      <SCUIContent>
        <Grid container direction="row">
          <SCUICollapsiblePaper title="Aktarım Logları">
            <Grid container spacing={1}>
              <Grid item xs={2} mt={3}>
                <Select
                  styles={customStyles}
                  placeholder={"Marketplace"}
                  value={this.state.selectedMp}
                  onChange={(e) =>
                    this.setState({
                      disabledReTransmission: true,
                      disabledButton: false,
                      selectedMp: e,
                      filterDb: {
                        ...this.state.filterDb,
                        marketplace: e.value,
                        page: 1,
                      },
                    })
                  }
                  options={this.props.mpList}
                />
              </Grid>

              <Grid item xs={2} mt={3}>
                <Select
                  styles={customStyles}
                  placeholder={"Entegratör"}
                  value={this.state.selectedIntegrator}
                  onChange={(e) =>
                    this.setState({
                      disabledReTransmission: true,
                      disabledButton: false,
                      selectedIntegrator: e,
                      filterDb: {
                        ...this.state.filterDb,
                        integrator: e.value,
                        page: 1,
                      },
                    })
                  }
                  options={this.props.mpIntegratorList}
                />
              </Grid>

              <Grid item xs={2} mt={3}>
                <Select
                  styles={customStyles}
                  placeholder={"Entegrasyon Alanı"}
                  value={this.state.selectedMpActivityType}
                  onChange={(e) =>
                    this.setState({
                      disabledReTransmission: true,
                      disabledButton: false,
                      selectedMpActivityType: e,
                      filterDb: {
                        ...this.state.filterDb,
                        ActivityType: e.value,
                        page: 1,
                      },
                    })
                  }
                  options={activityTypeList}
                />
              </Grid>

              <Grid item xs={2} mt={3}>
                <Select
                  styles={customStyles}
                  placeholder={"MP Ülke"}
                  value={this.state.selectedMpCountry}
                  onChange={(e) =>
                    this.setState({
                      disabledReTransmission: true,
                      disabledButton: false,
                      selectedMpCountry: e,
                      filterDb: {
                        ...this.state.filterDb,
                        Country: e.value,
                        page: 1,
                      },
                    })
                  }
                  options={this.props.mpCountriesList}
                />
              </Grid>

              <Grid item xs={1.7} mt={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label={"Aktarım Başlangıç"}
                    inputFormat="dd/MM/yyyy"
                    value={this.state.filterDb.startDate}
                    onChange={(event) => {
                      event.setHours(0, 0, 0, 0);
                      this.setState(
                        {
                          disabledReTransmission: true,
                          disabledButton: false,
                          filterDb: {
                            ...this.state.filterDb,
                            startDate: event.toLocaleDateString("en-US"),
                            page: 1,
                          },
                        },
                        () => console.log(this.state)
                      );
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={2} mt={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={tr}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label={"Aktarım Bitiş"}
                    inputFormat="dd/MM/yyyy"
                    value={this.state.filterDb.endDate}
                    onChange={(event) => {
                      event.setHours(0, 0, 0, 0);
                      this.setState({
                        disabledReTransmission: true,
                        disabledButton: false,
                        filterDb: {
                          ...this.state.filterDb,
                          endDate: event.toLocaleDateString("en-US"),
                          page: 1,
                        },
                      });
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={1.5} mt={3}>
                <TextField
                  // id="outlined-name"
                  label={"Belge No"}
                  type="text"
                  value={this.state.filterDb.documentNumber}
                  onChange={(event) => {
                    console.log(event);
                    this.setState({
                      disabledReTransmission: true,
                      disabledButton: false,
                      filterDb: {
                        ...this.state.filterDb,
                        documentNumber: event.target.value,
                        page: 1,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={1.5} mt={3}>
                <TextField
                  // id="outlined-name"
                  label={"Fatura No"}
                  type="text"
                  value={this.state.filterDb.documentSequenceNumber}
                  onChange={(event) => {
                    console.log(event);
                    this.setState({
                      disabledReTransmission: true,
                      disabledButton: false,
                      filterDb: {
                        ...this.state.filterDb,
                        documentSequenceNumber: event.target.value,
                        page: 1,
                      },
                    });
                  }}
                />
              </Grid>

              <Grid item xs={1.5} mt={3}>
                <Select
                  styles={customStyles}
                  placeholder={"Durum"}
                  value={this.state.selectedStatus}
                  onChange={(e) =>
                    this.setState({
                      disabledReTransmission: true,
                      disabledButton: false,
                      selectedStatus: e,
                      filterDb: {
                        ...this.state.filterDb,
                        retailTransactionActivityStatus: e.value,
                        page: 1,
                      },
                    })
                  }
                  options={this.state.StatusList}
                />
              </Grid>

              <Grid item xs={1} mt={3.3}>
                <SCUIButton
                  text={"Ara"}
                  type="primary"
                  disabled={this.state.disabledButton}
                  sizeType="fixed"
                  size={"m"}
                  onClick={() => this.getActivitys(this.state.filterDb)}
                />
              </Grid>

              <Grid item xs={1} mt={3.3}>
                <SCUIButton
                  text={"Temizle"}
                  type="secondary"
                  sizeType="fixed"
                  size={"m"}
                  onClick={() =>
                    this.setState(
                      {
                        disabledReTransmission: true,
                        disabledButton: true,
                        selectedStatus: null,
                        selectedMp: null,
                        selectedMpCountry: null,
                        selectedIntegrator: null,
                        selectedMpActivityType: null,
                        filterDb: {
                          ...this.state.filterDb,
                          documentNumber: "",
                          documentSequenceNumber: "",
                          startDate: null,
                          endDate: null,
                          marketplace: null,
                          Country: null,
                          integrator: null,
                          retailTransactionActivityStatus: null,
                          page: 1,
                          ActivityType: null,
                        },
                      },
                      () => {
                        this.getActivitys(this.state.filterDb);
                      }
                    )
                  }
                />
              </Grid>

              <Grid item xs={2} mt={3.3} ml={7}>
                <SCUIButton
                  text={this.state.manuelRetransmissionCaption}
                  type="secondary"
                  disabled={this.state.disabledReTransmission}
                  size={"l"}
                  onClick={() => this.manuelRetransmission()}
                />
              </Grid>
            </Grid>
          </SCUICollapsiblePaper>

          <Paper sx={{ width: "100%", margin: "1rem", padding: "1rem" }}>
            <div style={{ height: 2500, width: "100%" }}>
              {this.state.loading ? (
                <SCUILoading />
              ) : (
                <DataTable
                  columns={columns}
                  data={this.state.data}
                  highlightOnHover
                  expandableRows
                  customStyles={customStylesTable}
                  paginationPerPage={50}
                  pagination={true}
                  paginationServer
                  paginationTotalRows={this.state.totalCount}
                  expandableRowsComponent={TransactionActivityLog}
                  selectableRows
                  selectableRowsHighlight={true}
                  onSelectedRowsChange={handleChange}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  paginationDefaultPage={this.state.currentPage}
                  onChangePage={(page) => this.onChange(page)}
                />
              )}
            </div>
          </Paper>
        </Grid>
        <SCUISnackbar
          messageText={this.state.validationMessage}
          status={this.state.status}
          show={this.state.openMessage}
          onClose={() => this.setState({ openMessage: false })}
          autoHideDuration={2000}
        />
        <SCUIAlertDialog
          open={this.state.openDialog}
          status={this.state.status}
          iconShow={true}
          showBtnCancel={false}
          showBtnConfirm={true}
          btnConfirmText={"Tamam"}
          onClose={() => this.setState({ openDialog: false })}
          onClickAgreed={this.onClickRedirect}
          content={this.state.validationMessage}
        />
      </SCUIContent>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    handlerMpCountriesList: handlerMpCountriesList(dispatch),
    handlerMpList: handlerMpList(dispatch),
    handlerMpIntegratorList: handlerMpIntegratorList(dispatch),
  };
};

const mapStateToProps = ({ lookups }) => ({
  mpCountriesList: lookups.mpCountries,
  mpList: lookups.mpList,
  mpIntegratorList: lookups.mpIntegratorList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionActivity);
