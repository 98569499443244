import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";

/*
import { lighten, Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
*/
//
import "./SCUIGridHeader.scss";

const PREFIX = "SCUIGridHeader";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
  visuallyHidden: `${PREFIX}-visuallyHidden`,
};

const StyledTableHead = styled(TableHead)(({ theme: Theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    width: "100%",
    marginBottom: Theme.spacing(2),
  },

  [`& .${classes.table}`]: {
    minWidth: 750,
  },

  [`& .${classes.visuallyHidden}`]: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

type Order = "asc" | "desc";

interface IHeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

interface IProps {
  headCells: IHeadCell[];
  classes: any;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  showCheckbox?: boolean;
}

export const SCUIGridHeader: FC<IProps> = ({ showCheckbox = false,
  headCells, classes, numSelected = 0, onRequestSort, onSelectAllClick, 
  order = "asc", orderBy = headCells[0].id, rowCount = 0  }) => {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const sortDirection = (headCell: any) => {
    if (headCell.hasOwnProperty("id") && orderBy === headCell?.id) {
      return order;
    }
    return false;
  };

  return (
    <StyledTableHead className="sc-table-head">
      <TableRow className="head-row-area">
        {showCheckbox && (
          <TableCell className="head-cell-area">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}

        {headCells.map((headCell) => (
          <TableCell className="head-cell-area"
           key={headCell.id} align={headCell.numeric ? "right" : "left"} 
           padding={headCell.disablePadding ? "none" : "normal"} sortDirection={sortDirection(headCell)}>
            <TableSortLabel className="head-label-area" active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};
