import React, { FC, useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import LoopIcon from "@mui/icons-material/Loop";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
//
import { SCUICheckbox, SCUITextField, SCUIButton } from "../../atoms";
import Helper from "../../../utils/helper";
//--
import "./SCUISelectBox.scss";

//#region Enums

enum enumVariant {
  standard = "standard",
  outlined = "outlined",
  filled = "filled",
}
enum enumSelectBoxIndex {
  defaultValue = -1,
  searchInput = -2,
  clearButton = -3,
}
//#endregion

interface IList {
  id: number | string;
  value: string;
}

interface IProps {
  label: string;
  value: any;
  showHeadLabel?: boolean;
  list: Array<IList>;
  helperText?: string;
  displayEmpty?: boolean;
  disabled?: boolean;
  shrink?: boolean;
  error?: boolean;
  readOnly?: boolean;
  autoWidth?: boolean;
  required?: boolean;
  variant?: string;
  id?: string;
  size?: string;
  className?: string;
  multiSelect?: boolean;
  selectList?: Array<any>;
  defaultValue?: string;
  stateName?: string;
  onChange?: (value: Array<IList>, stateName: string) => void;
  success?: boolean;
  isLoading?: boolean;
}

export const SCUISelectBox: FC<IProps> = ({
  label,
  value,
  showHeadLabel = true,
  list,
  helperText,
  displayEmpty,
  disabled = false,
  shrink,
  error = false,
  readOnly = false,
  autoWidth = false,
  required = false,
  variant = "outlined",
  id = "0-0",
  size = "l",
  className = "",
  multiSelect = false,
  selectList = [],
  defaultValue = "",
  stateName = "sboxState",
  onChange,
  success = false,
  isLoading = false,
}) => {
  const [showList, setShowList] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [valueList, setValueList] = useState<Array<any>>([]);

  //#region UseEffect
  useEffect(() => {
    if (!arrayIsSame(list, valueList)) {
      setValueList(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    if (selectList && !arrayIsSame(selectList, selectedValues)) {
      setSelectedValues([...selectList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectList]);
  //#endregion

  //#region Events

  /**
   * change sbox
   * item harici seçim tıklamalarında Dizi içerisine undefined ekleyebilir
   * Menuitemde arama ve temizle butonlarına tıklanınca
   * event.defalt.value obje ve number listesi  , son seçilenin number olarak id si geliyor
   * @param {event} params
   * @returns {any}
   * @author Mehmet KORKUT
   */
  const _onChangeSelectbox = (event: any, sBoxChild: any): void => {
    let selectedId = sBoxChild.props.value;
    if (multiSelect) {
      let selectListValue = event.target.value as any[];
      let isUndefined = selectListValue.some((item) => Helper.isNil(item));

      if (!isUndefined) {
        let actionAreaControl = selectListValue.some(
          (item: IList | number) => Number(item) < 0,
        );

        if (!actionAreaControl) {
          let selectInfoList: any[] = [];

          selectListValue.forEach((selectedItem: IList | number) => {
            if (typeof selectedItem === "number") {
              if (!isContainsControl(selectListValue, selectedItem) && list) {
                selectInfoList.push(
                  list.find((item: any) => item.id === selectedItem),
                );
              }
              //ilk eklenen number gelir.
            } else {
              if (!isContainsControl(selectListValue, sBoxChild.props.value)) {
                selectInfoList.push(selectedItem);
              } else if (selectedItem.id !== selectedId) {
                //ekli olan bir sbox verisi objedir ve 2. kez tıklanınca obje olarak gelir.
                selectInfoList.push(selectedItem);
              }
            }
          });
          changePropsList(selectInfoList);
        }
      }
    } else if (!Helper.isNil(event.target.value)) {
      let selectInfos = list.find((item) => item.id === event.target.value);
      changePropsList([selectInfos]);
    }
  };

  const _onChangeSearchText = (textValue: string, stateName: string): void => {
    let result: any = [];
    if (textValue === "") {
      result = [...list];
    } else if (textValue.length > 0) {
      list.map((item: { id: number | string; value: string }) => {
        if (item.value.toLowerCase().indexOf(textValue.toLowerCase()) !== -1) {
          result.push(item);
        }
      });
    }
    setSearchValue(textValue);
    setValueList(result);
  };

  const _onClickBtnClear = (): void => {
    if (String(searchValue).length > 0) {
      _onChangeSearchText("", "searchValue");
    } else if (selectedValues.length > 0) {
      changePropsList([]);
    }
  };

  const _onCloseSelectList = (): void => {
    setShowList(false);
  };

  const _onOpenSelectList = (): void => {
    setShowList(true);
  };

  //#endregion

  //#region Methods

  const changePropsList = (item: any[]): void => {
    if (onChange) {
      setSelectedValues(item);
      onChange(item, stateName);
    }
  };

  const isContainsControl = (containsList: Array<any>, value: number) => {
    return containsList.some(
      (item) => !Helper.isNil(item.id) && item.id === value,
    );
  };

  const getVariant = (): any => {
    switch (variant) {
      case enumVariant.outlined:
        return enumVariant.outlined;
      case enumVariant.filled:
        return enumVariant.filled;
      default:
        return enumVariant.standard;
    }
  };

  const selectControl = (id: number): boolean => {
    if (selectedValues.length) {
      return selectedValues.some((item: any) => item?.id === id);
    }
    return false;
  };

  const getValue = (searchItem: any): string => {
    if (typeof searchItem === "number" && searchItem === -1) {
      return "Yükleniyor...";
    }

    if (Helper.isObject(searchItem) && list) {
      let info: any = list.find((item: any) => item.id === searchItem.id);
      if (info) {
        return info.value;
      }
    }

    return "";
  };

  const arrayIsSame = (arr1: any, arr2: any): boolean => {
    if (JSON.stringify(arr1) === JSON.stringify(arr2)) {
      return true;
    }
    return false;
  };

  //#endregion

  const _renderAndErrorControl = (list: any): any => {
    if (list.length) {
      //error ? `⚠️   ${item}` :
      return (
        <div className="menu-row">
          {list.map((item: any,index:number) => (
            <div className="selected-item" key={index}>
              <p className="txt-item">{getValue(item)}</p>
            </div>
          ))}
        </div>
      );
    }
  };

  const getIconStatus = () => {
    if (!showList && success) {
      return CheckCircleOutlineIcon;
    }
  };

  return (
    <FormControl
      variant={getVariant()}
      className={`selectbox sc-selectbox sc-selectbox--${size} ${className} ${
        showList ? "open " : " close "
      }  ${success ? "success" : ""}`}
      error={error}
      disabled={disabled}
      required={required}
    >
      {showHeadLabel && (
        <InputLabel
          shrink={shrink}
          className="sc-selectbox__label"
          htmlFor={id}
        >
          {label} {isLoading && <LoopIcon className="icon-loading" />}
        </InputLabel>
      )}
      <Select
        labelId={id}
        id={id}
        open={showList}
        onClose={_onCloseSelectList}
        onOpen={_onOpenSelectList}
        value={selectedValues}
        defaultValue={defaultValue}
        onChange={_onChangeSelectbox}
        autoWidth={autoWidth}
        multiple={multiSelect}
        displayEmpty={displayEmpty}
        label={label}
        className={`sc-selectbox__select ${
          multiSelect ? "multi-select" : "only-select"
        }`}
        renderValue={_renderAndErrorControl}
        inputProps={{
          readOnly: readOnly,
        }}
        IconComponent={getIconStatus()}
      >
        <MenuItem
          key="item-selectbox-head-name"
          value={enumSelectBoxIndex.defaultValue}
          className="sc-selectbox__select-menu-item"
          disabled
        >
          <p
            className={`sc-selectbox__select-menu-item--head  ${
              valueList?.length > 0 ? "border-bottom" : " "
            }`}
          >
            {valueList?.length > 0 ? label : "Veri bulunamadı."}
          </p>
        </MenuItem>

        {multiSelect && (
          <div className="sc-selectbox__search-area" key="search-area">
            <MenuItem
              key={`list-item-search-`}
              value={enumSelectBoxIndex.searchInput}
              className="sc-selectbox__select-menu-item"
            >
              <SCUITextField
                fullWidth
                iconVisible={true}
                placeholder={"Ara"}
                size={"m"}
                variant={"outlined"}
                value={searchValue}
                stateName={"searchValue"}
                onChange={_onChangeSearchText}
                isShowClearBtn
              />
            </MenuItem>
          </div>
        )}
        {valueList?.length > 0 &&
          valueList.map(
            (item: { id: number; value: string }, listIndex: number) => (
              <MenuItem
                key={`btn-clear-item-${item.id}`}
                value={item.id}
                className={`sc-selectbox__select-menu-item ${
                  selectControl(item.id) ? " selected" : ""
                }`}
              >
                {multiSelect ? (
                  <div className="menu-item-area select-multi">
                    <SCUICheckbox checked={selectControl(item.id)} />
                    <p className="txt-item" key={item.id}>
                      {item.id} - {item.value}
                    </p>
                  </div>
                ) : (
                  <p className="txt-item select-only">{item.value}</p>
                )}
              </MenuItem>
            ),
          )}
        {multiSelect && (
          <div className="sc-selectbox__clear-area" key="btn-all-select-clear">
            <MenuItem
              key={`btn-clear-item`}
              value={enumSelectBoxIndex.clearButton}
              className="sc-selectbox__select-menu-item"
            >
              <SCUIButton
                text={searchValue === "" ? "Seçimleri Temizle" : "Temizle"}
                type="secondary"
                sizeType="default"
                size="l"
                onClick={_onClickBtnClear}
              />
            </MenuItem>
          </div>
        )}
      </Select>
      {helperText && (
        <FormHelperText>
          {error ? `⚠️ ${helperText}` : helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
