import React, { FC } from "react";
import { Controller } from "react-hook-form";
//--
import { SCUISelectBox } from "../../index";

export const SCUIControllerSelectbox: FC<any> = (props: any) => {
  return props.disabled ? (
    <SCUISelectBox
      selectList={props.value}
      value={props.value}
      size={"l"}
      label={props.label}
      list={props.list}
      {...props}
    />
  ) : (
    <Controller
      name={props.stateName}
      control={props.formControl}
      rules={{ required: props.requiredMessage }}
      defaultValue={props.selectedList}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <SCUISelectBox
          selectList={value}
          onChange={onChange}
          value={props.value}
          size={"l"}
          label={props.label}
          list={props.list}
          {...props}
          stateName={props.stateName}
          helperText={error ? error.message : props.hint}
          error={Boolean(error)}
        />
      )}
    />
  );
};
